import {
	SET_ACCOUNT, SET_POST, SET_LEAD, SET_MESSAGE
} from "./currentTypes";

const initialState = {
    account: null,
    leads: [],
    posts: [],
    messages: [],
}

const collectionReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACCOUNT:
			return {
				...state,
				account: action.payload.item,
			};
		case SET_POST:
			return {
				...state,
				posts: action.payload.item
			};
		case SET_LEAD:
			return {
				...state,
				leads: action.payload.item
			};
		case SET_MESSAGE:
			return {
				...state,
				messages: action.payload.item
			};
		default:
			return state;
	}
};

export default collectionReducer;
