import { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";

import {
	setAccountDetailLeads,
	setActiveUser,
	setFetchedLeads,
	setCurrentAccount,
	setLeads,
	setCurrentLeads,
	setAccounts,
} from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
	leadsAreFetched,
} from "../../selectors";

import {
	leadFetchAll,
	leadUpdate,
	leadPointsTotal,
} from "../../classes/database/Leads";
import { accountFetch } from "../../classes/database/Accounts";
import { getServerTimeStamp } from "../../classes/database/firebase";

import { useAuth } from "../../contexts/AuthContext";

function LeadView(props) {
	const accounts = props.collections.accounts
		? props.collections.accounts
		: {};
	const setAccounts = (items) => {
		props.setAccounts(items);
	};
	const leads = props.current.leads ? props.current.leads : [];

	const { id, lead } = useParams();
	const textareaRef = useRef();

	const [activeMenu, setActiveMenu] = useState();
	const [editing, setEdit] = useState();
	const [removeLead, setRemoveLead] = useState(false);
	const [storedNotes, setStoredNotes] = useState(false);

	const { isAdmin } = useAuth();

	useEffect(async () => {
		const user = accounts[id] ? accounts[id] : await accountFetch(id);
		props.setCurrentAccount(user);
		if (!accounts[id]) {
			const _accounts = { ...accounts };
			_accounts[id] = user;
			props.setAccounts(_accounts);
		}
	}, []);

	useEffect(async () => {
		if (!props.current.account) {
			return;
		}
		const leadList =
			leads[id] && props.collections.leadsAreFetched
				? leads[id]
				: await leadFetchAll(props.current.account.documentId);
		props.setCurrentLeads(leadList ? leadList : {});
		props.setFetchedLeads(id);

		if (leadList && (!leads[id] || !props.collections.leadsAreFetched)) {
			const _leads = { ...leads };
			_leads[id] = leadList;
			props.setLeads(_leads);
		}
		// const leads = await accountFetch(lead, id);
		// if (leads) {
		// 	props.setAccountDetailLeads([leads]);
		// } else {
		// 	props.setAccountDetailLeads([]);
		// }
	}, [props.current.account]);

	const updateStatus = async (e, item, index) => {
		const data = { status: e.target.value };
		if (data.status === "connected" && !item.connectedFrom) {
			data["connectedFrom"] = getServerTimeStamp();
			data["shouldTrack"] = true;
		}
		const response = await leadUpdate(
			item.documentId,
			data,
			props.current.account
		);

		const _leads = [];
		props.current.leads.map((element, i) => {
			if (element.documentId === item.documentId) {
				const data = {
					...element,
					status: response.data.status,
				};
				if (response.data.connectedFrom) {
					data["connectedFrom"] = response.data.connectedFrom;
					data["shouldTrack"] = response.data.shouldTrack;
				}
				_leads.push(data);
			} else {
				_leads.push(element);
			}
		});

		props.setCurrentLeads(_leads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = _leads;
		props.setLeads(leadCollection);
	};

	const countPointsTotal = (points) => {
		let total = 0;
		for (let i = 0; i < points.length; i++) {
			if (points[i].type === "LIKES") {
				total += 1;
			} else if (points[i].type === "COMMENTS") {
				total += 2;
			} else if (points[i].type === "CUSTOM_SUBTRACT") {
				total -= 1;
			} else if (points[i].type === "CUSTOM_ADD") {
				total += 1;
			}
		}
		return total;
	};

	const updateTracking = async (e, item, index) => {
		const response = await leadUpdate(
			item.documentId,
			{
				shouldTrack: e.target.value === "track" ? true : false,
			},
			props.current.account
		);

		const _leads = [];
		props.current.leads.map((element, i) => {
			if (element.documentId === item.documentId) {
				_leads.push({
					...element,
					shouldTrack: response.data.shouldTrack,
				});
			} else {
				_leads.push(element);
			}
		});
		//props.setAccountDetailLeads(leads);
		props.setCurrentLeads(_leads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = _leads;
		props.setLeads(leadCollection);
	};

	const removePrompt = (index) => {
		setActiveMenu(null);
	};

	const outputViewRow = (item, index) => {
		const total = leadPointsTotal(item.points);
		return (
			<div
				key={index}
				data-id={item.documentId}
				className={`single__lead${
					item.thresholdHit === true ? " hot__lead" : ""
				}`}>
				<span className="col name">
					<label>Naam</label>
					{item.name}
				</span>
				<span className="col points">
					<label>Punten</label>
					{total}
				</span>
				{item.status === "open" && (
					<span className="col status">
						<label>Status</label>
						Open
					</span>
				)}
				{item.status === "requested" && (
					<span className="col status">
						<label>Status</label>
						Verzoek verstuurd
					</span>
				)}
				{item.status === "connected" && (
					<span className="col status">
						<label>Status</label>
						Verbonden
					</span>
				)}
				<span className="col track">
					<label>Volgen</label>
					{item.shouldTrack === true ? "Volgen" : "Niet volgen"}
				</span>
				<span className="col rejected">
					<label>Is afgewezen</label>
					<button
						className={`rejection__button no__edit ${
							item.rejected === true ? "is__rejected" : ""
						}`}>
						{item.rejected === true ? "Afwijzen" : "Goedkeuren"}
					</button>
				</span>
				<span className="col comment">
					<label>Notities</label>
					{item.notes ? item.notes : "-"}
				</span>
			</div>
		);
	};

	const subtractPoint = async (index) => {
		let first = true;
		const lead = props.current.leads[index];
		const points = lead.points.filter(function (e) {
			if (first && e.type === "CUSTOM_ADD") {
				first = false;
				return;
			}
			return e;
		});
		if (first === true) {
			points.push({
				post: "/Posts/custom-" + new Date().getTime(),
				type: "CUSTOM_SUBTRACT",
			});
		}

		const data = await leadUpdate(
			lead.documentId,
			{
				points: points,
			},
			props.current.account
		);

		const newLeads = [];
		props.current.leads.map((element, i) => {
			if (element.documentId === lead.documentId) {
				let info = {
					...element,
					points: data.data.points,
				};
				if (typeof data.data.thresholdHit == "boolean") {
					info.thresholdHit = data.data.thresholdHit;
				}
				newLeads.push(info);
			} else {
				newLeads.push(element);
			}
		});

		//props.setAccountDetailLeads(leads);
		props.setCurrentLeads(newLeads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = newLeads;
		props.setLeads(leadCollection);
	};

	const addPoint = async (index) => {
		let first = true;
		const lead = props.current.leads[index];
		const points = lead.points.filter(function (e) {
			if (first && e.type === "CUSTOM_SUBTRACT") {
				first = false;
				return;
			}
			return e;
		});
		if (first === true) {
			points.push({
				post: "/Posts/custom-" + new Date().getTime(),
				type: "CUSTOM_ADD",
			});
		}

		const data = await leadUpdate(
			lead.documentId,
			{
				points: points,
			},
			props.current.account
		);

		const newLeads = [];
		props.current.leads.map((element, i) => {
			if (element.documentId === lead.documentId) {
				let info = {
					...element,
					points: data.data.points,
				};
				if (typeof data.data.thresholdHit == "boolean") {
					info.thresholdHit = data.data.thresholdHit;
				}
				if (typeof data.data.shouldTrack == "boolean") {
					info.shouldTrack = data.data.shouldTrack;
				}
				newLeads.push(info);
			} else {
				newLeads.push(element);
			}
		});
		//props.setAccountDetailLeads(leads);
		props.setCurrentLeads(newLeads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = newLeads;
		props.setLeads(leadCollection);
	};

	const storeNotes = async () => {
		const response = await leadUpdate(
			lead,
			{
				notes: textareaRef.current.value,
			},
			props.current.account
		);

		const _leads = [];
		props.current.leads.map((element, i) => {
			if (element.documentId === lead) {
				_leads.push({
					...element,
					notes: response.data.notes,
				});
			} else {
				_leads.push(element);
			}
		});
		if (_leads.length > 0) {
			setStoredNotes(true);
			setTimeout(() => {
				setStoredNotes(false);
			}, 500);
		}
		//props.setAccountDetailLeads(leads);
		props.setCurrentLeads(_leads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = _leads;
		props.setLeads(leadCollection);
	};

	const rejectLead = async (index) => {
		const item = props.current.leads[index];
		const response = await leadUpdate(
			item.documentId,
			{
				rejected: item.rejected ? false : true,
			},
			props.current.account
		);
		const newLeads = props.current.leads.map((el, i) => {
			return index !== i
				? el
				: {
						...el,
						...response.data,
				  };
		});
		//props.setAccountDetailLeads(leads);
		props.setCurrentLeads(newLeads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = newLeads;
		props.setLeads(leadCollection);
	};

	const outputEditRow = (item, index) => {
		const total = countPointsTotal(item.points);
		return (
			<div
				key={index}
				data-id={item.documentId}
				className={`single__lead${
					item.thresholdHit === true ? " hot__lead" : ""
				}`}>
				<span className="col name">
					<label>Naam</label>
					{item.name}
				</span>
				<span className="col points">
					<label>Punten</label>
					<button
						onClick={() => subtractPoint(index)}
						className="lead__subtract__custom">
						-
					</button>
					{total}
					<button
						onClick={() => addPoint(index)}
						className="lead__add__custom">
						+
					</button>
				</span>
				<span className="col status">
					<label>Status</label>
					<select
						value={item.status}
						onChange={(e) => {
							updateStatus(e, item, index);
						}}>
						<option value="open">Open</option>
						<option value="requested">Verzoek verstuurd</option>
						<option value="connected">Verbonden</option>
					</select>
				</span>
				<span className="col track">
					<label>Volgen</label>
					<select
						value={item.shouldTrack === true ? "track" : "untrack"}
						onChange={(e) => {
							updateTracking(e, item, index);
						}}>
						<option value="untrack">Niet volgen</option>
						<option value="track">Volgen</option>
					</select>
				</span>
				<span className="col rejected">
					<label>Is afgewezen</label>
					<button
						className={`rejection__button ${
							item.rejected === true ? "is__rejected" : ""
						}`}
						onClick={() => rejectLead(index)}>
						{item.rejected === true ? "Afwijzen" : "Goedkeuren"}
					</button>
				</span>
				<span className="col comment">
					<label>Notities</label>
					<textarea
						value={item.notes}
						onChange={() => {
							const newLeads = [];
							leads.map((element) => {
								if (element.documentId === lead) {
									newLeads.push({
										...element,
										notes: textareaRef.current.value,
									});
								} else {
									newLeads.push(element);
								}
							});
							//props.setAccountDetailLeads(leads);
							props.setCurrentLeads(newLeads);
							const leadCollection = {
								...props.collections.leads,
							};
							leadCollection[id] = newLeads;
							props.setLeads(leadCollection);
						}}
						ref={textareaRef}
						placeholder="Plaats opmerking"></textarea>
					<button
						className={`${storedNotes ? "active" : ""}`}
						onClick={storeNotes}>
						Notitie opslaan
					</button>
				</span>
				{
					<span
						className="col options"
						onClick={(e) => {
							e.stopPropagation();
							setActiveMenu(
								activeMenu === null || index !== activeMenu
									? index
									: null
							);
						}}>
						<ul
							className={`pop__up__menu${
								activeMenu === index ? " active" : ""
							}`}>
							<li
								onClick={(e) => {
									e.stopPropagation();
									setEdit(null);
									setActiveMenu(null);
								}}>
								Klaar
							</li>
							<li
								onClick={(e) => {
									e.stopPropagation();
									setActiveMenu(null);
								}}>
								Sluiten
							</li>
						</ul>
					</span>
				}
			</div>
		);
	};

	const outputLeads = (item, index) => {
		if (item.documentId !== lead) {
			return null;
		}
		if (editing === index || editing == "all") {
			return outputEditRow(item, index);
		}
		return outputViewRow(item, index);
	};

	const toggleEdit = () => {
		if (editing === "all") {
			setEdit(null);
		} else {
			setEdit("all");
		}
	};

	const doRemoveLead = () => {
		// leadDelete(item.documentId);
	};

	const outputRemovePopUp = (item, index) => {
		return createPopUp([
			<h2 key={0}>Weet je zeker dat je deze post wilt verwijderen?</h2>,
			<button key={1} className="remove__confirm" onClick={doRemoveLead}>
				Ja, verwijder de post
			</button>,
			<button
				key={2}
				className="cancel__confim"
				onClick={cancelRemoveLeast}>
				Annuleren
			</button>,
		]);
	};

	const cancelRemoveLeast = (e) => {
		setRemoveLead(false);
	};

	const createPopUp = (elements) => {
		return (
			<div className="post__pop__up__container">
				<div className="pop__up__inner">{elements}</div>
			</div>
		);
	};

	const getNameOfCurrentLeadName = () => {
		if (!leads) {
			return null;
		}
		const current = leads.filter((item, index) => {
			return item.documentId === lead ? true : false;
		});
		return current[0].name;
	};

	if(!isAdmin()){
		return null;
	}
	return (
		<div
			className={`lead__container${
				props.parser.isRunning ? " loading__screen" : ""
			}`}>
			<Link className="return__link" to={`/account/${id}/leads`}>
				Naar klant
			</Link>
			<h2>
				{getNameOfCurrentLeadName()}
				<button
					onClick={() => {
						toggleEdit();
					}}
					className={`${
						editing === "all" ? "i__edit active" : "i__edit"
					}`}></button>
			</h2>
			<div className="leads__inner">
				{leads && leads.map(outputLeads)}
			</div>
			{removeLead !== false && outputRemovePopUp()}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			leadsAreFetched: leadsAreFetched(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		detail: {
			leads: state.accountDetailReducer.leads,
		},
		parser: {
			user: state.parserReducer.user,
		},
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAccountDetailLeads: (leads) => {
			dispatch(setAccountDetailLeads(leads));
		},
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setLeads: (leads) => {
			dispatch(setLeads(leads));
		},
		setCurrentLeads: (leads) => {
			dispatch(setCurrentLeads(leads));
		},
		setAccounts: (accounts) => {
			dispatch(setAccounts(accounts));
		},
		setFetchedLeads: (leads) => {
			dispatch(setFetchedLeads(leads));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadView);
