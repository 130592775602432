import { sleep } from "../helpers/functions";
import { leadInsert } from "./database/Leads";

export async function parseSalesNavigatorHTML(view, callback) {
	const children = view.querySelectorAll(
		".horizontal-person-entity-lockup-4 figure > a"
	);

	const isConnected = (element) => {
		element = element.parentNode.parentNode.querySelector(
			"div .list-detail__badge-separator .list-lead-detail__degree-badge"
		);
		element = !element ? false : element.innerHTML.toLowerCase();
		return !element ||
			(element.indexOf("1st") < 0 && element.indexOf("self") < 0)
			? false
			: true;
	};

	const findId = (element) => {
		const link = element.href;
		const linkFiltered = link.replace("https://www.linkedin.com/sales/people/", "");
		const href = linkFiltered.replace("https://www.linkedin.com/sales/lead/", "")
		const parts = href.split(",");
		return parts[0];
	};

	const findName = (element) => {
		element = element.querySelector(".a11y-text");
		return element.innerHTML;
	};

	const findCompany = (element) => {
		element = element.parentNode.parentNode;
		if(element.parentNode.nodeName == "TD"){
			element = element.parentNode;
		} else if(!element.nextSibling.classList.contains('list-lead-detail__account')){ return null; }
		
		element = element.nextSibling;		
		element = element.querySelector(".company-entity [data-anonymize='company-name']");

		return element ? element.innerHTML : null;
	};

	const findOccupation = (element) => {
		element = element.parentNode.parentNode.querySelector(
			"div [data-anonymize='job-title']"
		);
		return element ? element.innerHTML : null;
	};

	const findLocation = (element) => {
		element = element.parentNode.parentNode;
		if(element.parentNode.nodeName == "TD"){
			element = element.parentNode;
			return element.nextSibling.nextSibling.textContent;
		} else {
			return element.nextSibling.nextSibling.nodeName == "#text" ? element.nextSibling.nextSibling.textContent : null;
		}
		// element =
		// 	element.parentNode.parentNode.nextSibling.nextSibling.querySelector(
		// 		".list-people-detail-header__geography[data-anonymize='location']"
		// 	);
		// return element ? element.innerHTML : null;
	};

	const buildItem = (element) => {
		return {
			id: findId(element),
			name: findName(element),
			company: findCompany(element),
			occupation: findOccupation(element),
			location: findLocation(element),
			isConnected: isConnected(element),
		};
	};

	let item, isInserted;
	const data = [];
	const childLength = children.length;
	const timeout = (2000 / childLength > 1) ? 2000 / childLength  : 0;
	for (let i = 0; i < children.length; i++) {
		item = buildItem(children[i]);
		children[i].remove();

		await sleep(timeout);        
		if (item.isConnected) {
			continue;
		}
		isInserted = await callback(item);
		if (isInserted) {
			data.push(item);
		}
	}

	return data;
}
