import { useEffect, useState } from "react";
import { useParams, Route, Link } from "react-router-dom";
import { connect } from "react-redux";

import {
	filterObject,
	mapObject,
	mapObjectToArray,
} from "../../helpers/functions";
import { accountFetch, accountUpdate } from "../../classes/database/Accounts";

import { useAuth } from "../../contexts/AuthContext";

import { setAccounts, setCurrentAccount } from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../selectors";


function Onboarding(props) {    
    const { id } = useParams();
    const slides = [
        'samenwerkingsVoorstel',
        'ask',
        'samenwerking'
    ];

    const [data, setData] = useState({});
    const [localStorageLoaded, setLocalStorageLoaded] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [activeSlide, setActiveSlide] = useState(slides[0]);
    const [action, setAction] = useState(null);
   
    var currentQuestion = null;
    var currentSlide = null;

    const accounts = props.collections.accounts
    ? props.collections.accounts
    : {};

    useEffect(() => {
        if(!window || !window.localStorage){
            return;
        }

        setLocalStorageLoaded(true);
    }, [window.localStorage]);

    useEffect(async () => {     
        
		const user = accounts[id] ? accounts[id] : await accountFetch(id);
        if(user.onboarding){
            setData(user.onboarding);
        }
        props.setCurrentAccount(user);
		if (!accounts[id]) {
            const _accounts = { ...accounts };
			_accounts[id] = user;
			props.setAccounts(_accounts);
		}
		return () => {
            props.setCurrentAccount(null);
		};
	}, []);

    const questions = {
        samenwerkingsVoorstel: [
            {
                title: "Wat zijn het komend jaar de doelen van je organisatie?",
                category:"Bedrijfsambities",
                type: "textarea"
            },
            {
                title: "Welke producten of diensten biedt jouw organisatie aan?",
                category:"Bedrijfsambities",
                type: "textarea"
            },
            {
                title: "Wat is de gemiddelde marge op deze producten per categorie?",
                category:"Bedrijfsambities",
                type: "text"
            },
            {
                title: "Welke markten zijn interessant voor deze producten?",
                category:"Bedrijfsambities",
                type: "text"
            },
            {
                title: "Hoe belangrijk vind je het om meer bekendheid in de markt te krijgen?",
                category:"Bedrijfsambities",
                type: "text"
            },
            {
                title: "Hoe belangrijk vind je het om nieuwe klanten te ontmoeten?",
                category:"Bedrijfsambities",
                type: "text"
            },
            {
                title: "Wat verwacht u uit account based marketing te halen?",
                category:"Bedrijfsambities",
                type: "textarea"
            },
            {
                title: "Hoeveel accountmanagers zouden zich willen aansluiten bij de ABM strategie?",
                category:"Bedrijfsambities",
                type: "number"
            },
            {
                title: "Wat hoop je als uitkomst te zien van onze samenwerking?",
                category:"Bedrijfsambities",
                type: "textarea"
            },
            {
                title: "Heb je dingen die je specifiek kwijt wil aan ons?",
                category:"Bedrijfsambities",
                type: "textarea"
            }
        ],
        ask: [
            {
                title: "Uitbreiding over doelen",
                category: "Contentstrategie",
                type: "text"
            },
            {
                title: "Uitbreiding over product / markt / marges",
                category: "Contentstrategie",
                type: "text"
            },
            {
                title: "Waar moeten mensen aan denken als ze aan jouw merk denken",
                category: "Contentstrategie",
                type: "text"
            },
            {
                title: "Strategie Klok",
                category: "Contentstrategie",
                type: "text"
            },
            {
                title: "Wat is jullie visie",
                category: "Contentstrategie",
                type: "text"
            },
            {
                title: "Wat is jullie missie",
                category: "Contentstrategie",
                type: "text"
            },
            {
                title: "Waarom kiezen klanten voor jou en niet een concurrent",
                category: "Contentstrategie",
                type: "text"
            },
            {
                title: "Als een klant aan je vraagt “waarom sta jij en je medewerkers in de ochtend op om naar werk te gaan”, hebben jullie daar een antwoord?",
                category: "Contentstrategie",
                type: "text"
            },
            {
                title: "Wat is hun functietitel?",
                category: "Social Selling",
                subCategory: "Droom connectie",
                type: "textarea"
            },
            {
                title: "Bij welke organisaties werken ze?",
                category: "Social Selling",
                subCategory: "Droom connectie",
                type: "textarea"
            },
            {
                title: "Waar bevinden ze zich?",
                category: "Social Selling",
                subCategory: "Droom connectie",
                type: "textarea"
            },
            {
                title: "Wat heb je ze te bieden?",
                category: "Social Selling",
                subCategory: "Droom connectie",
                type: "textarea"
            },
            {
                title: "Wat voor afspraak wil je met deze connecties maken?",
                category: "Social Selling",
                subCategory: "Droom connectie",
                type: "textarea"
            },
            {
                title: "Welke huidige connecties wil je dat we in de gaten houden naast de nieuwe connecties?",
                category: "Social Selling",
                type: "textarea"
            },
            {
                title: "Wat is de aanspreekvorm",
                category: "Social Selling",
                subCategory: "Tone of voice",
                type: "text"
            },
            {
                title: "[Concreet voorbeeld met betrekking op de klant, laat hem of haar aangeven hoe hierop gereageerd wordt]",
                category: "Social Selling",
                subCategory: "Tone of voice",
                type: "textarea"
            },
            {
                title: "Generiek: “Wat mooi hoe jullie aan het bouwen zijn aan de organisatie!”",
                category: "Social Selling",
                subCategory: "Tone of voice",
                type: "text"
            },             
            {
                title: "Wie willen we bereiken?",
                category: "Advertentiestrategie",
                subCategory: "Targetgroepen",
                type: "textarea"
            },
            {
                title: "Wat is hun functietitel?",
                category: "Advertentiestrategie",
                subCategory: "Targetgroepen",
                type: "textarea"
            },
            {
                title: "Bij welke organisaties werken ze?",
                category: "Advertentiestrategie",
                subCategory: "Targetgroepen",
                type: "textarea"
            },
            {
                title: "Waar bevinden ze zich?",
                category: "Advertentiestrategie",
                subCategory: "Targetgroepen",
                type: "textarea"
            },
            {
                title: "Wat heb je ze te bieden?",
                category: "Advertentiestrategie",
                subCategory: "Targetgroepen",
                type: "textarea"
            },
            {
                title: "Wat voor afspraak wil je met deze connecties maken?",
                category: "Advertentiestrategie",
                subCategory: "Targetgroepen",
                type: "textarea"
            },
            {
                title: "Welke gegevens willen we hebben van de leads",
                category: "Advertentiestrategie",
                subCategory: "Leadcampagne",
                type: "textarea"
            },
            {
                title: "Hoe volgen we de leads op?",
                category: "Advertentiestrategie",
                subCategory: "Leadcampagne",
                type: "textarea"
            },
        ],
        samenwerking: [
            {
                title: "Hebben jullie een huisstijldocument, kun je deze aanleveren",
                category:"Benodigdheden",
                type: "yes/no"
            },

            {
                title: "Heb je bestanden van je logo, kun je deze aanleveren",
                category:"Benodigdheden",
                type: "yes/no"
            },

            {
                title: "Heb je lettertypebestanden, kun je deze aanleveren",
                category:"Benodigdheden",
                type: "yes/no"
            },

            {
                title: "Hebben jullie interne fotografie en videografie, kun je deze aanleveren",
                category:"Benodigdheden",
                type: "yes/no"
            },

            {
                title: "Wat zijn jullie social media accounts / inlogs, kun je deze aanleveren",
                category:"Benodigdheden",
                type: "yes/no"
            },

            {
                title: "Hebben jullie een website waarop blogs geplaatst kunnen worden",
                category:"Benodigdheden",
                type: "yes/no"
            },

            {
                title: "Zijn er contactgegevens beschikbaar van X en X personen die we kunnen benaderen voor content",
                category:"Benodigdheden",
                type: "yes/no"
            },

            {
                title: "Wie wordt ons hoofdcontactpersoon",
                category:"Benodigdheden",
                type: "yes/no"
            },

            {
                title: "Inloggegevens profielen en beheerdersrechten pagina (relevante social kanalen)",
                category:"Benodigdheden",
                type: "yes/no"
            },

            {
                title: "Eventueel toegang tot advertentieaccounts (relevante social kanalen)",
                category:"Benodigdheden",
                type: "yes/no"
            }

        ]
    };

    const updateParsedUser = (response) => {
		if (!response.data) {
			return;
		}
		const account = {
			...props.current.account,
			...response.data,
		};
		
		const _accounts = { ...accounts };
		_accounts[account.documentId] = account;

		props.setCurrentAccount(account);
		props.setAccounts(_accounts);
	};

	const updateOnboarding = async (_data) => {
		const response = await accountUpdate(props.current.account.documentId, {
			onboarding: _data,
		});
		updateParsedUser(response);
	};

    const determineCurrentSlide = (object, slideIndex, key, index) => {
        if(action == 'previous' && currentQuestion == `${slideIndex}_${index + 1}`) {
            setAction(null);
            currentQuestion = null;
        } else if(action == 'previous' && currentQuestion == `${slideIndex}_0`){
            setAction(null);
            slides.map((slide, position) =>{
                if(position > 0 && slide == activeSlide){
                    currentSlide = slides[position - 1];
                    currentQuestion = `${currentSlide}_${questions[currentSlide].length - 1}`;
                }
            });
            return;
        }

        if(currentQuestion == null && activeSlide == currentSlide){
            currentQuestion = key;
        } else if(action == 'next' && currentQuestion == key){
            if(index < object.length - 1){
                currentQuestion = null;
                setAction(null);
            } else {
                setAction(null);
                slides.map((slide, position) =>{
                    if(position + 1 <= slides.length - 1 && slide == activeSlide){
                        currentSlide = slides[position + 1];
                        currentQuestion = null;
                    }
                });
            }
        }
    }

    const buildSlide = (object, slideIndex, isSubform = false) => {
        if(!isSubform && activeSlide != slideIndex){
            return null;
        }
        if(activeQuestion != null) {
            currentQuestion = activeQuestion;
        }

        if(activeSlide != null){
            currentSlide = activeSlide
        }
        return (
            <div key={slideIndex} className={`onboarding__slide ${isSubform ? " sub__form" : ""}`}>    
                {object.map((item, index) => {
                    const key = `${slideIndex}_${index}`;
                    if(isSubform && currentQuestion == slideIndex){
                        currentQuestion = key;
                    }

                    determineCurrentSlide(object, slideIndex, key, index);
                    return (
                        <div key={key} className={`onboarding__question${key == currentQuestion ? ' active' : ''}`}>
                            <h2 className={`onboarding__title${item.type =='yes/no' ? ' center' : ''}`}>{item.title}</h2>
                            {item.category && <h3 className="onboarding__category">{item.category}</h3>}
                            {item.subCategory && <h4 className="onboarding__sub__category">{item.subCategory}</h4>}
                            {buildResponseField(index, key, item.type, item)}
                        </div>
                    );
                })}
                {!isSubform && (
                    <div className="slider__actions">
                        <button onClick={(e)=>setAction("previous")} className={`previous__slide${currentQuestion != `${slides[0]}_0` ? '':' hide'}`}>Vorige</button>
                        <button onClick={saveOnboarding} className="store">Opslaan</button>
                        <button onClick={(e)=>setAction("next")} className={`next__slide${currentQuestion != `${slides[slides.length - 1]}_${(questions[slides[slides.length - 1]]).length - 1}` ? '':' hide'}`}>Volgende</button>
                    </div>
                )}
            </div>
        );
    }
    const buildResponseField = (slideIndex, index, type, object) => {
        if(type == "text"){
            return (
                <input placeholder="Voer antwoord in" className={`onboarding_question`} type="text" name={index} onChange={(e)=>{ setStoreInput(index, e.target.value); }} value={getStoredInput(index)}/>
            );
        }
        if(type == "textarea"){
            return (
                <textarea placeholder="Voer antwoord in" className={`onboarding_question`} name={index} onChange={(e)=>{ setStoreInput(index, e.target.value); }} value={getStoredInput(index)}></textarea>
            );
        }
        if(type == "number"){
            return (
                <input placeholder="Voer antwoord in" className={`onboarding_question`} type="number" name={index} onChange={(e)=>{ setStoreInput(index, e.target.value); }} value={getStoredInput(index)}/>
            );
        } 
        if (type == "yes/no"){
            return (
                <div className="radio__container">
                    <label className="radio__option">
                        <input type="radio" className={`onboarding_question`} name={index} onChange={(e)=>{ setStoreInput(index, e.target.value); }} value="yes"/>
                        <span>Ja</span>
                    </label>
                    <label className="radio__option">
                        <input type="radio" className={`onboarding_question`} name={index} onChange={(e)=>{ setStoreInput(index, e.target.value); }} value="no"/>
                        <span>Nee</span>
                    </label>
                </div>
            );        
        }
        return null;

    }

    const setStoreInput = (key, value) => {
        let local = window.localStorage.getItem('zd-onboarding');
        if(!local){
            local = {};
        } else {
            local = JSON.parse(local);
            if(!local){
                local = {};
            }
        }

        const temp = {...local, ...data};
        temp[key] = value;

        window.localStorage.setItem('zd-onboarding', JSON.stringify(temp));
        setData(temp);
    };

    const getStoredInput = (key) => {
        if(data[key]){
            return data[key];
        }

        let local = window.localStorage.getItem('zd-onboarding');
        if(!local){
            return '';
        }
        
        local = JSON.parse(local);
        if(local && local[key]){
            return local[key];
        }

        return '';
    };

    const saveOnboarding = () => {
        let local = window.localStorage.getItem('zd-onboarding');
        if(!local){
            local = {};
        } else {
            local = JSON.parse(local);
            if(!local){
                local = {};
            }
        }

        updateOnboarding({
            ...local,
            ...data
        });
    }

	if(!localStorageLoaded){
        return false;
    }

    const _slides = [
        buildSlide(questions.samenwerkingsVoorstel, 'samenwerkingsVoorstel'),
        buildSlide(questions.ask, 'ask'),
        buildSlide(questions.samenwerking, 'samenwerking')
    ];
    if(currentQuestion != activeQuestion){
        setActiveQuestion(currentQuestion);
    }
    if(currentSlide != activeSlide){
        setActiveSlide(currentSlide);
    }

    
	return (
        <div className="slide__container">
            {_slides}
        </div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
