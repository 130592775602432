/*

	opslaanbaar / bewerkbaar maken

	PDF genereren op basis van selectie

*/

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";

function Navigation(props) {
	const navigate = useNavigate();
	const [showMenu, setShowMenu] = useState(false);
	const { signOut, currentUser, userData, isAdmin } = useAuth();

	const handleLogout = async () => {
		try {
			const response = await signOut();
			navigate("/login");
		} catch (exception) {
			// console.log("error", exception);
		}
	};

	useEffect(() => {
		if(!window || !window.location){
			return null;
		}
		
		if(window.location.pathname.indexOf('/onboarding') >= 0){
			return null
		}

		setShowMenu(true);
	}, [window.location]);
	if(!showMenu){
		return null;
	}

	return (
		<nav className="nav__container">
			<div className="nav__inner container">
				<figure>
					<img src={`${process.env.PUBLIC_URL}/Werk - logo.png`} />
				</figure>
				<ul>
					{/* <li>
							<Link to="/">Home</Link>
						</li>
						<li>
							<Link to="/companies">Bedrijven</Link>
						</li> */}
					{currentUser && userData && isAdmin() === true && (
						<li>
							<Link to="/social-selling">Social selling</Link>
						</li>
					)}
					{currentUser && userData && isAdmin() === true && (
						<li>
							<Link to="/accounts">Klanten</Link>
						</li>
					)}
					{currentUser && (
						<li>
							<button className="logout" onClick={handleLogout}>
								Uitloggen
							</button>
						</li>
					)}
				</ul>
			</div>
		</nav>
	);
}

const mapStateToProps = (state) => {
	return {
		appState: state.appReducer.appState,
	};
};

export default Navigation;
