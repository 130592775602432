import { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";

function SignUp(props) {
	const leadNameRef = useRef();
	const leadIdRef = useRef();
	const emailRef = useRef();
	const companyRef = useRef();
	const passwordRef = useRef();
	const passwordConfirmRef = useRef();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const { isAdmin, signUp, currentUser } = useAuth();

	const navigate = useNavigate();

	const handleEvent = async (e) => {
		e.preventDefault();

		if (passwordRef.current.value !== passwordConfirmRef.current.value) {
			return setError("Het wachtwoord komt niet overeen"); // return error message
		}

		try {
			setError("");
			setLoading(true);
			const response = await signUp(
				emailRef.current.value,
				passwordRef.current.value,
				{
					id: leadIdRef.current.value,
					name: leadNameRef.current.value,
					company: companyRef.current.value
				}
			);
			setLoading(false);
			navigate("/");
		} catch (Exception) {
			setError(
				"Er is iets fout gegaan tijdens het aanmaken van het account"
			);
			setLoading(false);
		}
	};

	if(!isAdmin()){
		return null;
	}
	return (
		<div className="container zd__lln__user__container">
			<div className="zd__lln__login__main">
				<h2>Klant aanmaken</h2>
				{error && (
					<div className="zd__lln__notice zd__lln__error">
						{error}
					</div>
				)}
				<form onSubmit={handleEvent}>
					<div>
						<label>Naam</label>
						<input
							key={1}
							ref={leadNameRef}
							type="text"
							name="name"
							placeholder="Vul naam in..."
						/>
					</div>
					<div>
						<label>LinkedIn Slug</label>
						<input
							key={1}
							ref={leadIdRef}
							type="text"
							name="name"
							placeholder="Vul LinkedIn user slug in..."
						/>
					</div>
					<div>
						<label>E-mail</label>
						<input
							ref={emailRef}
							type="email"
							placeholder="Vul e-mailadres in..."
						/>
					</div>
					<div>
						<label>Bedrijf</label>
						<input
							ref={companyRef}
							type="text"
							placeholder="Vul bedrijfsnaam in..."
						/>
					</div>
					<div>
						<label>Wachtwoord</label>
						<input
							ref={passwordRef}
							type="password"
							placeholder="Vul wachtwoord in..."
						/>
					</div>
					<div>
						<label>Wachtwoord</label>
						<input
							ref={passwordConfirmRef}
							type="password"
							placeholder="Herhaal wachtwoord..."
						/>
					</div>
					<input disabled={loading} type="submit" value="Aanmelden" />
				</form>
			</div>
			{/* <div className="zd__lln__login__secondary">
				Heb je al een account? <Link to="/login">Log in</Link>
			</div> */}
		</div>
	);
}

export default SignUp;
