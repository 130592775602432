import { useState } from "react";
import { connect } from "react-redux";

import { getLeads, getPosts, getAccounts, getAccountLeads, getAccountPosts, getAccount } from "../../../selectors";

import { accountUpdate } from "../../../classes/database/Accounts";
import { setCurrentAccount, setAccounts } from "../../../redux";

function AccountSettings(props) {
	const accounts = props.collections.accounts ? props.collections.accounts : [];
	const [editing, setEditing] = useState(false);

	const defaultChannels = [
		{active: false, name: "LinkedIn", followers: null},
		{active: false, name: "Instagram", followers: null},
		{active: false, name: "Facebook", followers: null},
		{active: false, name: "Twitter", followers: null},
		{active: false, name: "TikTok", followers: null}
	];

	const toggleEdit = () => {
		setEditing(editing ? false : true);
	};

	const outputSocialSelling = () => {
		return [
			<h3>
				Social Selling
			</h3>,
			(typeof props.data.pointThreshold == "number" && (
				<div className="setting__option">
					<label>Puntengrens</label>
					<span>{props.data.pointThreshold}</span>
				</div>
			)),
			(typeof props.data.socialSellingLimit == "number" && (
				<div className="setting__option">
					<label>Uitnodigingen per dag</label>
					<span>{props.data.socialSellingLimit}</span>
				</div>
			))
		]
	}

	const outputMarketingSettings = () => {
		return [
			<h3>
				Content Marketing
			</h3>,
			<div key={0} className="setting__option">
				<label>Zoho Klant Id</label>
				<span>{props.data.zohoId ? props.data.zohoId : "-leeg-"}</span>
			</div>,
			<div key={2} className="setting__option">
				<label key={0}>Social kanalen</label>
				<div className="social__channels" key={1}>
					{(props.data.channels || defaultChannels).map((item, index) => {
						return <div>
							<span key={index} className={`${item.active ? 'checkbox__emulator active': 'checkbox__emulator'}`}>{item.name}</span>
							{item.followers ? <div className="social__followers"><span>Volgers</span><span>{item.followers}</span></div> : ''}
						</div>;	
					})}
				</div>
			</div>
		];
	}

	const outputViewAccount = () => {
		return (
			<div className="setting__options container">
				<h2>
					Instellingen
					<button
						onClick={() => {
							toggleEdit();
						}}
						className={`${
							editing === "all" ? "i__save" : "i__edit"
						}`}></button>
				</h2>
				{props.mode == "social-selling" ? outputSocialSelling() : null}
				{props.mode == "content-marketing" ? outputMarketingSettings() : null}
			</div>
		);
	};

	const updateParsedUser = (response) => {
		if (!response.data) {
			return;
		}
		const account = {
			...props.current.account,
			...response.data,
		};
		
		const _accounts = { ...accounts };
		_accounts[account.documentId] = account;

		props.setCurrentAccount(account);
		props.setAccounts(_accounts);
	};

	const updateZohoId = async (e) => {
		const data = await accountUpdate(props.current.account.documentId, {
			zohoId: e.target.value,
		});
		updateParsedUser(data);
	};

	const updateThreshold = async (e) => {
		const data = await accountUpdate(props.current.account.documentId, {
			pointThreshold: parseInt(e.target.value),
		});
		updateParsedUser(data);
	};

	const updateLimit = async (e) => {
		const data = await accountUpdate(props.current.account.documentId, {
			socialSellingLimit: parseInt(e.target.value),
		});
		updateParsedUser(data);
	}

	const updateFollowers = async (e, item, index) => {
		const items = [
			...(props.data.channels || defaultChannels)
		];
		items[index].followers = e.target.value;
		const data = await accountUpdate(props.current.account.documentId, {
			channels: items
		});
		updateParsedUser(data);
	};

	const updateChannel = async (e, item, index) => {
		const items = [
			...(props.data.channels || defaultChannels)
		];
		items[index].active = e.target.checked;
		const data = await accountUpdate(props.current.account.documentId, {
			channels: items
		});
		updateParsedUser(data);
	}

	const outputEditMarketingSettings = () => {
		return ([
				<h3>
					Content Marketing
				</h3>,
				<div key={0} className="setting__option">
					<label key={0}>Zoho Klant Id</label>
					<input
					 	key={1}
						type="number"
						value={props.data.zohoId ? props.data.zohoId : ''}
						onChange={updateZohoId}
					/>
				</div>,
				// <div key={1} className="setting__option">
				// 	<label key={0}>Aantal volgers</label>
				// 	<input
				// 	 	key={1}
				// 		type="number"
				// 		value={props.data.followers ? props.data.followers : ''}
				// 		onChange={updateFollowers}
				// 	/>
				// </div>,
				<div key={2} className="setting__option">
					<label key={0}>Social kanalen</label>
					<div className="social__channels" key={1}>
						{(props.data.channels || defaultChannels).map((item, index) => {
							return (
								<div>
									<label key={index}>{item.name}
										<input onChange={(e) => updateChannel(e, item, index)} checked={item.active} type="checkbox" className={`${item.active ? 'active': ''}`}/>
									</label>
									<div key={1} className="setting__option">
										<label key={0}>Aantal volgers</label>
										<input
											key={1}
											type="number"
											value={item.followers ? item.followers : 0}
											onChange={(e) => updateFollowers(e, item, index)}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			]);
	}
	const outputEditSocialSelling = () => {
		return [<h3>
					Social Selling
				</h3>,
				typeof props.data.pointThreshold == "number" && (
					<div className="setting__option">
						<label>Puntengrens</label>
						<input
							type="number"
							value={props.data.pointThreshold}
							onChange={updateThreshold}
						/>
					</div>
				),
				typeof props.data.socialSellingLimit == "number" && (
					<div className="setting__option">
						<label>Uitnodigingen per dag</label>
						<input
							type="number"
							value={props.data.socialSellingLimit}
							onChange={updateLimit}
						/>
					</div>
				)
			];
	}

	const outputEditAccount = () => {
		return (
			<div className="setting__options container">
				<h2>
					Instellingen
					<button
						onClick={() => {
							toggleEdit();
						}}
						className={`${
							editing === true ? "i__save" : "i__edit"
						}`}></button>
				</h2>
				{props.mode == "social-selling" ? outputEditSocialSelling() : null}
				{props.mode == "content-marketing" ? outputEditMarketingSettings() : null}
			</div>
		);
	};

	const outputAccountSettings = () => {
		if (editing) {
			return outputEditAccount();
		} else {
			return outputViewAccount();
		}
	};

	return (
		<div className="account__settings__container container">
			{outputAccountSettings()}
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},		
		setCurrentAccount: (users) => {
			dispatch(setCurrentAccount(users));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections : {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state)
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
