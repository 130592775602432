import React, { useContext, useEffect, useState } from "react";
import {
	auth,
	createUser,
	loginUser,
	signOutUser,
	resetPasswordUser,
	updatePasswordUser,
	updateEmailUser,
	updateLoggedInUser,
	deleteAccount
} from "../classes/database/firebase";
import { accountInsert, accountFetch, accountDelete } from "../classes/database/Accounts";

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ user, setLoggedInUser, children }) {
	// const [userData, setUserData] = useState();
	const userData = user;
	const [currentUser, setCurrentUser] = useState();
	const [loading, setLoading] = useState(true);
	const [claims, setClaims] = useState();
	const [isCreating, setIsCreating] = useState(false);

	const signUp = async (email, password, data) => {
		setIsCreating(true);
		const _user = await createUser({ email, password });
		await updateLoggedInUser(currentUser);
		setIsCreating(false);
		setCurrentUser(currentUser);

		return await accountInsert({
			...data,
			uid: _user.user.uid,
			email: email,
		});
	};

	const deleteUser = async (documentId) => {
		const response = await deleteAccount(documentId);
		if(!response.success){ return false; }
		await accountDelete(documentId);
		return true;
	}

	const login = async (email, password) => {
		return await loginUser({ email, password });
	};

	const signOut = () => {
		return signOutUser();
	};

	const resetPassword = (email) => {
		return resetPasswordUser({ email });
	};

	const updateEmail = async (email) => {
		return updateEmailUser({ user: currentUser, email });
	};

	const updatePassword = (password) => {
		return updatePasswordUser({ user: currentUser, password });
	};

	const isCurrentUser = (id) => {
		return id === currentUser.uid;
	};

	const updateCurrentUser = (_user) => {
		if (!(_user.uid == currentUser.uid)) {
			return false;
		}
		setCurrentUser(user);
		return true;
	};

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (_user) => {
			if (isCreating) {
				return;
			}

			setCurrentUser(_user);
			setLoading(false);

			if (_user && (!userData || userData.documentId != _user.uid)) {
				const data = await accountFetch(_user.uid);
				setLoggedInUser(data);
			}
		});

		return unsubscribe;
	}, [currentUser]);

	useEffect(async () => {
		if (!currentUser) {
			return;
		}

		const idTokenResult = await currentUser.getIdTokenResult();
		setClaims(idTokenResult.claims);
	}, [currentUser]);

	const isAdmin = () => {
		// return true;
		if (!claims) return false;
		return claims.admin === true;
	};

	const value = {
		currentUser,
		userData,
		isAdmin,
		signUp,
		login,
		isCurrentUser,
		signOut,
		resetPassword,
		updateEmail,
		updatePassword,
		updateCurrentUser,
		deleteUser
	};

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
}
