import firebase from "./firebase";
import { getServerTimeStamp } from "./firebase";
import {
	collection,
	doc,
	getDoc,
	addDoc,
	updateDoc,
	getDocs,
	query,
	where,
	orderBy,
	deleteDoc,
	documentId,
} from "firebase/firestore";

import { shallowEqual } from "../../helpers/functions";

const buildRef = (documentId) => {
	return collection(firebase, "Posts", documentId, "List");
};
export const postRef = collection(firebase, "Posts");

export const postInsert = async (item, owner) => {
	if (typeof owner == "undefined" || owner == null) {
		return false;
	}
	const ref = buildRef(owner);
	//postRef
	const docRef = await addDoc(ref, {
		name: item.name,
		created: getServerTimeStamp(),
		owner: "/Accounts/" + owner,
	});
	return await getDoc(docRef);
};
export const postFetchAll = async (documentId) => {
	if (!documentId) {
		return;
	}
	const ref = buildRef(documentId);
	// postRef
	let q = query(ref, orderBy("created", "desc"));
	// q = query(q, where("owner", "==", "/Accounts/" + documentId));

	const snapshot = await getDocs(q);
	const data = [];
	snapshot.forEach((element) => {
		data.push({
			...element.data(),
			documentId: element.id,
		});
	});

	if (data.length > 0) {
		return data;
	}
	return false;
};
export const postUpdate = async (documentId, data, userId) => {
	var documentRef = doc(firebase, "Posts", userId, "List", documentId);
	const result = await updateDoc(documentRef, data);
	return {
		data: {
			...data,
			documentId: documentId,
		},
	};
};

export const postDelete = async (documentId, userId) => {
	try {
		const documentRef = doc(firebase, "Posts", userId, "List", documentId);
		const response = await deleteDoc(documentRef);
	} catch(e){
		console.log(e);
	}
};

export const postExists = async (query) => {
	const snapshot = await getDocs(query);
	const data = [];
	snapshot.forEach((element) => {
		data.push({
			...element.data(),
			documentId: element.id,
		});
	});

	if (data.length > 0) {
		return data;
	}
	return false;
};

export async function updatePointCount(item, type) {
	const documentId = sessionStorage.getItem("zd__lln__customer");
	const post = sessionStorage.getItem("zd__lln__post");

	if (typeof documentId == "undefined" || documentId == null) {
		return false;
	}
	if (typeof post == "undefined" || post == null) {
		return false;
	}

	let base = query(postRef, where("owner", "==", "/Accounts/" + documentId));
	let baseProps = {
		post: post,
		type: type,
	};

	let q = query(base, where("id", "==", item.id));

	const temp = await postExists(q);

	if (!temp) {
		return false;
	}
	if (temp[0].shouldTrack == false) {
		return false;
	}

	if (typeof temp[0].points == "undefined") {
		temp[0].points = [];
	}

	const props = {
		...baseProps,
	};

	if (temp[0].points.some((item) => shallowEqual(item, props))) {
		return false;
	}
	const result = await postUpdate(temp[0].documentId, {
		points: [...temp[0].points, props],
	});
	return item;
}
