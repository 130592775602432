function Companies(props) {
	return (
		<div className="app__inner container">
			<h1>Bedrijven</h1>
        </div>
    );
}

const mapStateToProps = (state) => {
	return {
		appState: state.appReducer.appState,
	};
};

export default Companies;
