import { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import { accountFetch, accountUpdate } from "../../classes/database/Accounts";
import { contentFetch, contentUpdate } from "../../classes/database/Content";
import { debounce, dateRange, weeksBetween, getWeekNumber, months, days } from "../../helpers/functions";

import Calendar from "./Calendar";

import SocialTextarea from "../accounts/view/SocialTextarea";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../selectors";
import GoogleDriveUploader from "./GoogleDriveUploader";


function SocialFeedback(props) {
	const { id, planningId } = useParams();
    const [data, setData] = useState();
    const [input, setInput] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    const [removePost, setRemovePost] = useState(null);
    const [selected, setSelected] = useState(null);
    const [showDone,  setShowDone] = useState(null);
    
    useEffect(async () => {
        const feedback = typeof props.data == 'undefined' ? [] : props.data;
        setData(feedback);
	}, [props.data]);
    
    const updateDriveId = async (id, version) => {
        if(!id){ return; }
        
        const newData = {
            ...data,
            driveFolderId: id
        }
        await contentUpdate(planningId, newData, id);
        setData(newData);
    };

    const storeFeedback = (value) => {
        if(!value || value.length == 0){ return; }
        const newData = [...data];
        const message = {
            "message": value,
            "reactions" : [],
            "userId" : props.current.account.id,
            "userName" : props.current.account.name
        };

        const selectedParts = `${selected}`.indexOf('-') >= 0 ? `${selected}`.split('-') : [selected, null];
        const selector = selectedParts[0];
        if(selected != null && selector >= 0){
            if(selectedParts[1] != null) {
                message.message = `@${newData[selector].reactions[selectedParts[1]].userName} ${message.message}`;
                newData[selector].reactions.push(message);
            } else {
                newData[selector].reactions.push(message);
            }
        } else {
            newData.push(message);
        }

        setData(newData);
        setInput("");
        props.update(newData, props.index);
    }

    const storeFeedbackOnEnter = (e) => {
        if(e.keyCode != 13){ return; }
        storeFeedback(input);
    }

    const storeFeedbackOnClick = (e) => {
        storeFeedback(input);
    }

    const doRemovePost = (index) => {
        setContextMenu(null);
        setRemovePost(null);

        const selectedParts = `${index}`.indexOf('-') >= 0 ? index.split('-') : [index, null];
        let newData = [...data];
        if(selectedParts[1] == null){
            newData = data.filter((item, i) => {
                return index != i ? true : false;
            });
        } else {
            newData[selectedParts[0]].reactions = newData[selectedParts[0]].reactions.filter((item, i) => {
                return selectedParts[1] != i ? true : false;
            });
        }
        setData(newData);
        setSelected(null);
        props.update(newData, props.index);
    }

    const updateFeedbackStatus = (index, status) => {
        const selectedParts = `${index}`.indexOf('-') >= 0 ? index.split('-') : [index, null];
        let newData = [...data];
        if(selectedParts[1] == null){
            newData[index]['done'] = status == 'done' ? true : status == 'open' ? false : status;
        } else {
            newData[selectedParts[0]].reactions[selectedParts[1]]['done'] = status == 'done' ? true :  status == 'open' ? false : status;
        }

        setData(newData);
        setSelected(null);
        props.update(newData, props.index);

        if(status != 'done'){ return; }
        setTimeout(() => {
            setContextMenu(null);
            updateFeedbackStatus(index, 'hide');
        }, 400);
    }

    const getFeedbackStatus = (index) => {
        const selectedParts = `${index}`.indexOf('-') >= 0 ? index.split('-') : [index, null];
        let newData = [...data];
        let status;
        if(selectedParts[1] == null){
            status = newData[index]['done'];
        } else {
            status = newData[selectedParts[0]].reactions[selectedParts[1]]['done'];
        }
        return typeof status == 'undefined' ? false : status;
    }

    const maybeOutputContextMenu = (index) => {
        const status = getFeedbackStatus(index);
        if(contextMenu != index){ return }
        let html = (
            <div className="default__inner">
                <div className="finished__post">
                    Feedback
                    <div className="slider__container">
                        <input onChange={()=>{
                            updateFeedbackStatus(index, "open")
                        }} name="finished__feedback" type="radio" checked={status == false ? true : false} value="open" />
                        <input onChange={()=>{
                            updateFeedbackStatus(index, "done")
                        }} name="finished__feedback" type="radio" checked={status == true || status == 'hide' ? true : false} value="done" />
                        <span className="zd__label">{status ? "afgerond" : "open"}</span>
                        <span className="finished__slider"></span>
                    </div>
                </div>
                <button key={0} onClick={()=>setRemovePost(index)} className="remove__post">Verwijder post</button>
                <button key={1} onClick={()=>setContextMenu(null)} className="close__menu">Sluiten</button>
            </div>
        );
        
        if(removePost != null){
            html = (<div className="remove__inner">
                <p>Weet je zeker dat je deze post wilt verwijderen?</p>
                <button onClick={()=>doRemovePost(index)} className="remove__post">Verwijderen</button>
                <button onClick={()=>{
                    setContextMenu(null);
                    setRemovePost(null);
                }} className="close__menu">Annuleren</button>
            </div>);
        }
        
        return (<div className="context__menu__pop__up">{html}</div>);
    }

    const outputMessage = (item) => {
        if(!item.driveLink){
            return <p>{item.message}</p>;
        }
        return <a href={item.driveLink} target="_blank" rel="noopener noreferrer">{item.message}</a>
    }

    const outputFeedback = (item, index) => {
        if(item.done == 'hide' && showDone != true){
            return null;
        }
        return <div key={index} onClick={(e)=>{
            e.stopPropagation();
            if(e.target.classList.contains('context__menu')){ return; }
            setSelected((selected == index ? null : index))
        }} className={`zd__feedback__item${index == selected ? ' active' : ''}${item.done ? ' done' : ''}`}>
            <div className="grid two">
                <span className="zd__social__logo"></span>
                <div className="zd__column">
                    <h2>{item.userName}<button className="context__menu" onClick={()=>setContextMenu(contextMenu === null || index != contextMenu ? index : null)}></button></h2>
                    {outputMessage(item)}
                </div>
            </div>
            {item.reactions.length > 0 && <div className="zd__feedback__reactions">{item.reactions.map((v, i) => outputFeedback(v, `${index}-${i}`))}</div>}
            {maybeOutputContextMenu(index)}
        </div>;
    }

    const outputView = () => {
        if(!data){ return null; }        
        return <div className="zd__feedback__container">
            <div className="zd__show__done" onClick={(e)=>setShowDone(showDone ? false : true)}>
                Toon afgeronde items 
                <div className="checkbox__container">
                    <input type="checkbox" name="showDone" checked={showDone} onChange={(e)=>setShowDone(e.target.checked)} />
                    <span className="checkbox"></span>
                </div>
            </div>
            {data.map(outputFeedback)}
            <div className="zd__feedback__input">
                <input type="text" name="feedback" placeholder={`Voer ${props.placeholder} in..`} onInput={(e)=>setInput(e.target.value)} value={input} onKeyDown={storeFeedbackOnEnter}/>
                <button className="zd__send" onClick={storeFeedbackOnClick}></button>
                {props.children[0]}
            </div>
            {props.children[1]}
        </div>
    }
    
    return outputView();
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialFeedback);
