import { useEffect } from "react";
import { connect } from "react-redux";

import {
	setAccountDetailPosts,
	setCurrentLeads,
	setLeads,
} from "../../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../../selectors";
import {
	setParserType,
	setParserFocus,
	setParserIsRunning,
	setParsedItems,
	setActivePost,
	PARSER_TYPE,
} from "../../../redux";
import { LinkedinParse } from "../../../classes/LinkedinParse";

function ParseControls(props) {
	const runParser = async () => {
		props.setParserIsRunning(true);
		await LinkedinParse(props, updateCurrentLeads);
		props.setParserIsRunning(false);
	};

	const updateCurrentLeads = (item) => {
		if(item.length === 0){ return; }
		props.setParsedItems(item);
		if(!item[0].id){ return; }

		const original = props.current.leads ? [...props.current.leads] : [];
		let newLeads;
		if(props.parser.type === "LEADS"){
			newLeads = [item[0], ...original];
		} else {
			newLeads = original.map((e, i) => {
				return e.documentId === item[0].documentId ? item[0] : e;
			});
		}

		props.setCurrentLeads(newLeads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[props.current.account.documentId] = newLeads;
		props.setLeads(leadCollection);
	};

	const outputParseOptions = () => {
		if (
			props.parser.type === "LEADS" ||
			props.parser.type === "CONNECTION"
		) {
			return outputParseButton();
		} else {
			return outputParseSelect();
		}
	};

	const outputParseSelect = () => {
		return (
			<div
				className={`run__parse__container mode__${
					!props.parser.post ? "post__select" : "start__parse"
				} ${props.parser.type === null ? "" : "type__selected"}`}>
				<label htmlFor="post-select"></label>
				<select
					id="post-select"
					value={props.parser.post === null ? "" : props.parser.post}
					onChange={(e) => props.setActivePost(e.target.value)}>
					<option key={-1} value="">
						Selecteer een post...
					</option>
					{props.current.posts &&
						props.current.posts.map((item, index) => {
							return (
								<option key={index} value={item.documentId}>
									{item.name}
								</option>
							);
						})}
				</select>
				{outputParseButton()}
			</div>
		);
	};

	const outputParseButton = () => {
		let disableButton = false;
		if (
			props.parser.type === null ||
			(props.parser.type !== "LEADS" &&
				props.parser.type !== "CONNECTION" &&
				!props.parser.post)
		) {
			disableButton = true;
		}

		return (
			<button
				disabled={disableButton}
				className={`parser_button run__parser${
					disableButton ? " inactive" : ""
				}`}
				onClick={runParser}>
				{props.parser.type ? props.parser.type.toLowerCase() : ""}
			</button>
		);
	};

	return (
		<div className="parser__options__container">
			<div className="parser__options container">
				<button
					onClick={() => props.setParserType(PARSER_TYPE.LEADS)}
					className={`parser_button i__lead${
						props.parser.type === PARSER_TYPE.LEADS ? " active" : ""
					}`}>
					Leads
				</button>
				<button
					onClick={() => props.setParserType(PARSER_TYPE.LIKES)}
					className={`parser_button i__like${
						props.parser.type === PARSER_TYPE.LIKES ? " active" : ""
					}`}>
					Likes
				</button>
				<button
					onClick={() => props.setParserType(PARSER_TYPE.COMMENTS)}
					className={`parser_button i__comment${
						props.parser.type === PARSER_TYPE.COMMENTS
							? " active"
							: ""
					}`}>
					Comments
				</button>
				<button
					onClick={() => props.setParserType(PARSER_TYPE.CONNECTION)}
					className={`parser_button i__connection${
						props.parser.type === PARSER_TYPE.CONNECTION
							? " active"
							: ""
					}`}>
					Connection
				</button>
				{props.parser.type !== null && outputParseOptions()}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			posts: state.accountDetailReducer.posts,
		},
		parser: {
			items: state.parserReducer.items,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
			user: state.parserReducer.user,
			post: state.parserReducer.post,
		},
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setParserType: (type) => {
			dispatch(setParserType(type));
		},
		setParserFocus: (focussed) => {
			dispatch(setParserFocus(focussed));
		},
		setParserIsRunning: (running) => {
			dispatch(setParserIsRunning(running));
		},
		setParsedItems: (items) => {
			dispatch(setParsedItems(items));
		},
		setActivePost: (post) => {
			dispatch(setActivePost(post));
		},
		setCurrentLeads: (leads) => {
			dispatch(setCurrentLeads(leads));
		},
		setLeads: (leads) => {
			dispatch(setLeads(leads));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ParseControls);
