import { STATE_FORM } from "../../state";
import { CHANGE_STATE, SET_PAGE } from "./appTypes";

const initialState = {
	appState: STATE_FORM,
	appPage: 0,
	screenData: null,
};

const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_STATE:
			return {
				...state,
				appState: action.payload.appState,
				appPage: action.payload.appPage,
				screenData: action.payload.screenData,
			};
		case SET_PAGE: {
			return { ...state, appPage: action.payload };
		}
		default:
			return state;
	}
};

export default appReducer;
