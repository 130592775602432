import { useRef, useEffect, useCallback } from "react";

function SocialTextarea(props) {
    const textarea = useRef();

    const updateInput = (e) => {
        props.update(props.id, e.target.value)
    }

    const resizeTextArea = () => {
        textarea.current.style.height = "auto";
        textarea.current.style.height = props.text.length == 0 ? "2rem" : textarea.current.scrollHeight + "px";
    };

    useEffect(() => {
        setTimeout(() => {
            updateInput({"target":{"value":props.text}});
        }, 100);
    }, []);
    
    useEffect(resizeTextArea, [props.text]);    
    const height = (textarea.current && textarea.current.scrollHeight) ? textarea.current.scrollHeight : 0;
	return ([
        <textarea key={0} value={props.text} ref={textarea} placeholder={props.placeholder ? props.placeholder : "Voer een omschrijving in.."} onInput={updateInput} className={`zd__edit__description${props.text.length != 0 && height > 86 ? " limit":""}${props.text.length == 0 ? " empty":""}`}></textarea>,
	    (height > 86 ? <span  key={1} className="show__more">...lees meer</span> : '')
    ])
}

export default SocialTextarea;