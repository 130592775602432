import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { dateRange, getWeekNumber, months } from "../../helpers/functions";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../selectors";


function Calendar(props) {
    const [ currentDate, setCurrentDate ] = useState(props.start);
    const [ selected, setSelected ] = useState();
    const [isMouseDown, setMouseDown] = useState(false);
    const [mousePosition, setMousePosition] = useState({
        left: 0,
        top: 0
    });
    const [previousPosition, setPreviousPositions] = useState({
        left: 0,
        top: 0
    });
    
    useEffect(() => {
        props.onMonthChange(currentDate);
    }, [currentDate]);

    const selectDay = (item) => {
        setSelected(item);
        props.onSelect(item);
    };

    const isSameDate = (a, b) => {
        if(!a || !b){ return false; }
        return a.getFullYear()+"-"+a.getMonth()+"-"+a.getDate() == b.getFullYear()+"-"+b.getMonth()+"-"+b.getDate();
    }

    const outputMonth = (date) => {
        if(!date || !(date.getMonth() >= 0)){
            return null;
        }
        return months[date.getMonth()] + " " + date.getFullYear();
    }

    const outputDays = (date) => {
        if(!date || !(date.getMonth() >= 0)){
            return null;
        }
        const start = !date ? null : new Date(date.getFullYear(), date.getMonth(), 1);
        const end = !date ? null : new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const range = dateRange(start, end);
        return range.map((item, index) => {
            const key = item.getFullYear()+""+item.getMonth()+""+item.getDate();

            let done = true;
            if(typeof props.items[key] != 'undefined' && props.items[key].posts.length > 0){
                props.items[key].posts.map((item)=>{
                    if(item.status != "Content ingepland"){
                        done = false;
                    }
                });
            } else {
                done = false;
            }

            return ([
                item.getDay() == 1 ? (
                <span key={`w${index}`} className="week__number">{getWeekNumber(item)}</span>) : null,
                <div onClick={() => selectDay(item)} key={index} className={`calendar__item${done ? " done" : ""}${isSameDate(item, selected) ? ' active' : ''}${typeof props.items[key] != 'undefined' && props.items[key].isEmpty != true && props.items[key].posts.length > 0 ? " has__item" : ""}${typeof props.items[key] != 'undefined' && props.items[key].prePlanned ? " pre__plannend" : ""}`}>
                    <div key={0} className="calendar__date">
                        <span key={0}>{item.getDate()}</span>
                    </div>
                </div>
            ]);
        });
    }

    const prependEmpty = (date) => {
        const start = !date ? null : new Date(date.getFullYear(), date.getMonth(), 1);
        let count = start.getDay() - 1;

        if(start.getDay() == 0) { count = 6; }
        
        const empty = [...Array(count).keys()];
        return empty.map((item, index) => {
            return <div key={`pe${index}`} className="calendar__date empty"></div>;
        });
    }

    const appendEmpty = (date) => {
        const end = !date ? null : new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const empty = [...Array(7 - end.getDay()).keys()];
        return empty.map((item, index) => {
            return <div key={`ae${index}`} className="calendar__date empty"></div>;
        });
    }

    const isMinimumDate = (date) => {
        return (props.start.getFullYear()+'-'+props.start.getMonth() == date.getFullYear()+'-'+date.getMonth());
    }

    const isMaximumDate = (date) => {
        return (props.end.getFullYear()+'-'+props.end.getMonth() == date.getFullYear()+'-'+date.getMonth());
    }

    const previousMonth = (date) => {
        let newDate;
        if(date.getMonth() == 0){
            newDate = new Date(date.getFullYear() - 1, 11, 1);
        } else {    
            newDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        }
        if(newDate < props.start && !isMinimumDate(newDate)){ return; }
        setCurrentDate(newDate);
    }

    const nextMonth = (date) => {
        let newDate;
        if(date.getMonth() == 11){
            newDate = new Date(date.getFullYear() + 1, 0, 1);    
        } else {
            newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        }
        if(newDate > props.end){ return; }
        setCurrentDate(newDate);
    }

    const detectMouseUp = () => {
        if(mousePosition.left > 0){
            previousMonth(currentDate);
        } else if(mousePosition.left < 0){
            nextMonth(currentDate);
        }

        if(isMouseDown){
            setPreviousPositions({left: 0, top: 0});
        }
        setMousePosition({left: 0, top: 0});
        setMouseDown(false);
    }

    const detectMouseDown = (e) => {
        if(!isMouseDown){
            setPreviousPositions({left: e.pageX, top: e.pageY});
        }
        setMouseDown(true);
    }

    const maybeMoveList = (e) => {
        if(!isMouseDown){ return; }
        if(previousPosition.left == 0 && previousPosition.top == 0){ return; }
        
        if(Math.abs(e.pageX - previousPosition.left) < 30){ return; }
        setMousePosition({left: (e.pageX - previousPosition.left), top: 0});
    }

    const outputCalendar = () => {
        let run = true;
        let i = 0;
        let date = new Date(props.displayAll ? props.start : currentDate);
        const list = [];
        let transform;

        let minDate = new Date(currentDate);
        minDate.setMonth(minDate.getMonth() - 1);

        let maxDate = new Date(currentDate);
        maxDate.setMonth(maxDate.getMonth() + 1);

        while(run){
            const isActiveMonth = currentDate.getFullYear()+'-'+currentDate.getMonth() == date.getFullYear()+'-'+date.getMonth();
           
            if(!isActiveMonth && date < currentDate && date >= minDate){
                transform = { position:'absolute', left: 0, marginLeft: '5%', transform:'translate(-100%)' };
            } else if(!isActiveMonth && date > currentDate && date <= maxDate){
                transform = { position:'absolute', left: 0, marginLeft: '15%' }
            } else {
                transform = isActiveMonth ? { position:'absolute', marginLeft: '10%', left: '200%', transform:'translate(0%)' } : false;
            }
            
            if(transform){
                list.push(
                    <div style={transform} key={i} className={`calendar__view${isActiveMonth ? " active" : ""}`}>
                        <div key={0} className="calendar__head">
                            {(props.displayAll !== true && <button key={0} onClick={() => previousMonth(date)} disabled={(isMinimumDate(date) ? "disabled" : "")} className="calendar__previous"></button>)}
                            <h2 key={1} className="calendar__month">{outputMonth(date)}</h2>
                            {(props.displayAll !== true && <button key={2} onClick={() => nextMonth(date)} disabled={(isMaximumDate(date) ? "disabled" : "")} className="calendar__next"></button>)}
                        </div>
                        <div key={1} className="calendar__days">
                            <div key={0} className="calendar__empty__week"><span>Week</span></div>
                            <div key={1} className="calendar__day"><span>ma</span></div>
                            <div key={2} className="calendar__day"><span>di</span></div>
                            <div key={3} className="calendar__day"><span>wo</span></div>
                            <div key={4} className="calendar__day"><span>do</span></div>
                            <div key={5} className="calendar__day"><span>vr</span></div>
                            <div key={6} className="calendar__day"><span>za</span></div>
                            <div key={7} className="calendar__day"><span>zo</span></div>
                        </div>
                        <div key={2} className="calendar__body">
                            {date && date.getDay() != 1 ? (<span className="week__number">{getWeekNumber(date)}</span>) : null}
                            {prependEmpty(date)}
                            {outputDays(date)}
                            {appendEmpty(date)}
                        </div>
                    </div>
                );
            }

            if(!isMaximumDate(date) && props.displayAll === true){
                if(date.getMonth() == 11){
                    date = new Date(date.getFullYear() + 1, 0, 1);    
                } else {
                    date = new Date(date.getFullYear(), date.getMonth() + 1, 1);
                }
            } else {
                run = false;
            }
            i++
        }
        return list;
    }
    
	return <div onMouseDown={detectMouseDown} onMouseUp={detectMouseUp} onBlur={detectMouseUp} onMouseMove={maybeMoveList} className={`calendar__container${props.displayAll === true ? " drag__scroll" : ""}`}>
        {(props.displayAll != true) ? null : (
            <div className="calendar__month__navigation">
                {(<button onClick={() => previousMonth(currentDate)} disabled={(isMinimumDate(currentDate) ? "disabled" : "")} className="calendar__previous"></button>)}
                {(<button onClick={() => nextMonth(currentDate)} disabled={(isMaximumDate(currentDate) ? "disabled" : "")} className="calendar__next"></button>)}
            </div>
        )}
        {outputCalendar()}
        </div>;
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
