import firebase from "./firebase";
import { getServerTimeStamp } from "./firebase";
import {
	collection,
	doc,
	getDoc,
	addDoc,
	updateDoc,
	getDocs,
	query,
	where,
	orderBy,
	limit,
	deleteDoc,
} from "firebase/firestore";

import { shallowEqual } from "../../helpers/functions";

export const leadRef = collection(firebase, "Leads");

// const { currentUser } = useAuth();

const buildRef = (documentId) => {
	return collection(firebase, "Leads", documentId, "List");
};

const leadMaybeInsert = async (item) => {
	const documentId = sessionStorage.getItem("zd__lln__customer");
	if (!documentId) {
		return false; //display error that account is not selected
	}

	const ref = buildRef(documentId);
	let q = query(ref, where("id", "==", item.id));

	// let q = query(leadRef, where("id", "==", item.id));
	// q = query(q, where("owner", "==", "/Accounts/" + documentId));
	const data = [];
	const snapshot = await getDocs(q);
	snapshot.forEach((element) => {
		data.push(element.id);
	});

	if (data.length > 0) {
		return data;
	} else {
		return true;
	}
};
export const leadInsert = async (item) => {
	const owner = sessionStorage.getItem("zd__lln__customer");
	if (typeof owner == "undefined" || owner == null) {
		return false;
	}

	const shouldInsert = await leadMaybeInsert(item);
	if (shouldInsert != true) {
		var documentRef = doc(
			firebase,
			"Leads",
			owner,
			"List",
			shouldInsert[0]
		);
		const result = await updateDoc(documentRef, item);
		return {
			data: () => { return {
				...item,
				documentId: shouldInsert[0],
			}},
		};
		// return false;
	}


	const ref = buildRef(owner);
	//leadRef
	const docRef = await addDoc(ref, {
		id: item.id,
		name: item.name,
		company: item.company ? item.company : null,
		occupation: item.occupation ? item.occupation : null,
		location: item.location ? item.location : null,
		created: getServerTimeStamp(),
		lastModified: getServerTimeStamp(),
		owner: "/Accounts/" + owner,
		points: [],
		rejected: false,
		shouldTrack: false,
		status: "open",
	});
	return await getDoc(docRef);
};
export const leadFetchAll = async (documentId) => {
	const ref = buildRef(documentId);
	//leadRef
	let q = query(ref, orderBy("name"));
	// q = query(q, where("owner", "==", "/Accounts/" + documentId));
	// q = query(q, where("shouldTrack", "==", true));
	// q = query(q, where("status", "!=", 'connected'));
	try {
		const snapshot = await getDocs(q);
		const data = [];
		snapshot.forEach((element) => {
			data.push({
				...element.data(),
				documentId: element.id,
			});
		});
		if (data.length > 0) {
			return data;
		}
	} catch (error) {
		console.log(error);
	}
	return false;
};

export const leadSocialSelling = async (documentId, ssLimit, ssDate) => {
	const ref = buildRef(documentId);
	let q = query(
		ref,
		orderBy("rejected", "desc"),
		orderBy("name"),
		limit(ssLimit)
	);
	q = query(q, where("status", "==", "open"));
	q = query(q, where("rejected", "!=", true));

	try {
		const snapshot = await getDocs(q);
		const data = [];
		snapshot.forEach((element) => {
			data.push({
				...element.data(),
				documentId: element.id,
			});
		});
		if (data.length > 0) {
			return data;
		}
	} catch (error) {
		console.log(error);
	}
	return false;
};

export const leadUpdate = async (documentId, data, user) => {
	var documentRef = doc(
		firebase,
		"Leads",
		user.documentId,
		"List",
		documentId
	);

	if (data.points && user.pointThreshold >= 0) {
		data.thresholdHit =
			leadPointsTotal(data.points) >= user.pointThreshold ? true : false;
		data.thresholdHitSince = getServerTimeStamp();
		if (data.thresholdHit === true) {
			data.shouldTrack = false;
		}
	}
	data.lastModified = getServerTimeStamp();
	const result = await updateDoc(documentRef, data);
	return {
		data: {
			...data,
			documentId: documentId,
		},
	};
};

export const leadPointsTotal = (points) => {
	let total = 0;
	for (let i = 0; i < points.length; i++) {
		if (points[i].type === "LIKES") {
			total += 1;
		} else if (points[i].type === "COMMENTS") {
			total += 2;
		} else if (points[i].type === "CUSTOM_SUBTRACT") {
			total -= 1;
		} else if (points[i].type === "CUSTOM_ADD") {
			total += 1;
		}
	}
	return total;
};

export const leadExists = async (query) => {
	const snapshot = await getDocs(query);
	const data = [];
	snapshot.forEach((element) => {
		data.push({
			...element.data(),
			documentId: element.id,
		});
	});

	if (data.length > 0) {
		return data;
	}
	return false;
};

export async function updatePointCount(item, type, user) {
	const post = sessionStorage.getItem("zd__lln__post");
	if (typeof user.documentId == "undefined" || user.documentId == null) {
		return false;
	}
	if (typeof post == "undefined" || post == null) {
		return false;
	}

	var documentRef = collection(firebase, "Leads", user.documentId, "List");
	let base = query(documentRef);
	let baseProps = {
		post: "/Posts/" + post,
		type: type,
		// created: new Date(),
	};
	//base
	let q = query(documentRef, where("id", "==", item.id));

	const temp = await leadExists(q);

	if (!temp) {
		return false;
	}
	if (temp[0].shouldTrack == false) {
		return false;
	}

	if (typeof temp[0].points == "undefined") {
		temp[0].points = [];
	}

	const props = {
		...baseProps,
	};

	if (temp[0].points.some((item) => shallowEqual(item, props))) {
		console.log('test');
		return false;
	}

	const data = {
		points: [...temp[0].points, props],
	};

	const result = await leadUpdate(temp[0].documentId, data, user);
	return {
		data: () => {
			return {
				...temp[0],
				...item,
				...data,
			};
		},
		id: temp[0].documentId,
	};
}

export const leadDelete = async (documentId, userId) => {
	const documentRef = doc(firebase, "Leads", userId, "List", documentId);
	// const documentRef = doc(firebase, "Leads", documentId);
	const response = await deleteDoc(documentRef);
};

export const accountById = async (id) => {
	if (!id) {
		return false;
	}
	const owner = sessionStorage.getItem("zd__lln__customer");
	if (typeof owner == "undefined" || owner == null) {
		return false;
	}

	const documentRef = collection(firebase, "Leads", owner, "List");
	let q = query(documentRef, where("id", "==", id));
	const snapshot = await getDocs(q);
	//leadRef
	// let q = query(documentRef, where("owner", "==", "/Accounts/" + owner));

	const data = [];
	snapshot.forEach((element) => {
		data.push({
			...element.data(),
			documentId: element.id,
		});
	});

	if (data.length > 0) {
		return data[0];
	}
	return false;
};

export const accountByName = async (name) => {
	if (!name) {
		return false;
	}
	const owner = sessionStorage.getItem("zd__lln__customer");
	if (typeof owner == "undefined" || owner == null) {
		return false;
	}

	const documentRef = collection(firebase, "Leads", owner, "List");
	let q = query(documentRef, where("name", "==", name));
	const snapshot = await getDocs(q);
	//leadRef
	// let q = query(documentRef, where("owner", "==", "/Accounts/" + owner));

	const data = [];
	snapshot.forEach((element) => {
		data.push({
			...element.data(),
			documentId: element.id,
		});
	});
	if (data.length > 0) {
		return data[0];
	}
	return false;
};

export const leadFetch = async (documentId, userId) => {
	if (!documentId) {
		return false;
	}

	const documentRef = doc(firebase, "Leads", userId, "List", documentId);
	// const accountRef = doc(firebase, "Leads", documentId);
	const snapshot = await getDoc(documentRef);

	if (!snapshot.exists()) {
		return false;
	}

	return {
		...snapshot.data(),
		documentId: snapshot.id,
	};
};
