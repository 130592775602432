// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import "firebase/compat/auth";
// import { getFirestore } from "firebase/firestore";

import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import {
	getAuth,
	updateEmail,
	updatePassword,
	verifyBeforeUpdateEmail,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	setPersistence,
	browserLocalPersistence,
	signOut,
	updateCurrentUser,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
// 	apiKey: "AIzaSyDHZJnxENlBpdMd5wC-EwgUUV3FY74cIHk",
// 	authDomain: "zodoende-eb146.firebaseapp.com",
// 	projectId: "zodoende-eb146",
// 	storageBucket: "zodoende-eb146.appspot.com",
// 	messagingSenderId: "8resetPasswordUser86955574699",
// 	appId: "1:886955574699:web:81305accd65b1a5d6e7eee",
// };

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTDOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGIN_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export const auth = getAuth();
export const createUser = async (credentials) => {
	return await createUserWithEmailAndPassword(
		auth,
		credentials.email,
		credentials.password
	);
};
export const loginUser = async (credentials) => {
	await setPersistence(auth, browserLocalPersistence);
	return await signInWithEmailAndPassword(
		auth,
		credentials.email,
		credentials.password
	);
};
export const signOutUser = async () => {
	return await signOut(auth);
};
export const resetPasswordUser = async (credentials) => {
	return await sendPasswordResetEmail(auth, credentials.email);
};
export const updateEmailUser = async (credentials) => {
	return await updateEmail(credentials.user, credentials.email);
};
export const updatePasswordUser = async (credentials) => {
	return await updatePassword(credentials.user, credentials.password);
};



export const getServerTimeStamp = () => {
	return serverTimestamp();
	return new Date().getTime(); //
};

export const updateLoggedInUser = async (user) => {
	return await updateCurrentUser(auth, user);
};

export const assignAdminRole = async (email) => {
	const functions = getFunctions(app, "europe-west1");
	const addAdmin = httpsCallable(functions, "addAdmin");

	return await addAdmin({ email: email })
		.then((result) => {
			return {
				success: result.data.result ? true : false,
			};
		})
		.catch((error) => {
			const code = error.code;
			const message = error.message;
			const details = error.details;
			return {
				code,
				message,
				details,
				success: false,
			};
		});
};

export const revokeAdminRole = async (email) => {
	const functions = getFunctions(app, "europe-west1");
	const removeAdmin = httpsCallable(functions, "removeAdmin");

	return await removeAdmin({ email: email })
		.then((result) => {
			return {
				success: result.data.result ? true : false,
			};
		})
		.catch((error) => {
			const code = error.code;
			const message = error.message;
			const details = error.details;
			return {
				code,
				message,
				details,
				success: false,
			};
		});
};

export const deleteAccount = async (documentId) => {
	const functions = getFunctions(app, "europe-west1");
	const deleteUser = httpsCallable(functions, "deleteUser");

	return await deleteUser({ uid: documentId })
		.then((result) => {
			return {
				success: result.data.result ? true : false,
			};
		})
		.catch((error) => {
			const code = error.code;
			const message = error.message;
			const details = error.details;
			return {
				code,
				message,
				details,
				success: false,
			};
		});
};

export default firestore;
