import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
	getMessages,
	getAccountMessages,
} from "../selectors";
import {
	filterObject,
	mapObject,
	mapObjectToArray,
} from "../helpers/functions";
import { accountUpdate, accountFetch } from "../classes/database/Accounts";

function BooleanSearch(props) {
	const accounts = props.collections.accounts
		? props.collections.accounts
		: {};

	const { id } = useParams();
	const companyListRef = useRef();
	const functionListRef = useRef();
	const titleRef = useRef();

	const [companyList, setCompanyList] = useState("");
	const [functionList, setfunctionList] = useState("");
	const [title, setTitle] = useState("");

	const [coppied, setCoppied] = useState(false);

	const [createBooleanSearch, setCreateBooleanSearch] = useState(false);
	const [activeBooleanSearch, setActiveBooleanSearch] = useState();
	const [editBooleanSearch, setEditBooleanSearch] = useState();
	const [booleanSearchChanged, setBooleanSearchChanged] = useState(false);

	useEffect(async () => {
		const user = accounts[id] ? accounts[id] : await accountFetch(id);
		props.setCurrentAccount(user);
		if (!accounts[id]) {
			const _accounts = { ...accounts };
			_accounts[id] = user;
			props.setAccounts(_accounts);
		}
		return () => {
			props.setCurrentAccount(null);
			props.setCurrentLeads([]);
			props.setCurrentPosts([]);
		};
	}, []);

	const toggleActive = (index) => {
		if (activeBooleanSearch === index) {
			setActiveBooleanSearch(null);
		} else {
			setActiveBooleanSearch(index);
		}
	};

	const outputBooleanSearchItem = (item, index) => {
		return (
			<div
				className={`boolean__search__item${
					activeBooleanSearch === index ? " active" : ""
				}`}
				key={index}>
				<h2
					onClick={() => {
						toggleActive(index);
					}}>
					{item.title}
				</h2>
				<div className="boolean__search__preview">
					{outputfunctionList(item, index)}
					{outputControlButton(index)}
				</div>
			</div>
		);
	};

	const saveCurrentBooleanSearch = async () => {
		if (!booleanSearchChanged) {
			setEditBooleanSearch(null);
			return;
		}
		const searches = [...props.current.account.booleanSearches];
		const response = await accountUpdate(props.current.account.documentId, {
			booleanSearches: searches,
		});
		setEditBooleanSearch(null);
	};

	const outputControlButton = (index) => {
		return index === editBooleanSearch ? (
			<button
				onClick={() => saveCurrentBooleanSearch()}
				className="i__save"></button>
		) : (
			<button
				onClick={() => {
					setBooleanSearchChanged(false);
					setEditBooleanSearch(index);
				}}
				className="i__edit"></button>
		);
	};

	const copySearchStringFromSearch = (search) => {
		const companies = search.company;
		const functions = search.function;

		if (companies.length === 0 || functions.length === 0) {
			return <p key={0}></p>;
		}

		const combinations = companies.flatMap((bedrijf) =>
			functions.map((functie) => `("${functie} by ${bedrijf}") OR `)
		);

		const combinationString = combinations.join("");
		copySearchString(combinationString.substr(0, combinationString.length - 3));
	}

	const outputfunctionList = (search, index) => {
		return index !== editBooleanSearch
			? <div 
			 className={`${coppied === true ? "coppied" : ""} ${coppied == 'error' ? 'error' : ''}`}
			onClick={()=>{copySearchStringFromSearch(search); }}>
				{generate__boolean__search(false, search.company, search.function)}
				</div>
			: [
					<input
						key={0}
						type="text"
						className="input__field"
						placeholder="Voer titel in.."
						onChange={(e) => {
							updateTitleLocally("title", e, index);
						}}
						value={search.title}
					/>,
					<div className="grid two" key={1}>
						<div key={0} className="column">
							<h3 key={0}>Bedrijven</h3>
							<textarea
								key={1}
								className="input__field"
								value={search.company.join("\n")}
								onChange={(e) => updateTextareaLocally("company", e, index)}
								placeholder="Vul bedrijven in.."
								type="text"
								ref={companyListRef}></textarea>
						</div>
						<div key={1} className="column">
							<h3 key={0}>Functies</h3>
							<textarea
								key={1}
								className="input__field"
								onChange={(e) =>
									updateTextareaLocally("function", e, index)
								}
								value={search.function.join("\n")}
								placeholder="Vul functies in.."
								ref={functionListRef}></textarea>
						</div>
					</div>,
			  ];
	};

	const updateTitleLocally = (prop, e, index) => {
		const searches = [...props.current.account.booleanSearches];
		if (searches[index]) {
			setBooleanSearchChanged(true);
			searches[index][prop] = e.target.value;
		}
		const user = { ...props.current.account, searches: searches };
		props.setCurrentAccount(user);
	};

	const updateTextareaLocally = (prop, e, index) => {
		const searches = [...props.current.account.booleanSearches];
		if (searches[index]) {
			setBooleanSearchChanged(true);
			searches[index][prop] = textareaToArray(e.target.value);
		}
		const user = { ...props.current.account, searches: searches };
		props.setCurrentAccount(user);
	};

	const createPopUp = (elements) => {
		return (
			<div key={4} className="post__pop__up__container">
				<div className="pop__up__inner">{elements}</div>
			</div>
		);
	};

	const doCreateSearch = async () => {
		const search = {
			title: titleRef.current.value,
			company: getCompanyArray(),
			function: getFunctionArray(),
		};

		const searches = !props.current.account.booleanSearches
			? [search]
			: [...props.current.account.booleanSearches, search];

		const response = await accountUpdate(props.current.account.documentId, {
			booleanSearches: searches,
		});

		const _accounts = { ...accounts };
		_accounts[id] = {
			...props.current.account,
			booleanSearches: response.data.booleanSearches,
		};
		console.log(_accounts[id]);
		props.setAccounts(_accounts);
		props.setCurrentAccount(_accounts[id]);

		setCompanyList("");
		setfunctionList("");
		setCreateBooleanSearch(false);
	};

	const getFunctionArray = () => {
		if (!functionListRef.current) {
			return [];
		}
		return textareaToArray(functionListRef.current.value).filter(function (el) {
			return el;
		});
	};

	const getCompanyArray = () => {
		if (!companyListRef.current) {
			return [];
		}
		return textareaToArray(companyListRef.current.value).filter(function (el) {
			return el;
		});
	};

	const textareaToArray = (text) => {
		return text.split(/\r?\n/);
	}

	const generate__boolean__search = (
		doReturn = false,
		companies = null,
		functions = null
	) => {
		companies = companies ? companies : getCompanyArray();
		functions = functions ? functions : getFunctionArray();

		if (companies.length === 0 || functions.length === 0) {
			return <p key={0}></p>;
		}

		const combinations = companies.flatMap((bedrijf) =>
			functions.map((functie) => `("${functie} by ${bedrijf}") OR `)
		);

		const combinationString = combinations.join("");
		if (doReturn) {
			return combinationString.substr(0, combinationString.length - 3);
		}
		return (
			<p key={0}>
				{combinationString.substr(0, combinationString.length - 3)}
			</p>
		);
	};

	const copySearchString = (text = null) => {
		if(text == null){
			text = generate__boolean__search(true);
		}
		
		navigator.clipboard.writeText(text).then(
			function () {
				setCoppied(true);
			},
			function (err) {
				setCoppied("error");
			}
		);
		setTimeout(() => {
			setCoppied(false);
		}, 2000);
	};

	const outputCreatePopUp = (item, index) => {
		return createPopUp([
			<h2 key={0}>Boolean Search</h2>,
			<h3 key={1}>Titel</h3>,
			<input
				key={2}
				className="input__field"
				value={title}
				onChange={(e) => setTitle(e.target.value)}
				placeholder="Vul titel in.."
				type="text"
				ref={titleRef}
			/>,
			<div className="grid two" key={3}>
				<div key={0} className="column">
					<h3 key={0}>Bedrijven</h3>
					<textarea
						key={1}
						className="input__field"
						value={companyList}
						onChange={(e) => setCompanyList(e.target.value)}
						placeholder="Vul bedrijven in.."
						type="text"
						ref={companyListRef}></textarea>
				</div>
				<div key={1} className="column">
					<h3 key={0}>Functies</h3>
					<textarea
						key={1}
						className="input__field"
						onChange={(e) => setfunctionList(e.target.value)}
						value={functionList}
						placeholder="Vul functies in.."
						ref={functionListRef}></textarea>
				</div>
			</div>,
			<div
				key={4}
				className={`boolean__search__output grid two ${
					coppied ? "coppied" : ""
				} ${coppied == "error" ? "error" : ""}`}>
				{generate__boolean__search()}
				<button onClick={()=>{copySearchString()}} key={1}>
					Kopieer
				</button>
			</div>,
			<button key={5} className="add__confirm" onClick={doCreateSearch}>
				Opslaan
			</button>,
			<button
				key={6}
				className="cancel__confim"
				onClick={() => setCreateBooleanSearch(false)}>
				Annuleren
			</button>,
		]);
	};

	return [
		<div key={0} className={`boolean__search__container`}>
			<Link key={0} className="return__link" to={`/account/${id}/leads`}>
				Naar klant
			</Link>
			<h2 key={1}>Boolean Search</h2>
			<div key={2} className="boolean__search__inner">
				{props.current.account &&
					props.current.account.booleanSearches &&
					props.current.account.booleanSearches.map(
						outputBooleanSearchItem
					)}
			</div>
			<button
				key={3}
				onClick={() => setCreateBooleanSearch(true)}
				className="i__add"></button>
			{createBooleanSearch !== false && outputCreatePopUp()}
		</div>,
	];
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
			berichten: getMessages(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
			berichten: getAccountMessages(state),
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BooleanSearch);
