import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import {
	setFetchedLeads,
	setAccountDetailLeads,
	setActiveUser,
	setCurrentAccount,
	setLeads,
	setCurrentLeads,
	setAccounts,
} from "../../redux";

import { accountFetch } from "../../classes/database/Accounts";
import { htmlDecode } from "../../helpers/functions";

import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
	leadsAreFetched,
} from "../../selectors";

import { Link } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";

import { leadFetchAll, leadUpdate } from "../../classes/database/Leads";

function CustomerLeadView(props) {
	const accounts = props.collections.accounts
		? props.collections.accounts
		: {};
	const leads = props.collections.leads ? props.collections.leads : {};

	const { id, lead, created } = useParams();
	const { isAdmin } = useAuth();
	const [occupationFilter, setOccupationFilter] = useState("");
	const [companyFilter, setCompanyFilter] = useState("");
	const [locationFilter, setLocationFilter] = useState("");
	const [connectedSinceFilter, setConnectedSinceFilter] = useState("");

	const [connectedSinceMaxFilter, setConnectedSinceMaxFilter] = useState("");
	const [leadFromFilter, setLeadFromFilter] = useState(created ? created : "");

	useEffect(async () => {
		const user = accounts[id] ? accounts[id] : await accountFetch(id);
		props.setCurrentAccount(user);
		if (!accounts[id]) {
			const _accounts = { ...accounts };
			_accounts[id] = user;
			props.setAccounts(_accounts);
		}

		const leadList =
			leads[id] && props.collections.leadsAreFetched
				? leads[id]
				: await leadFetchAll(user.documentId);

		props.setCurrentLeads(leadList ? leadList : {});
		props.setFetchedLeads(id);

		if (leadList && (!leads[id] || !props.collections.leadsAreFetched)) {
			const _leads = { ...leads };
			_leads[id] = leadList;
			props.setLeads(_leads);
		}

		return () => {
			props.setLeads(null);
		};
	}, []);

	const rejectLead = async (index) => {
		const item = props.current.leads[index];
		const response = await leadUpdate(
			item.documentId,
			{
				rejected: item.rejected ? false : true,
			},
			props.current.account
		);
		const newLeads = props.current.leads.map((el, i) => {
			return el.documentId !== response.data.documentId
				? el
				: {
						...el,
						...response.data,
				  };
		});

		props.setCurrentLeads(newLeads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = newLeads;
		props.setLeads(leadCollection);
	};

	const decodeValueOrPlaceholder = (value) => {
		const decoded = htmlDecode(value);
		return decoded === "null" ? "-" : decoded;
	};

	const maybeFilterLeads = (leads) => {
		const hits = [];
		if (occupationFilter) {
			hits.push(true);
		}
		if (companyFilter) {
			hits.push(true);
		}
		if (locationFilter) {
			hits.push(true);
		}
		if (connectedSinceFilter) {
			hits.push(true);
		}
		if (connectedSinceMaxFilter) {
			hits.push(true);
		}
		if(leadFromFilter){
			hits.push(true);
		}
		if (hits.length === 0) {
			return leads;
		}

		const list = {};
		const connectedFromDate = new Date(connectedSinceFilter);
		connectedFromDate.setSeconds(0);
		connectedFromDate.setMinutes(0);
		connectedFromDate.setHours(0);
		const connectedFrom = connectedSinceFilter
			? Math.floor(connectedFromDate.getTime() / 1000)
			: "";

		const connectedUntilDate = new Date(connectedSinceMaxFilter);
		connectedUntilDate.setSeconds(59);
		connectedUntilDate.setMinutes(59);
		connectedUntilDate.setHours(23);
		const connectedUntil = connectedSinceMaxFilter
			? Math.floor(connectedUntilDate.getTime() / 1000)
			: "";

		
			const leadFromDate = new Date(leadFromFilter);
			leadFromDate.setSeconds(0);
			leadFromDate.setMinutes(0);
			leadFromDate.setHours(0);
			const leadFrom = leadFromFilter
				? Math.floor(leadFromDate.getTime() / 1000)
				: "";
			

		leads.map((e, i) => {
			let allowed = true;
			if (occupationFilter && e.occupation != occupationFilter) {
				allowed = false;
			}
			if (companyFilter && e.company != companyFilter) {
				allowed = false;
			}
			if (locationFilter && e.location != locationFilter) {
				allowed = false;
			}
			if (
				connectedFrom &&
				e.connectedFrom &&
				connectedFrom > e.connectedFrom.seconds
			) {
				allowed = false;
			} else if (connectedFrom && !e.connectedFrom) {
				allowed = false;
			}

			if (
				connectedUntil &&
				e.connectedFrom &&
				connectedUntil <= e.connectedFrom.seconds
			) {
				allowed = false;
			} else if (connectedUntil && !e.connectedFrom) {
				allowed = false;
			}

			if (
				leadFrom &&
				e.created &&
				((Number.isInteger(e.created) && leadFromDate > new Date(e.created)) || (leadFromDate > new Date(e.created.seconds * 1000)))
			) {
				allowed = false;
			} else if (leadFrom && !e.created) {
				allowed = false;
			}

			if (allowed) {
				list[i] = e;
			}
		});

		return list;
	};

	const buildRow = (item, i) => {
		const data = {
			name: decodeValueOrPlaceholder(item.name),
			occupation: decodeValueOrPlaceholder(item.occupation),
			company: decodeValueOrPlaceholder(item.company),
			location: decodeValueOrPlaceholder(item.location),
		};

		return (
			<li
				className="lead__overview__item"
				key={i}
				data-id={item.documentId}>
				<span title={data.occupation}>{data.occupation}</span>
				<span title={data.company}>{data.company}</span>
				<span title={data.location}>{data.location}</span>
				<button
					className={`rejection__button ${
						item.rejected === true ? "is__rejected" : ""
					}`}
					onClick={(e) => rejectLead(i)}>
					{item.rejected === true ? "Afwijzen" : "Goedkeuren"}
				</button>
			</li>
		);
	};

	const maybeOutputPreviewUrl = () => {
		if (!isAdmin()) {
			return;
		}
		if (!leadFromFilter) {
			return;
		}
		const location = window.location.href;
		const createdPosition = location.indexOf("/created");
		const base = location.substring(
			0,
			createdPosition <= 0 ? location.length : createdPosition
		);
		return <p>{`${base}/created/${leadFromFilter}`}</p>;
	};

	const outputViewModeScreen = () => {
		let data;
		const occupation = [
			...new Set(props.current.leads.map((item) => item.occupation)),
		];
		const company = [
			...new Set(props.current.leads.map((item) => item.company)),
		];
		const location = [
			...new Set(props.current.leads.map((item) => item.location)),
		];

		const _leads = maybeFilterLeads(props.current.leads);
		return (
			<div className="leads__display__container">
				{isAdmin() && (
					<Link
						className="return__link"
						to={`/account/${props.current.account.documentId}/leads`}>
						Naar klant
					</Link>
				)}
				<div className="lead__filters">
					<strong style={{marginBottom:'-0.5rem'}}>Functie</strong>
					<strong style={{marginBottom:'-0.5rem'}}>Bedrijf</strong>
					<strong style={{marginBottom:'-0.5rem'}}>Locatie</strong>
					<div className="select__container">
						<select
							onChange={(e) =>
								setOccupationFilter(e.target.value)
							}
							value={occupationFilter}>
							<option value="">Selecteer een functie</option>
							{occupation.map((item, i) => {
								return (
									<option key={i} value={item}>
										{decodeValueOrPlaceholder(item)}
									</option>
								);
							})}
						</select>
					</div>

					<div className="select__container">
						<select
							onChange={(e) => setCompanyFilter(e.target.value)}
							value={companyFilter}>
							<option value="">Selecteer een bedrijf</option>
							{company.map((item, i) => {
								return (
									<option key={i} value={item}>
										{decodeValueOrPlaceholder(item)}
									</option>
								);
							})}
						</select>
					</div>
					<div className="select__container">
						<select
							onChange={(e) => setLocationFilter(e.target.value)}
							value={locationFilter}>
							<option value="">Selecteer een locatie</option>
							{location.map((item, i) => {
								return (
									<option key={i} value={item}>
										{decodeValueOrPlaceholder(item)}
									</option>
								);
							})}
						</select>
					</div>
					<strong style={{marginBottom:'-0.5rem'}}>Verbonden vanaf</strong>
					<strong style={{marginBottom:'-0.5rem'}}>Verbonden voor</strong>
					<strong style={{marginBottom:'-0.5rem'}}>Lead vanaf</strong>
					<input
						onChange={(e) => {
							setConnectedSinceFilter(e.target.value);
						}}
						type="date"
						value={connectedSinceFilter}
					/>
					<input
						onChange={(e) => {
							setConnectedSinceMaxFilter(e.target.value);
						}}
						type="date"
						value={connectedSinceMaxFilter}
					/>
					<input
						onChange={(e) => {
							setLeadFromFilter(e.target.value);
						}}
						type="date"
						value={leadFromFilter}
					/>
				</div>
				<div className="preview__url">{maybeOutputPreviewUrl()}</div>
				<ul className="leads__overview__container">
					<li className="lead__overview__head" key={-1}>
						<span>Functie</span>
						<span>Bedrijf</span>
						<span>Locatie</span>
						<span style={{ textAlign: "right" }}>Uitsluiten</span>
					</li>
					{Object.keys(_leads).map((e, i) =>
						buildRow(props.current.leads[e], e)
					)}
				</ul>
			</div>
		);
	};

	return (
		<div className={`app__inner grid one lead__overview`}>
			{props.current.leads && outputViewModeScreen()}
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailLeads: (leads) => {
			dispatch(setAccountDetailLeads(leads));
		},

		setLeads: (leads) => {
			dispatch(setLeads(leads));
		},
		setCurrentLeads: (leads) => {
			dispatch(setCurrentLeads(leads));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setFetchedLeads: (leads) => {
			dispatch(setFetchedLeads(leads));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			leadsAreFetched: leadsAreFetched(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			leads: state.accountDetailReducer.leads,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLeadView);
