import { connect } from "react-redux";
import { setAccountDetailMode } from "../../../redux";
import { Link, useNavigate } from "react-router-dom";

import { getLeads, getPosts, getAccounts, getAccountLeads, getAccountPosts, getAccount } from "../../../selectors";

function ModeControls(props) {
	const navigate = useNavigate();
	return (
		<div className="mode__controler__container">
			<div className={`mode__controler container mode_${props.mode}`}>
				<button
					onClick={() => {
						props.setAccountDetailMode("parser");
						navigate(
							"/account/" +
								props.current.account.documentId +
								"/social-selling/parser"
						);
					}}
					className={`i__parse${
						props.mode === "parser" ? " active" : ""
					}`}>
					<span className="block__text">Parse</span>
				</button>
				<button
					onClick={() => {
						props.setAccountDetailMode("leads");
						navigate(
							"/account/" +
								props.current.account.documentId +
								"/social-selling/leads"
						);
					}}
					className={`i__lead${
						props.mode === "leads" ? " active" : ""
					}`}>
					<span className="block__text">Leads</span>
				</button>
				<button
					onClick={() => {
						props.setAccountDetailMode("posts");
						navigate(
							"/account/" +
								props.current.account.documentId +
								"/social-selling/posts"
						);
					}}
					className={`i__posts${
						props.mode === "posts" ? " active" : ""
					}`}>
					<span className="block__text">Posts</span>
				</button>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		collections : {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state)
		},
		parser: {
			user: state.parserReducer.user,
		},
		detail: {
			mode: state.accountDetailReducer.mode,
		},
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAccountDetailMode: (type) => {
			dispatch(setAccountDetailMode(type));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModeControls);
