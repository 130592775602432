import { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { accountInsert } from "../../../classes/database/Accounts";

function AccountCreate(props) {
	const leadIdRef = useRef();
	const leadNameRef = useRef();
	const leadComapnyRef = useRef();

	const [error, setError] = useState();
	const navigate = useNavigate();

	const addAccount = async () => {
		const response = await accountInsert({
			id: leadIdRef.current.value,
			name: leadNameRef.current.value,
		});

		if (!response) {
			setError(
				"Er is iets fout gegaan tijdens het aanmaken van de gebruiker"
			);
		} else {
			navigate("/accounts");
		}
	};

	return (
		<div className="container">
			<div className="zd__lln__create__user">
				{props.message && (
					<div className="zd__lln__description">{props.message}</div>
				)}
				<form className="account__create__form">
					<h2 key={0}>Klant aanmaken</h2>
					{error && <div className="message__error">{error}</div>}
					<input
						key={1}
						ref={leadNameRef}
						type="text"
						name="name"
						placeholder="Vul naam in..."
					/>
					<input
						key={1}
						ref={leadIdRef}
						type="text"
						name="name"
						placeholder="Vul LinkedIn user slug in..."
					/>
					<input
						key={1}
						ref={leadComapnyRef}
						type="text"
						name="company"
						placeholder="Vul bedrijfsnaam in..."
					/>
					<button
						type="button"
						onClick={addAccount}
						key={2}
						className="remove__confirm">
						Aanmaken
					</button>
				</form>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		parser: {
			user: state.parserReducer.user,
		},
	};
};

export default connect(mapStateToProps)(AccountCreate);
