export const filterObject = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter((entry)=>predicate(entry[1], entry[0])));
export const mapObject = (obj, predicate) => Object.fromEntries(Object.entries(obj).map((entry)=>predicate(entry[1], entry[0])));
export const mapObjectToArray = (obj, predicate) => Object.keys(obj).map((key, index) => predicate(obj[key], key));

export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function shallowEqual(object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}
	return true;
}

export function hashCode(str, seed = 0) {
	let h1 = 0xdeadbeef ^ seed,
		h2 = 0x41c6ce57 ^ seed;
	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i);
		h1 = Math.imul(h1 ^ ch, 2654435761);
		h2 = Math.imul(h2 ^ ch, 1597334677);
	}
	h1 =
		Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
		Math.imul(h2 ^ (h2 >>> 13), 3266489909);
	h2 =
		Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
		Math.imul(h1 ^ (h1 >>> 13), 3266489909);
	return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export function htmlDecode(input) {
	var doc = new DOMParser().parseFromString(input, "text/html");
	return doc.documentElement.textContent;
  }

export function makeRequest (method, url) {
	return new Promise(function (resolve, reject) {
	  var xhr = new XMLHttpRequest();
	  xhr.open(method, url);
	  xhr.onload = function () {
		if (xhr.status >= 200 && xhr.status < 300) {
		  resolve(xhr.response);
		} else {
		  reject({
			status: xhr.status,
			statusText: xhr.statusText
		  });
		}
	  };
	  xhr.onerror = function () {
		reject({
		  status: xhr.status,
		  statusText: xhr.statusText
		});
	  };
	  xhr.send();
	});
}

export const debounce = (fn, delay) => {
	let timerId;
	return (...args) => {
	  clearTimeout(timerId);
	  timerId = setTimeout(() => fn(...args), delay);
	}
  };

  export const dateRange = (startDate, endDate, excludeLastDate = false) => {
	const date = new Date(startDate.getTime());

	const dates = [];
  
	while (date <= endDate) {
		if(excludeLastDate === false || date != endDate){
			dates.push(new Date(date));
			date.setDate(date.getDate() + 1);
		}
	}
	return dates;
  }

export const displayDate = (date) => {
	const month = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1);
	const day = (date.getDate()) < 10 ? '0'+(date.getDate()) : (date.getDate());
	return date.getFullYear() +"-"+month+"-"+day;
} 


const startOfWeek = (d) => {
	d = new Date(d);
	var day = d.getDay(),
		diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
	return new Date(d.setDate(diff));
}

export const weeksBetween = (d1, d2) => {
	const week = 7 * 24 * 60 * 60 * 1000;
    return Math.round((startOfWeek(d2) - startOfWeek(d1)) / week);
}

export const getWeekNumber = (d) => {
	var d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
	var dayNum = d.getUTCDay() || 7;
	d.setUTCDate(d.getUTCDate() + 4 - dayNum);
	var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
	return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
}

export const days = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];
export const months = ["Januari","Februari","Maart","April","Mei","Juni","Juli","Augustus","September","Oktober","November","December"];


export const setCookie = (name, value, lifetime) => {
    var expires = "";
    if (lifetime) {
        var date = new Date();
        date.setTime(date.getTime() + lifetime);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/; secure; samesite=none;";
}
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
export const eraseCookie = (name) => {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}


export function FileListItems (files) {
    var b = new ClipboardEvent("").clipboardData || new DataTransfer()
    for (var i = 0, len = files.length; i<len; i++) b.items.add(files[i])
    return b.files
}