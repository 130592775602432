import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./css/reset.css";
import "./css/base.css";
import "./css/app.css";
import "./css/nav.css";
import "./css/leadDetail.css";
import "./css/card.css";
import "./css/parser.css";
import "./css/accountOverview.css";
import "./css/createAccount.css";
import "./css/leadOverview.css";
import "./css/login.css";
import "./css/message.css";
import "./css/booleanSearch.css";
import "./css/content.css";
import "./css/calendar.css";
import "./css/onboarding.css";
import "./css/responsive.css";

import { Provider } from "react-redux";
import store from "./redux/store";

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);
