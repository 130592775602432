export const SET_PARSER_TYPE = "SET_PARSER_TYPE";
export const SET_PARSER_FOCUS = "SET_PARSER_FOCUS";
export const SET_PARSER_IS_RUNNING = "SET_PARSER_IS_RUNNING";
export const SET_PARSED_ITEMS = "SET_PARSED_ITEMS";
export const SET_ACTIVE_USER = "SET_ACTIVE_USER";
export const SET_ACTIVE_POST = "SET_ACTIVE_POST";
export const PARSER_TYPE = {
	LEADS: "LEADS",
	LIKES: "LIKES",
	COMMENTS: "COMMENTS",
	CONNECTION: "CONNECTION",
};
