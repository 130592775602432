import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import { FileListItems, debounce } from "../../helpers/functions";
import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../selectors";

import { gapi } from 'gapi-script';

import PDFViewer from "../PDFViewer";


function GoogleDriveInput(props) {
    const input = useRef();
    const [files, setFiles] = useState();
    const [changeImage, setChangeImage] = useState();
    const [isLoading, setIsLoading] = useState();
    
    const outputFileItems = (key, index) => {
        const item = input.current.files[key];
        return (<h2 key={index}>
            <span>{item.name}</span>
            <button key={index} onClick={(e) => removeFileItem(e, key) } className="zd__cancel"></button>
        </h2>);
    } 

    const addNewFiles = () => {
        setFiles(input.current.files);
    }

    const removeFileItem = (e, index) => {
        const files = [];
        for(let i = 0; i < input.current.files.length; i++){
            if(index != i){ 
                files.push(input.current.files[i]);
            }
        }
        input.current.files = new FileListItems(files);        
        const event = new Event('input', { bubbles: true });
        input.current.dispatchEvent(event);
        setFiles(input.current.files);
    }

    const processUpload = async () => {
        for(var i = 0; i < input.current.files.length; i++){
            const fileObj = await getData(input.current.files[i]);
            props.process(fileObj.data, fileObj.filename, fileObj.mimeType, fileObj.fileSize);  
            removeFileItem(null, i);
        }
        setChangeImage(false);
    }

    const getData = (file) =>
        new Promise((resolve, reject) => {
            if (file) {
            const fr = new FileReader();
            fr.onload = f => resolve({filename: file.name, mimeType: file.type, fileSize: file.size, data: f.target.result});
            fr.onerror = err => reject(err);
            fr.readAsArrayBuffer(file);
            } else {
            resolve({});
        }
    });
    
    const pingFile = async ({ currentTarget }) => {
        if(!currentTarget){ return; }
        setIsLoading(true);
        currentTarget.classList.add('image__404');
        const response = await props.pingCallback();
        const text = await response.text();
        if(text.indexOf(props.file) > 0){        
            currentTarget.onerror = null;
            currentTarget.src = props.file;
            currentTarget.classList.remove('image__404');
            setIsLoading(false);
        } else {
            setTimeout(()=>{pingFile({'currentTarget': currentTarget})}, 2000);
        }

    }
    
    const pingPDF = async (file) => {
        if(!file){ return; }
        setIsLoading(true);
        const response = await props.pingCallback();
        const text = await response.text();

        if(text.indexOf(props.file) > 0){        
            setIsLoading(false);
        } else {
            setTimeout(()=>{pingPDF(file)}, 2000);
        }

    }

    const outputFiles = (file) => {
        const extension = file.split(/\./).pop();
        const imageValidExtensions = ["jpg","bmp","gif","png"];
        const videoValidExtensions = ['m4v', 'avi','mpg','mp4', 'webm', 'mov']; 

        if(file.indexOf('.pdf') == file.length - 4){
            return (<PDFViewer onError={pingPDF} file={file} />);
        } else if(imageValidExtensions.indexOf(extension) >= 0) {
            return <img onError={pingFile} src={file}/>;
        } else if(videoValidExtensions.indexOf(extension) >= 0){
            return (<video  src={file} controls onError={pingFile}></video>);
        } else {
            alert("Bestandsformaat wordt niet ondersteund (jpg, bmp, gif, png, m4v, avi,mpg, mp4, webm, mov).");
        }
    }

    return (
        <div className={`google__drive__upload__container${props.className ? ` ${props.className}` : ''}${!props.className && isLoading === true ? ' is__loading' : '' }`}>
            <div key={0} className={`zd__upload__form${props.file != null ? ' has__image' : " no__image"}${changeImage ? ' edit__image': ''}`}>
                {((props.file == null || changeImage) && <div className="zd__inner">
                    <h2 key={0}>Selecteer bestand(en)</h2>
                    <div key={1} className="zd__uploader">
                        <input onChange={addNewFiles} ref={input} type="file" name="google_file_upload" className="zd__upload"/>
                    </div>
                    {(input && input.current && input.current.files.length > 0 && <div key={2} className="zd__uploading__items">
                        {Object.keys(input.current.files).map(outputFileItems)}
                    </div>)}
                    <button key={3} onClick={processUpload} className="zd__submit">Uploaden</button>
                    {((props.cancelable || props.file != null) && <button key={4} className="zd__cancel" onClick={() => {
                        setChangeImage(false); 
                        if (typeof props.cancelable === 'function') {
                            props.cancelable();
                        }}}></button>)}
                </div>)}
                {(props.file != null && <div key={1} className="zd__image__overlay">
                    <button className="upload__image" onClick={() => setChangeImage(true)}></button>
                    {outputFiles(props.file)}
                </div>)}
            </div>
        </div>
      );
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleDriveInput);