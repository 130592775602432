import {
	SET_ACTIVE_USER,
	SET_ACTIVE_POST,
	SET_PARSER_TYPE,
	SET_PARSER_FOCUS,
	SET_PARSER_IS_RUNNING,
	SET_PARSED_ITEMS,
} from "./parserTypes";

const initialState = {
	type: null,
	isFocused: false,
	items: [],
	post: null,
	user: {
		id: null,
		name: "",
		documentId: null
	},
	isRunning: false,
};

const parserReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PARSER_TYPE:
			return {
				...state,
				type: action.payload.type,
			};
		case SET_PARSER_FOCUS:
			return {
				...state,
				isFocused: action.payload.isFocused,
			};
		case SET_PARSER_IS_RUNNING:
			return {
				...state,
				isRunning: action.payload.isRunning,
			};
		case SET_PARSED_ITEMS:
			const items = [...state.items, ...action.payload.items];
			return {
				...state,
				items: items,
			};
		case SET_ACTIVE_POST:
			return {
				...state,
				post: action.payload.post,
			};

		case SET_ACTIVE_USER:
			return {
				...state,
				user: action.payload.user,
			};
		default:
			return state;
	}
};

export default parserReducer;
