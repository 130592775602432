import { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import ModeControls from "./ModeControls";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../../selectors";

import {
	postFetchAll,
	postDelete,
	postInsert,
} from "../../../classes/database/Posts";
import { useAuth } from "../../../contexts/AuthContext";
import { useParams } from "react-router-dom";

function PostsView(props) {
	const { id } = useParams();
	const postNameRef = useRef();
	const [activeMenu, setActiveMenu] = useState();
	const [creatingPost, setCreatingPost] = useState(false);
	const [removePost, setRemovePost] = useState(false);
	const {isAdmin} = useAuth();

	useEffect(async () => {
		if (!props.current.account) {
			return;
		}
		const posts =
			props.collections.posts[id] &&
			props.collections.posts[id].length > 0
				? props.collections.posts[id]
				: await postFetchAll(id);
		const postCollections = props.collections.posts
			? { ...props.collections.posts }
			: {};
		postCollections[id] = posts ? posts : [];

		props.setCurrentPosts(postCollections[id]);
		props.setPosts(postCollections);
	}, [props.current.account]);

	const removePrompt = (index) => {
		setActiveMenu(null);
		setRemovePost(index);
	};

	const createPostPrompt = () => {
		setActiveMenu(null);
		setCreatingPost(true);
		props.callback(true);
	};

	const doRemovePost = () => {
		let item,
			index = removePost;
		if (props.current.posts[index]) {
			item = props.current.posts[index];
		} else {
			return;
		}

		postDelete(item.documentId, id);

		const newPosts = [];
		props.current.posts.map((item, i) => {
			if (i !== index) {
				newPosts.push(item);
			}
		});

		const postCollections = props.collections.posts
			? { ...props.collections.posts }
			: {};
		postCollections[id] = newPosts;
		props.setCurrentPosts(postCollections[id]);
		props.setPosts(postCollections);

		setActiveMenu(null);
		setRemovePost(false);
	};

	const outputViewRow = (item, index) => {
		return (
			<div
				key={index}
				data-id={item.documentId}
				className="post__row post__container">
				<span className="col name">{item.name}</span>
				<span
					className="col options"
					onClick={(e) => {
						e.stopPropagation();
						setActiveMenu(index);
					}}>
					<ul
						className={`pop__up__menu${
							activeMenu === index ? " active" : ""
						}`}>
						<li
							className="remove__post"
							onClick={() => removePrompt(index)}>
							Verwijderen
						</li>
						<li
							onClick={(e) => {
								e.stopPropagation();
								setActiveMenu(null);
							}}>
							Sluiten
						</li>
					</ul>
				</span>
			</div>
		);
	};

	const outputPosts = (item, index) => {
		return outputViewRow(item, index);
	};

	const outputCreatePopUp = () => {
		return createPopUp(
			<div className="post__create__form">
				<h2 key={0}>Post aanmaken</h2>
				<input
					key={1}
					ref={postNameRef}
					type="text"
					name="name"
					placeholder="Vul post naam in..."
				/>
				<button onClick={addPost} key={2} className="remove__confirm">
					Aanmaken
				</button>
				<button
					key={3}
					className="cancel__confim"
					onClick={cancelAddPost}>
					Annuleren
				</button>
			</div>
		);
	};

	const outputRemovePopUp = (item, index) => {
		return createPopUp([
			<h2 key={0}>Weet je zeker dat je deze post wilt verwijderen?</h2>,
			<button key={1} className="remove__confirm" onClick={doRemovePost}>
				Ja, verwijder de post
			</button>,
			<button
				key={2}
				className="cancel__confim"
				onClick={cancelRemovePost}>
				Annuleren
			</button>,
		]);
	};

	const cancelRemovePost = (e) => {
		setRemovePost(false);
	};

	const cancelAddPost = (e) => {
		setCreatingPost(false);
		props.callback(false);
	};

	const createPopUp = (elements) => {
		return (
			<div className="post__pop__up__container">
				<div className="pop__up__inner">{elements}</div>
			</div>
		);
	};

	const addPost = async () => {
		// 's werelds beste marketeer
		const response = await postInsert(
			{
				name: postNameRef.current.value,
			},
			props.current.account.documentId
		);
		if (!response) {
			return;
		}

		const newPosts = [
			{ ...response.data(), documentId: response.id },
			...props.current.posts,
		];
		const postCollections = props.collections.posts
			? { ...props.collections.posts }
			: {};
		postCollections[id] = newPosts;
		props.setCurrentPosts(postCollections[id]);
		props.setPosts(postCollections);

		setCreatingPost(false);
		props.callback(false);
	};

	if(!isAdmin()){
		return null;
	}
	return (
		<div className={`posts__container`}>
			<ModeControls mode={props.mode} />
			<h2>
				Post overzicht
				<button onClick={createPostPrompt} className="i__add"></button>
			</h2>
			<div className="posts__inner container">
				{props.current.posts && props.current.posts.map(outputPosts)}
			</div>
			{creatingPost && outputCreatePopUp()}
			{removePost !== false && outputRemovePopUp()}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		detail: {
			leads: state.accountDetailReducer.leads,
			posts: state.accountDetailReducer.posts,
		},
		parser: {
			user: state.parserReducer.user,
		},
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PostsView);
