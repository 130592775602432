import {
	SET_ACCOUNTS,
	SET_POSTS,
	SET_LEADS,
	SET_MESSAGES,
	SET_FETCHED_ACCOUNT,
	SET_FETCHED_POSTS,
	SET_FETCHED_LEADS,
	SET_FETCHED_MESSAGES,
} from "./collectionTypes";

const initialState = {
	accounts: {},
	fetchedAccounts: {},
	leads: {},
	fetchedLeads: {},
	posts: {},
	fetchedPosts: {},
	messages: {},
	fetchedMessages: {},
};

const collectionReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_FETCHED_ACCOUNT:
			const accounts = { ...state };
			accounts.fetchedAccounts[action.payload.id] = true;
			return accounts;
		case SET_FETCHED_POSTS:
			const posts = { ...state };
			posts.fetchedPosts[action.payload.id] = true;
			return posts;
		case SET_FETCHED_LEADS:
			const leads = { ...state };
			leads.fetchedLeads[action.payload.id] = true;
			return leads;
		case SET_FETCHED_MESSAGES:
			const messages = { ...state };
			leads.fetchedMessages[action.payload.id] = true;
			return messages;
		case SET_ACCOUNTS:
			return {
				...state,
				accounts: action.payload.items,
			};
		case SET_POSTS:
			return {
				...state,
				posts: action.payload.items,
			};
		case SET_LEADS:
			return {
				...state,
				leads: action.payload.items,
			};
		case SET_MESSAGES:
			return {
				...state,
				messages: action.payload.items,
			};
		default:
			return state;
	}
};

export default collectionReducer;
