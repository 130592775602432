import { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate  } from "react-router-dom";

function Login(props) {
	const emailRef = useRef();
	const passwordRef = useRef();
	const passwordConfirmRef = useRef();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const { login, currentUser , updateCurrentUser} = useAuth();

	const navigate = useNavigate();

	const handleEvent = async (e) => {
		e.preventDefault();

        if(!emailRef.current.value.length || !passwordRef.current.value.length){
            setError(
				"Vul gebruikersnaam of wachtwoord in"
			);
		    setLoading(false);
            return;
        }

		try {
			setError("");
			setLoading(true);
			const response = await login(emailRef.current.value, passwordRef.current.value);
			if(props.inline !== true){
				navigate('/accounts');
			} else {
				const success = updateCurrentUser(response.user);
				if(success && props.onSuccess){
					props.onSuccess();
				}
			}
        } catch (exception) {
			setError(
				"Er is iets fout gegaan tijdens het inloggen"
			);
		}
		setLoading(false);
	};

	return (
		<div className="container zd__lln__user__container">
			<div className="zd__lln__login__main">
				<h2>Inloggen</h2>
				{props.message && (
					<div className="zd__lln__description">
						{props.message}
					</div>
				)}
				{error && (
					<div className="zd__lln__notice zd__lln__error">
						{error}
					</div>
				)}
				<form onSubmit={handleEvent}>
					<div>
						<label>E-mail</label>
						<input ref={emailRef} type="email" />
					</div>
					<div>
						<label>Wachtwoord</label>
						<input ref={passwordRef} type="password" />
					</div>
					{props.inline !== true && (<div>
						<Link to="/wachtwoord-vergeten">Wachtwoord vergeten?</Link>
					</div>)}
					<input
						disabled={loading}
						type="submit"
						value="Inloggen"
					/>
				</form>
			</div>
			{/* {props.inline !== true && (<div className="zd__lln__login__secondary">
				Heb je nog geen account? <Link to="/aanmelden">Aanmelden</Link>
			</div>)} */}
		</div>
	);
}

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		setLoggedInAccount: (user) => {
// 			dispatch(setLoggedInAccount(user));
// 		},
// 	};
// };

// const mapStateToProps = (state) => {
// 	return {
// 		user: state.userReducer.user
// 	};
// };

export default Login; //connect(mapStateToProps, mapDispatchToProps)(Login);
