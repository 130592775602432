import {
	SET_ACCOUNT_DETAIL_MODE,
	SET_LEADS,
	SET_POSTS,
} from "./accountDetailTypes";

export const setAccountDetailMode = (state) => {
	return {
		type: SET_ACCOUNT_DETAIL_MODE,
		payload: {
			mode: state,
		},
	};
};

export const setAccountDetailLeads = (state) => {
	return {
		type: SET_LEADS,
		payload: {
			leads: state,
		},
	};
};

export const setAccountDetailPosts = (state) => {
	return {
		type: SET_POSTS,
		payload: {
			posts: state,
		},
	};
};
