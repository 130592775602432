import {
	SET_PARSER_TYPE,
	SET_PARSER_FOCUS,
	SET_PARSER_IS_RUNNING,
	SET_PARSED_ITEMS,
	SET_ACTIVE_USER,
	SET_ACTIVE_POST,
} from "./parserTypes";

export const setParserType = (state) => {
	return {
		type: SET_PARSER_TYPE,
		payload: {
			type: state,
		},
	};
};

export const setParserFocus = (state) => {
	return {
		type: SET_PARSER_FOCUS,
		payload: {
			isFocused: state,
		},
	};
};

export const setParserIsRunning = (state) => {
	return {
		type: SET_PARSER_IS_RUNNING,
		payload: {
			isRunning: state,
		},
	};
};

export const setParsedItems = (state) => {
	return {
		type: SET_PARSED_ITEMS,
		payload: {
			items: state,
		},
	};
};

export const setActivePost = (state) => {
	return {
		type: SET_ACTIVE_POST,
		payload: {
			post: state,
		},
	};
};

export const setActiveUser = (state) => {
	return {
		type: SET_ACTIVE_USER,
		payload: {
			user: state,
		},
	};
};
