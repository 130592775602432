import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import { setCookie, getCookie } from "../../helpers/functions";

import authenticator from "../../helpers/GoogleAuthentication";

import GoogleDriveInput from "./GoogleDriveInput";
import { loadGapiInsideDOM } from 'gapi-script';

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../selectors";


function GoogleDriveUploader(props) {
    // const [isBuilding, setIsBuilding] = useState(false);
    let isBuilding = false;
    const [isDriveLoading, setIsDriveLoading] = useState(null);

    const handleSignIn = (response) => {
        setCookie('zd_drive_access', response.access_token, response.expires_in * 1000);
        maybeStart();
    }

    const maybeRequestLogin = () => {
        const token = getCookie('zd_drive_access');
        if(!authenticator && !token){ return; }

        if(token){ 
            maybeStart();
        } else {
            authenticator.maybePromptAuthorization();
        }
    }

    useEffect(async () => {
        if(!authenticator){ return; }
        authenticator.authenticated = handleSignIn;
        authenticator.callback = maybeRequestLogin;
        authenticator.gapi = await loadGapiInsideDOM();
        authenticator.identityServiceLoaded();      
    }, [authenticator]);

    const maybeStart = () => {
        const token = getCookie('zd_drive_access');
        if(token){
            start();
            return;
        }
        setTimeout(maybeStart, 500);
    }

    const start = async () => {
        if(!props.current.account.company || !props.title){
            alert("De bedrijfsnaam of de content calender naam is onbekent.");
            return;
        }
        const key = !props.directory ? null : props.directory.join('-');
        const id = getDriveId(key);
        if(id == null) {
            const folderId = await buildFolderStructure(props.directory);
            props.storeDriveFolderId(folderId, key);
        }

    }

    const getDriveId = (key = null) => {
        if (typeof props.driveFolderId === 'string'){
            if(key != null){ return null; }
            return props.driveFolderId;
        } else {
            if(key == null){ return null; }
            if(!props.driveFolderId){ return null; }
            if(!props.driveFolderId[key]){ return null; }
            return props.driveFolderId[key];
        }
    }

    const buildFolderStructure = async (dir = null) => {        
        if(isBuilding){ return; }
        isBuilding = true;

        const temp = {};
        const basePath = ["Klanten", props.current.account.company, "Contentplanningen", props.title];
        const paths = (!dir) ? basePath : basePath.concat(dir);
        
        let folder = 'root';
        for(let index = 0; index < paths.length; index++){
            if(paths[index] === "base"){ continue; }
            
            const data = await list({
                q: `"${folder}" in parents and trashed=false`,
                trashed: false,
                orderBy:'folder' ,
                fields: 'files(id,name,createdTime,modifiedTime,size,mimeType,parents)'
            });
            temp[folder] = data;
            temp[folder].files.sort(function( a, b ) {
                return  ( a.name.toUpperCase() > b.name.toUpperCase() ) ? 1 : 
                        ( b.name.toUpperCase() > a.name.toUpperCase() ) ? -1 : 
                        0;
            });
            
            const next = data.files.filter((item) => {
                return item.name == paths[index];
            });

            if(next.length == 0){
                const response = await addFolder(paths[index], folder);
                folder = response.id;
            } else {
                folder = next[0].id;
            }
        }
        isBuilding = false;
        return folder;
    }

    const initRequest = (data) => {
        var accessToken = getCookie("zd_drive_access");
    
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open("POST", "https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable");
            xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
            xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
                        
            xhr.onload = function () {
              if (xhr.status == 200) {
                resolve(xhr.getResponseHeader('location'));
              } else {
                reject({
                  status: xhr.status,
                  statusText: xhr.statusText
                });
              }
            };
            xhr.onerror = function () {
              reject({
                status: xhr.status,
                statusText: xhr.statusText
              });
            };
            xhr.send(data);
          });
    }

    const doRequest = (method, formData, url, responseType = null, header = null) => {
        var accessToken = getCookie("zd_drive_access");
        var xhr = new XMLHttpRequest();
        xhr.open(method, url);
        
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open(method, url);
            xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
            
            if(header != null){
                Object.keys(header).map((name) => {
                    xhr.setRequestHeader(name, header[name]);
                });
            }
            if(responseType != null){
                xhr.responseType = responseType;
            }
            
            xhr.onload = function () {
              if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
              } else {
                reject({
                  status: xhr.status,
                  statusText: xhr.statusText
                });
              }
            };
            xhr.onerror = function () {
              reject({
                status: xhr.status,
                statusText: xhr.statusText
              });
            };
            xhr.send(formData);
          });
    }

    const addFile = async (file, name, type, size) => {
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(name)[1];

        const key = !props.directory ? null : props.directory.join('-');
        const id = getDriveId(key);
        if(!id){
            alert('Folder doesn\'t exist');
        }

        var metadata = {
            'name': (props.name == null ? name : props.name + "." + ext),
            'mimeType': type,
            'parents' : [ id ]
        };
        setIsDriveLoading(true);
        const location = await initRequest(JSON.stringify(metadata));
        const response = await doRequest(
            'PUT',
            file,
            location,
            'json',
            {"Content-Range": `bytes 0-${size - 1}\/${size}`}
        );
        setIsDriveLoading(false);
        props.update({name: response.name, id:response.id});
    }

    const addFolder = async (name, parent = 'root') => {
        var metadata = {
            'name': name,
            'mimeType': 'application/vnd.google-apps.folder',
        };
        metadata['parents'] = [ parent ];

        var form = new FormData();
        form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
        const response = await doRequest(
            'POST',
            form,
            'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id',
            'json'
        );
        return response;
    }

    const list = (query) => {
        let params = '';
        if (query && typeof query === 'object') {
            params = Object.keys(query).map(function (key) {
                return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
            }).join('&');
        }
        if(params.length > 0){
            params = "?" + params;
        }

        return doRequest(
            'get',
            null,
            'https://www.googleapis.com/drive/v3/files'+params,
            'json',
            {'Content-Type' : 'application/json; charset=UTF-8'}
        );
    }

    return (
        <GoogleDriveInput cancelable={props.cancelable} ping={props.ping} pingCallback={props.pingCallback} className={isDriveLoading ? ' is__loading' : ''} file={props.file} process={addFile} />
      );
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleDriveUploader);