import { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import { getServerTimeStamp } from "../classes/database/firebase";
import {
	accountSocialSelling,
	accountUpdate,
} from "../classes/database/Accounts";
import {
	leadSocialSelling,
	leadPointsTotal,
	leadUpdate,
} from "../classes/database/Leads";

import MessageBuilder from "./MessageBuilder";

function SocialSelling(props) {
	const { id } = useParams();

	const defaultLimit = 3;

	const textareaRef = useRef();
	const selectRef = useRef();

	const [accounts, setAccounts] = useState(null);
	const [currentAccount, setCurrentAccount] = useState(null);
	const [leads, setLeads] = useState(null);
	const [currentLead, setCurrentLead] = useState(null);
	const [savedLeads, setSavedLeads] = useState({});
	const [processedLeads, setProcessedLeads] = useState({});
	const [changed, setChanged] = useState(true);
	const [isDone, setIsDone] = useState(false);

	const doChange = () => {
		if (!changed) {
			clearTimeout(changed);
		}

		setChanged(
			setTimeout(() => {
				setChanged(false);
			}, 600)
		);
	};

	useEffect(async () => {
		doChange();

		const results = await accountSocialSelling(id);
		if (!results) {
			return;
		}

		const filtered = results.filter((element) => {
			const today = new Date();
			const date =
				element.socialSelling && element.socialSelling.date
					? new Date(element.socialSelling.date.seconds * 1000)
					: null;
			const currentLimit =
				element.socialSelling && element.socialSelling.count
					? element.socialSelling.count
					: 0;
			const hardLimit = element.socialSellingLimit
				? element.socialSellingLimit
				: defaultLimit;

			if (areDatesEqual(today, date) && currentLimit >= hardLimit) {
				return false;
			}
			return true;
		});

		setAccounts(filtered);
		setCurrentAccount(0);
	}, []);

	useEffect(async () => {
		if (currentAccount === null) {
			return;
		}
		if (!accounts || !accounts[currentAccount]) {
			return;
		}
		if (savedLeads[accounts[currentAccount].documentId]) {
			return;
		}

		let ssDate =
			accounts[currentAccount].socialSelling &&
			accounts[currentAccount].socialSelling.date
				? new Date(
						accounts[currentAccount].socialSelling.date.seconds *
							1000
				  )
				: null;
		if (ssDate != null) {
			ssDate = new Date(ssDate.toDateString());
			ssDate = new Date(ssDate.getTime() + 86400000);
		}

		const ssLimit = accounts[currentAccount].socialSellingLimit
			? accounts[currentAccount].socialSellingLimit
			: defaultLimit;

		const ssDone =
			accounts[currentAccount].socialSelling &&
			accounts[currentAccount].socialSelling.count
				? accounts[currentAccount].socialSelling.count
				: 0;

		const results = await leadSocialSelling(
			accounts[currentAccount].documentId,
			new Date() < ssDate ? ssLimit - ssDone : ssLimit
		);

		setCurrentLead(0);
		if (results === false) {
			storeLeads([]);
		} else {
			storeLeads(results);
		}
	}, [currentAccount]);

	const areDatesEqual = (date1, date2) => {
		if (!date1 || !date2) {
			return false;
		}
		return (
			date1.getFullYear() + date1.getMonth() + date1.getDate() ===
			date2.getFullYear() + date2.getMonth() + date2.getDate()
		);
	};

	const storeLeads = (data) => {
		const newLeads = { ...savedLeads };
		newLeads[accounts[currentAccount].documentId] = data;
		setSavedLeads(newLeads);

		setLeads(data);
	};

	const changeSelect = (e) => {
		const data = [...leads];
		data[currentLead] = {
			...leads[currentLead],
			status: e.target.value,
		};
		storeLeads(data);
	};

	const changeText = (e) => {
		const data = [...leads];
		data[currentLead] = {
			...leads[currentLead],
			notes: e.target.value,
		};
		storeLeads(data);
	};

	const finalizeLeadInvite = async () => {
		const lead = leads[currentLead];
		const response = await leadUpdate(
			lead.documentId,
			{
				status: selectRef.current.value,
				notes: textareaRef.current.value,
			},
			accounts[currentAccount]
		);

		let newLeads = [...leads];
		newLeads[currentLead] = {
			...lead,
			status: response.data.status,
			notes: response.data.notes,
		};

		storeLeads(newLeads);
		const returnData = processLead(lead);

		toNextLead(returnData);
	};

	const processLead = (lead) => {
		const leadProcesed = processedLeads[accounts[currentAccount].documentId]
			? {
					...processedLeads[accounts[currentAccount].documentId],
			  }
			: {};
		leadProcesed[currentLead] = lead.documentId;
		const newProcessed = { ...processedLeads };
		newProcessed[accounts[currentAccount].documentId] = leadProcesed;
		setProcessedLeads(newProcessed);
		return newProcessed;
	};

	const toNextLead = (data) => {
		const accountHasNewLeads = currentLead + 1 < leads.length;
		const hasNewAccount = currentAccount + 1 < accounts.length;

		doChange();

		if (accountHasNewLeads) {
			setCurrentLead(currentLead + 1);
			return;
		}

		if (!hasNewAccount) {
			finalizeSocialSelling(data);
			return;
		}
		if (savedLeads[accounts[currentAccount + 1].documentId]) {
			setLeads(savedLeads[accounts[currentAccount + 1].documentId]);
			setCurrentLead(0);
			setCurrentAccount(currentAccount + 1);
			return;
		}

		setCurrentLead(0);
		setCurrentAccount(currentAccount + 1);
	};

	const finalizeSocialSelling = (data) => {
		const items = data != processedLeads ? data : processedLeads;
		Object.keys(items).map(function (documentId) {
			accountUpdate(documentId, {
				socialSelling: {
					date: getServerTimeStamp(),
					count: items[documentId]
						? Object.keys(items[documentId]).length
						: 0,
				},
			});
			return;
		});
		setIsDone(true);
	};

	const toPreviousLead = () => {
		const accountHasNewLeads = currentLead > 0;
		const hasNewAccount = currentAccount > 0;

		doChange();

		if (accountHasNewLeads) {
			setCurrentLead(currentLead - 1);
			return;
		}

		if (hasNewAccount) {
			setLeads(savedLeads[accounts[currentAccount - 1].documentId]);
			setCurrentLead(
				savedLeads[accounts[currentAccount - 1].documentId].length - 1
			);
			setCurrentAccount(currentAccount - 1);
		}
	};

	const outputLeadData = () => {
		const lead = leads[currentLead];
		return (
			<div className={`lead__container${changed ? " change" : ""}`}>
				{lead && <h2>Lead - {lead.name}</h2>}
				<div className={`leads__inner`}>{outputEditRow()}</div>
				<div className="social__selling__controls">
					{(currentAccount > 0 || currentLead > 0) && (
						<span
							className="previous__lead"
							onClick={toPreviousLead}>
							Vorige
						</span>
					)}
					{leads.length > 1 && (
						<button onClick={finalizeLeadInvite}>Opslaan</button>
					)}
					{(currentAccount + 1 < accounts.length ||
						currentLead + 1 < leads.length) && (
						<span className="next__lead" onClick={toNextLead}>
							Volgende
						</span>
					)}
				</div>
			</div>
		);
	};

	const emptyLeads = () => {
		return (
			<div className={`single__lead`}>
				<p style={{ textAlign: "center", marginBottom: "2rem" }}>
					Deze klant heeft geen leads meer
				</p>
			</div>
		);
	};

	const outputEditRow = () => {
		if (leads.length === 0) {
			return emptyLeads();
		}
		const lead = leads[currentLead];
		const total = leadPointsTotal(lead.points);
		lead.id = lead.id.replace("https:/www.linkedin.com/sales/lead/", "");
		return (
			<div
				key={currentLead}
				data-id={lead.documentId}
				className={`single__lead`}>
				<span className="col name">
					<label>LinkedIn</label>
					<a
						href={`https://linkedin.com/in/${lead.id}`}
						taget="_blank">
						Naar profiel
					</a>
				</span>
				<span className="col rejected">
					<label>Is afgewezen</label>
					<button
						className={`rejection__button no__edit ${
							lead.rejected === true ? "is__rejected" : ""
						}`}>
						{lead.rejected === true ? "Afwijzen" : "Goedkeuren"}
					</button>
				</span>
				<span className="col status">
					<label>Status</label>
					<select
						onChange={changeSelect}
						ref={selectRef}
						value={lead.status}>
						<option value="open">Open</option>
						<option value="requested">Verzoek verstuurd</option>
					</select>
				</span>
				<span className="col comment">
					<label>Notities</label>
					<textarea
						onChange={changeText}
						value={lead.notes}
						ref={textareaRef}
						placeholder="Plaats opmerking"></textarea>
				</span>
			</div>
		);
	};

	const buildAccountScreen = () => {
		if (!accounts || !leads) {
			return (
				<h2 style={{ marginTop: "8rem", textAlign: "center" }}>
					Alle social selling is uitgevoerd!
				</h2>
			);
		}

		if (isDone) {
			return [
				<div className="social__selling__container">
					<h2>Social Selling</h2>
					<div className="lead__container">
						<div className={`leads__inner`}>
							<div className={`single__lead`}>
								<p
									style={{
										textAlign: "center",
										marginBottom: "2rem",
									}}>
									Het social selling is afgerond, druk op de
									knop om het process af te ronden.
								</p>
								{leads.length == 0 &&
									currentAccount + 1 >= accounts.length &&
									currentLead + 1 >= leads.length && (
										<button onClick={finalizeSocialSelling}>
											Naar afronden
										</button>
									)}
								<Link
									className="ss__to__overview"
									to="/accounts">
									Naar klanten
								</Link>
							</div>
						</div>
					</div>
				</div>,
			];
		}

		const user = accounts[currentAccount];
		return (
			<div className="social__selling__container">
				<h2>
					Social Selling ({currentAccount + 1}/{accounts.length})
				</h2>
				{leads.length > 0 && (
					<h3>
						{user.name} ({currentLead + 1}/{leads.length})
					</h3>
				)}
				{leads.length == 0 && <h3>{user.name}</h3>}

				<div>{outputLeadData()}</div>
			</div>
		);
	};

	return (
		<div className="app__inner social__selling container">
			{buildAccountScreen()}
			<MessageBuilder
				key={1}
				defaults={
					leads && leads[currentLead]
						? {
								"[NAAM]": leads[currentLead].name,
						  }
						: null
				}
				initial={
					accounts && accounts[currentAccount]
						? {
								"[KLANT]": accounts[currentAccount].name,
						  }
						: null
				}
				messages={
					accounts &&
					accounts[currentAccount] &&
					accounts[currentAccount].messages
						? accounts[currentAccount].messages
						: []
				}
			/>
		</div>
	);
}

export default SocialSelling;
