import firebase from "./firebase";
import { getServerTimeStamp } from "./firebase";
import {
	collection,
	doc,
	getDoc,
	addDoc,
	setDoc,
	updateDoc,
	getDocs,
	query,
	where,
	orderBy,
	deleteDoc,
	documentId,
} from "firebase/firestore";

import { shallowEqual } from "../../helpers/functions";

const buildRef = () => {
	return collection(firebase, "Content");
};
export const postRef = collection(firebase, "Content");

export const contentInsert = async (documentId, data, userId) => {
	const ref = doc(firebase, "Content", documentId);
	const docRef = await setDoc(ref, {
		...data,
		created: getServerTimeStamp(),
		owner: "/Accounts/" + userId,
	});

	return await getDoc(ref);
};
export const contentFetch = async (userId, documentId) => {
	if (!documentId) {
		return;
	}
    
    var documentRef = doc(firebase, "Content", documentId);
	// // postRef
	// let q = query(documentRef, orderBy("created", "desc"));
	// let q = query(documentRef, where("owner", "==", "/Accounts/" + documentId));
	const snapshot = await getDoc(documentRef);
	

	if (!snapshot.exists()) {
		return false;
	}

	return {
		...snapshot.data(),
		documentId: snapshot.id,
	};	 
};

export const contentFetchAll = async (userId, ) => {
	const ref = buildRef();

	var documentRef = doc(firebase, "Accounts", userId);
	//leadRef
	let q = query(ref, where("owner", "==", "/Accounts/" + userId));
	q = query(q, orderBy("title"));
	// q = query(q, where("shouldTrack", "==", true));
	// q = query(q, where("status", "!=", 'connected'));
	try {
		const snapshot = await getDocs(q);
		console.log(snapshot.size);
		const data = [];
		snapshot.forEach((element) => {

			data.push({
				...element.data(),
				documentId: element.id,
			});
		});
		if (data.length > 0) {
			return data;
		}
	} catch (error) {
		console.log(error);
	}
	return false;
};

export const contentUpdate = async (documentId, data, userId) => {
	var documentRef = doc(firebase, "Content", documentId);
	var newData = {...data};
	
	if(!await contentExists(documentRef)){
		return await contentInsert(documentId, data, userId);
	}
	
	const result = await updateDoc(documentRef, newData);
	return {
		data: {
			...newData,
			documentId: documentId,
		},
	};
};

export const contentDelete = async (documentId, userId) => {
	try {
		const documentRef = doc(firebase, "Content", documentId);
		const response = await deleteDoc(documentRef);
	} catch(e){
		console.log(e);
	}
};

export const contentExists = async (ref) => {
	const document = await getDoc(ref);
	if (document.exists) {
		return true;
	} else {
		return false;
	}
};