import { connect } from "react-redux";
import { Link } from "react-router-dom";

function ActionBar(props) {
	return (
		<div className="accounts__actionbar__container">
			<div className="accounts__actionbar container">
				<Link to="/account/aanmaken">Klant aanmaken</Link>
				<button
					onClick={() => {
						props.callback();
					}}>
					Klant verwijderen
				</button>
			</div>
		</div>
	);
}

export default ActionBar;
