import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
	filterObject,
	mapObject,
	mapObjectToArray,
} from "../helpers/functions";


import { useAuth } from "../contexts/AuthContext";

import { setAccounts } from "../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../selectors";

import { fetchAllAccounts, accountDelete } from "../classes/database/Accounts";
import ActionBar from "./accounts/overview/ActionBar";

function Accounts(props) {
	const accounts = props.collections.accounts
		? props.collections.accounts
		: [];
	const setAccounts = (items) => {
		props.setAccounts(items);
	};

	const { deleteUser } = useAuth();

	const [selected, setSelected] = useState([]);

	const [removeAccount, setRemoveAccount] = useState(false);

	useEffect(async () => {
		const users = await fetchAllAccounts();
		setAccounts(users);
	}, []);

	const removePrompt = () => {
		setRemoveAccount(true);
	};

	const displayEmptyAccountList = () => {
		return <h2>Geen accounts gevonden</h2>;
	};

	const changeSelectedItems = (e, i) => {
		if (e.target.checked) {
			addToSelection(i);
		} else {
			removeFromSelection(i);
		}
	};

	const removeSelection = async () => {
		const newAccounts = {};
		const list = Object.keys(accounts).map(async (e, i) => {
			let success = false;
			if(selected.indexOf(e) >= 0){
				const response = await deleteUser(e);
				if(response === false){
					success = true;
				}
			} else {
				success = true;
			}
			if(success){
				newAccounts[e] = accounts[e];
			}
			return {
				success: success,
				user: accounts[e]
			};
		});
		await Promise.all(list);

		setSelected([]);
		setRemoveAccount(false);
		setAccounts(newAccounts);
	};

	const removeFromSelection = (index) => {
		const newSelection = filterObject(selected, (e) => {
			return accounts[index].documentId === e ? false : true;
		});
		setSelected(newSelection);
	};

	const addToSelection = (index) => {
		const newSelection = [...selected, accounts[index].documentId];
		setSelected(newSelection);
	};

	const outputRemovePopUp = (item, index) => {
		return [
			<h2 key={0}>
				Weet je zeker dat je deze account wilt verwijderen?
			</h2>,
			<button
				key={1}
				className="remove__confirm"
				onClick={removeSelection}>
				Ja, verwijder de account
			</button>,
			<button
				key={2}
				className="cancel__confim"
				onClick={() => {
					setSelected([]);
					setRemoveAccount(false);
				}}>
				Annuleren
			</button>,
		];
	};

	const outputAcountsList = () => {
		if (accounts.length === 0) {
			displayEmptyAccountList();
			return;
		}

		return (
			<ul className="accounts__overview__container">
				{mapObjectToArray(accounts, (item, i) => {
					return (
						<li
							className="account__overview__item"
							key={i}
							data-id={item.documentId}>
							<Link to={`/account/${item.documentId}`}>
								{item.name}
							</Link>
							<input
								type="checkbox"
								checked={selected[accounts[i].documentId]}
								onChange={(e) => changeSelectedItems(e, i)}
							/>
						</li>
					);
				})}
			</ul>
		);
	};
	return (
		<div className="app__inner container">
			<ActionBar callback={removePrompt} />
			{outputAcountsList()}
			{removeAccount && (
				<div className="post__pop__up__container">
					<div className="pop__up__inner">{outputRemovePopUp()}</div>
				</div>
			)}
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
