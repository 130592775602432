import { BrowserRouter, Navigate, Route } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function ProtectedRoute({ children }) {
	const { currentUser } = useAuth();
	if (currentUser) {
	}
	return children;
	return <Navigate replace to="/login" />;
}
