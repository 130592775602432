import {
	SET_LOGGED_IN_ACCOUNT
} from "./userTypes";

const initialState = {
	user : null,
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOGGED_IN_ACCOUNT:
			return {
				...state,
				user: action.payload.user,
			};
		default:
			return state;
	}
};

export default userReducer;
