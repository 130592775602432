import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../../selectors";
import {
	filterObject,
	mapObject,
	mapObjectToArray,
} from "../../../helpers/functions";

import { accountFetch } from "../../../classes/database/Accounts";
import { useAuth } from "../../../contexts/AuthContext";

import Card from "./Card";
import ParserView from "./ParserView";
import ParseResult from "./ParseResult";
import LeadsView from "./LeadsView";
import PostsView from "./PostsView";
import AccountSettings from "./AccountSettings";

import { hashCode } from "../../../helpers/functions";
import {
	assignAdminRole,
	revokeAdminRole,
} from "../../../classes/database/firebase";

import { accountUpdate } from "../../../classes/database/Accounts";
import { postFetchAll } from "../../../classes/database/Posts";

function AccountView(props) {
	const accounts = props.collections.accounts
		? props.collections.accounts
		: {};
	const setAccounts = (items) => {
		props.setAccounts(items);
	};

	const { id } = useParams();
	const [passwordInput, setPasswordInput] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [editRole, setEditRole] = useState(false);
	const [isCreating, setIsCreating] = useState(false);

	const linkRef = useRef();

	const { isAdmin, isCurrentUser } = useAuth();

	useEffect(async () => {
		props.setCurrentPosts(null);
		props.setCurrentLeads(null);

		const user = accounts[id] ? accounts[id] : await accountFetch(id);
		props.setCurrentAccount(user);
		if (!accounts[id]) {
			const _accounts = { ...accounts };
			_accounts[id] = user;
			props.setAccounts(_accounts);
		}
		return () => {
			props.setCurrentAccount(null);
			props.setCurrentLeads([]);
			props.setCurrentPosts([]);
		};
	}, []);

	useEffect(async () => {
		if (!props.current.account) {
			return;
		}

		const posts = props.collections.posts[id]
			? props.collections.posts[id]
			: await postFetchAll(id);
		const postCollections = props.collections.posts
			? { ...props.collections.posts }
			: {};
		postCollections[id] = posts ? posts : [];

		props.setCurrentPosts(postCollections[id]);
		props.setPosts(postCollections);
	}, [props.current.account]);

	if (!props.current.account) {
		return null;
	}

	const outputSettingsScreen = () => {
        return (
			<div
				className={`parse__view__container`}>
				<AccountSettings mode="social-selling" data={props.current.account} />
				<AccountSettings mode="content-marketing" data={props.current.account} />
			</div>
		);		
	};

	const doAssignAdmin = async () => {
		setIsLoading(true);
		const response = await assignAdminRole(props.current.account.email);
		if (!response.success) {
			return;
		}
		const data = await accountUpdate(props.current.account.documentId, {
			isAdmin: true,
		});
		updateParsedUser(data);
	};

	const doRevokeAdmin = async () => {
		setIsLoading(true);
		const response = await revokeAdminRole(props.current.account.email);
		const data = await accountUpdate(props.current.account.documentId, {
			isAdmin: false,
		});
		updateParsedUser(data);
	};

	const updateParsedUser = (response) => {
		setIsLoading(false);
		setEditRole(false);
		if (!response.data) {
			return;
		}
		const account = {
			...props.current.account,
			...response.data,
		};
		props.setActiveUser(account);
	};

	const toggleEditRole = () => {
		setEditRole(editRole === true ? false : true);
	};

	const outputUserRole = () => {
		if (isCurrentUser(props.current.account.documentId) || !isAdmin()) {
			return null;
		}
		if (props.current.account.isAdmin) {
			return (
				<div className={`role__edit${editRole ? " active" : ""}`}>
					<button
						onClick={toggleEditRole}
						className="role__label admin__label">
						Admin
					</button>
					<button
						className="change__role"
						onClick={() => {
							doRevokeAdmin();
						}}>
						Maak gebruiker
					</button>
				</div>
			);
		}
		return (
			<div className={`role__edit${editRole ? " active" : ""}`}>
				<button
					onClick={toggleEditRole}
					className="role__label user__label">
					Gebruiker
				</button>
				<button
					className="change__role"
					onClick={() => {
						doAssignAdmin();
					}}>
					Maak admin
				</button>
			</div>
		);
	};

	const futureDate = new Date();
	futureDate.setDate(futureDate.getDate() + 1);
	if(!isAdmin()){
		return null;
	}
	return (
		<div
			className={`app__inner grid two lead__detail${
				props.parser.isFocused && props.mode === "parser"
					? " is__parsing"
					: ""
			}${isLoading ? " loading__screen" : ""} ${
				props.mode ? props.mode : ""
			}`}>
			<div className="lead__profile__container">
				{isAdmin() && (
					<Link className="return__link" to={`/accounts`}>
						Naar overzicht
					</Link>
				)}
				<Card
					name={props.current.account.name}
					description={[
						<Link
							key={2}
							to={`/account/${id}/content-marketing`}>
							Content Marketing
						</Link>,
						<Link
							key={1}
							to={`/account/${id}/social-selling`}>
							Social Selling
						</Link>,
						<a
							key={2}
							target="_blank"
							href={`/account/${id}/onboarding`}>
							Onboarding
						</a>
					]}
				/>
				{outputUserRole()}
			</div>
			{outputSettingsScreen()}
			{/* {generatePassword && outputLeadList()} */}
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountView);
