function Card(props) {
	return (
		<div className="card__container container">
			<div className="card__inner">
				<figure className="card__icon"></figure>
				<div className="card__information">
					<h2>{props.name}</h2>
					{props.link && props.link.length > 0 &&<a href={props.link} target="_blank">
						Bekijk profiel
					</a>}
					{props.description && <p>{props.description}</p>}
				</div>
			</div>
		</div>
	);
}

export default Card;
