import {
	SET_ACCOUNTS,
	SET_POSTS,
	SET_LEADS,
	SET_MESSAGES,
	SET_FETCHED_ACCOUNT,
	SET_FETCHED_POSTS,
	SET_FETCHED_LEADS,
	SET_FETCHED_MESSAGES,
} from "./collectionTypes";

export const setAccounts = (items) => {
	return {
		type: SET_ACCOUNTS,
		payload: {
			accountId: null,
			items: items,
		},
	};
};
export const setPosts = (items) => {
	return {
		type: SET_POSTS,
		payload: {
			items: items,
		},
	};
};
export const setLeads = (items) => {
	return {
		type: SET_LEADS,
		payload: {
			items: items,
		},
	};
};
export const setMessages = (items) => {
	return {
		type: SET_MESSAGES,
		payload: {
			items: items,
		},
	};
};

export const setFetchedAccount = (id) => {
	return {
		type: SET_FETCHED_ACCOUNT,
		payload: {
			id: id,
		},
	};
};

export const setFetchedLeads = (id) => {
	return {
		type: SET_FETCHED_LEADS,
		payload: {
			id: id,
		},
	};
};

export const setFetchedPosts = (id) => {
	return {
		type: SET_FETCHED_POSTS,
		payload: {
			id: id,
		},
	};
};

export const setFetchedMessages = (id) => {
	return {
		type: SET_FETCHED_MESSAGES,
		payload: {
			id: id,
		},
	};
};
