import React, { useState, useRef } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

export default function PDFViewer(props) {
    const [file, setFile] = useState(props.file);
    const [numPages, setNumPages] = useState();

    const ref = useRef();
  
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        console.log('success');
      setNumPages(nextNumPages);
    }
  
    function onDocumentLoadError(error) {
        console.log(error);
        props.onError(props.file);
    }
    
    return (
      <div className="Example">
        <div className="Example__container">
          <div className="Example__container__document">
            <Document ref={ref} file={{url:props.file}} onLoadError={onDocumentLoadError} onLoadSuccess={onDocumentLoadSuccess} >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        </div>
      </div>
    );
}
