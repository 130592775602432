function GoogleAuthentication(CLIENT_ID, API_KEY, SCOPES, DISCOVERY_DOC, callback, authenticated, gapi) {

  this.CLIENT_ID = CLIENT_ID;
  this.API_KEY = API_KEY;
  this.SCOPES = SCOPES;
  this.DISCOVERY_DOC = DISCOVERY_DOC;

  this.tokenClient = null;
  this.initiated = false;
  this.isInitiated = false;
  this.callback = callback;
  this.authenticated = authenticated;
  this.gapi = gapi;

  this.apiLoaded = () => {
    this.gapi.load('client', this.initializeClient);
  }

  this.initializeClient = async () => {
    await this.gapi.client.init({
      apiKey: this.API_KEY,
      discoveryDocs: [this.DISCOVERY_DOC],
    });
    this.initiated = true;
    this.callback();
  }

  this.identityServiceLoaded = () => {
    /* global google */
    this.gapi.load('client', this.apiLoaded);
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this.CLIENT_ID,
      scope: this.SCOPES,
      callback: this.authorizationCompleted,
    });
    this.isInitiated = true;
    this.callback();
  }

  this.authorizationCompleted = (response) => {
    if (!response || !response.access_token) { return; }
    this.authenticated(response);
  }

  this.maybePromptAuthorization = async () => {
    if (!this.initiated || !this.isInitiated) { return; }

    let response;
    if (this.gapi.client.getToken() === null) {
      response = await this.tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      response = await this.tokenClient.requestAccessToken({ prompt: '' });
    }
  }
  
  this.signOut = () => {
    const token = this.gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token);
      this.gapi.client.setToken('');
    }
  }
}

const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';
const SCOPES = 'https://www.googleapis.com/auth/drive';

const authenticator = new GoogleAuthentication(
  process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID, 
  process.env.REACT_APP_GOOGLE_DRIVE_API_KEY, 
  SCOPES, 
  DISCOVERY_DOC
);
export default authenticator;