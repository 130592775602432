import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../selectors";

import { accountFetch } from "../../classes/database/Accounts";
import { useAuth } from "../../contexts/AuthContext";

import { makeRequest } from "../../helpers/functions";

import { accountUpdate } from "../../classes/database/Accounts";
import { contentFetchAll } from "../../classes/database/Content";


function ContentPlanningView(props) {
	
	const { isAdmin } = useAuth();

	const outputDate = (date) => {
		if(!date || !date.seconds){ return; }
		const newDate = new Date(date.seconds * 1000);
		return newDate.getDay() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getFullYear();
	}

	const outputFrequency = (number) => {
		if(number == 1){
			return 'Eens per week'
		}
		if(number == 1.5){
			return 'Eens per week + uitgelichte post Eens per twee weken'
		}
		if(number == 2){
			return 'Twee keer per week'
		}
	}

    const buildContentPlanTable = () => {
        return (
            <section key={0} className="content__planning__table container">
                <aside key={0} className="content__planning__actions">
                    <ul>
                        <li><button onClick={props.setAddPlanning} className="button add__planning">Nieuwe planning toevoegen</button></li>
                   </ul>
                </aside>
				<div className="containt__planning__container" key={2}>
					<div key={1} className="content__planning__header">
						<ul>
							<li key={0}>Naam</li>
							<li key={0}>Frequentie</li>
							<li key={1}>Periode</li>
						</ul>
					</div>
					{props.data.map((item, index) => {
						return (
						<div className="containt__planning__item" key={index}>
							<div className="planning__item__container">
								<ul key={index}>
									<li key={0}>{item.title}</li>
									<li key={0}>{outputFrequency(item.frequency)}</li>
									<li key={1}>{outputDate(item.startPeriod)} <strong>t/m</strong> {outputDate(item.endPeriod)}</li>
								</ul>
								<Link className="ghost" to={`/account/${props.userId}/content-marketing/${item.zohoId}`}></Link>
							</div>
						</div>);
					})}
				</div>
            </section>
        );
    }

	if(!isAdmin()){
		return null;
	}
    return buildContentPlanTable();

}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentPlanningView);
