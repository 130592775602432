import { sleep } from "../helpers/functions";

export async function parseConnectionHTML(view, callback) {
	const children = view.querySelectorAll(
		".mn-connection-card__details .mn-connection-card__link"
	);

	const findId = (element) => {
		const link = element.href;
		const parts = link.split("/").filter((elm) => elm);
		return parts[parts.length - 1];
	};

	const findName = (element) => {
		const name = element.querySelector('.mn-connection-card__name');
		return name ? name.textContent : ""
	}

	const buildItem = (element) => {
		return {
			id: findId(element),
			name: findName(element),
			isConnected: true,
		};
	};

	let item, isUpdated;
	const data = [];
	const childLength = children.length;
	const timeout = (2000 / childLength > 1) ? 2000 / childLength  : 0;
	for (let i = 0; i < children.length; i++) {
		item = buildItem(children[i]);
		children[i].remove();

		await sleep(timeout);        
		isUpdated = await callback(item);
		if (isUpdated) {
			data.push(item);
		}
	}

	return data;
}
