import { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ModeControls from "./ModeControls";

import { useAuth } from "../../../contexts/AuthContext";
import { setFetchedLeads, setLeads, setCurrentLeads } from "../../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
	leadsAreFetched,
} from "../../../selectors";

import {
	leadInsert,
	leadFetchAll,
	leadUpdate,
	leadDelete,
	leadPointsTotal,
} from "../../../classes/database/Leads";
import { getServerTimeStamp } from "../../../classes/database/firebase";

import { useParams } from "react-router-dom";

function LeadsView(props) {
	const leads = props.collections.leads ? props.collections.leads : {};

	const { id } = useParams();
	const { isAdmin } = useAuth();
	const [activeMenu, setActiveMenu] = useState();
	const [editing, setEdit] = useState();

	const leadIdRef = useRef();
	const leadNameRef = useRef();
	const functionRef = useRef();
	const companyRef = useRef();
	const locationRef = useRef();

	const [creatingLead, setCreatingLead] = useState(false);
	const [statusFilter, setStatusFilter] = useState("");
	const [trackingFilter, setTrackingFilter] = useState("");
	const [searchFilter, setSearchFilter] = useState("");
	const [connectedSinceFilter, setConnectedSinceFilter] = useState("");
	const [removeLead, setRemoveLead] = useState(false);
	const [selected, setSelected] = useState([]);

	useEffect(async () => {
		const leadList =
			leads[id] && props.collections.leadsAreFetched
				? leads[id]
				: await leadFetchAll(props.current.account.documentId);
		props.setCurrentLeads(leadList ? leadList : {});
		props.setFetchedLeads(id);

		if (leadList && (!leads[id] || !props.collections.leadsAreFetched)) {
			const _leads = { ...leads };
			_leads[id] = leadList;
			props.setLeads(_leads);
		}
	}, []);

	const updateStatus = async (e, item, index) => {
		const data = { status: e.target.value };
		if (data.status === "connected" && !item.connectedFrom) {
			data["connectedFrom"] = getServerTimeStamp();
			data["shouldTrack"] = true;
		}
		const response = await leadUpdate(
			item.documentId,
			data,
			props.current.account
		);

		const _leads = props.current.leads.map((element, i) => {
			if (element.documentId === item.documentId) {
				const data = {
					...element,
					status: response.data.status,
				};
				if (response.data.connectedFrom) {
					data["connectedFrom"] = response.data.connectedFrom;
					data["shouldTrack"] = response.data.shouldTrack;
				}
				return data;
			} else {
				return element;
			}
		});

		props.setCurrentLeads(_leads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = _leads;
		props.setLeads(leadCollection);
	};

	const updateTracking = async (e, item, index) => {
		const response = await leadUpdate(
			item.documentId,
			{
				shouldTrack: e.target.value === "track" ? true : false,
			},
			props.current.account
		);

		const _leads = props.current.leads.map((element, i) => {
			if (element.documentId === item.documentId) {
				return {
					...element,
					shouldTrack: response.data.shouldTrack,
				};
			} else {
				return element;
			}
		});

		props.setCurrentLeads(_leads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = _leads;
		props.setLeads(leadCollection);
	};

	const removePrompt = (index) => {
		setActiveMenu(null);
		if (index >= 0) {
			setRemoveLead(index);
		} else {
			setRemoveLead("all");
		}
	};

	const rejectLead = async (index) => {
		const item = props.current.leads[index];
		const response = await leadUpdate(
			item.documentId,
			{
				rejected: item.rejected ? false : true,
			},
			props.current.account
		);

		const _leads = props.current.leads.map((el, i) => {
			return index != i
				? el
				: {
						...el,
						...response.data,
				  };
		});

		props.setCurrentLeads(_leads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = _leads;
		props.setLeads(leadCollection);
	};

	const outputViewRow = (item, index) => {
		const total = leadPointsTotal(item.points);
		return (
			<div
				key={index}
				data-id={item.documentId}
				className={`lead__row lead__container${
					item.thresholdHit === true ? " hot__lead" : ""
				}`}>
				<span className="col name">
					<Link
						to={`/account/${props.current.account.documentId}/lead/${item.documentId}`}>
						{item.name}
					</Link>
				</span>
				<span className="col points">{total}</span>
				{item.status === "open" && (
					<span className="col status">Open</span>
				)}
				{item.status === "requested" && (
					<span className="col status">Verzoek verstuurd</span>
				)}
				{item.status === "connected" && (
					<span className="col status">Verbonden</span>
				)}
				<span className="col track">
					{item.shouldTrack === true ? "Volgen" : "Niet volgen"}
				</span>
				<button
					className={`rejection__button no__edit ${
						item.rejected === true ? "is__rejected" : ""
					}`}>
					{item.rejected === true ? "Afwijzen" : "Goedkeuren"}
				</button>
				<span
					className="col options"
					onClick={(e) => {
						e.stopPropagation();
						setActiveMenu(
							activeMenu === null || index !== activeMenu
								? index
								: null
						);
					}}>
					<ul
						className={`pop__up__menu${
							activeMenu === index ? " active" : ""
						}`}>
						<li
							onClick={(e) => {
								e.stopPropagation();
								setEdit(index);
								setActiveMenu(null);
							}}>
							Aanpassen
						</li>
						<li>
							<a
								target="_blank"
								href={`https://www.linkedin.com/in/${item.id}`}>
								Bekijk profiel
							</a>
						</li>
						<li
							className="remove__post"
							onClick={() => removePrompt(index)}>
							Verwijderen
						</li>
						<li
							onClick={(e) => {
								e.stopPropagation();
								setActiveMenu(null);
							}}>
							Sluiten
						</li>
					</ul>
				</span>
			</div>
		);
	};

	const subtractPoint = async (index) => {
		let first = true;
		const lead = props.current.leads[index];
		const points = lead.points.filter(function (e) {
			if (first && e.type === "CUSTOM_ADD") {
				first = false;
				return;
			}
			return e;
		});
		if (first === true) {
			points.push({
				post: "/Posts/custom-" + new Date().getTime(),
				type: "CUSTOM_SUBTRACT",
			});
		}

		const data = await leadUpdate(
			lead.documentId,
			{
				points: points,
			},
			props.current.account
		);

		const _leads = [];
		props.current.leads.map((element, i) => {
			if (element.documentId === lead.documentId) {
				let info = {
					...element,
					points: data.data.points,
				};
				if (typeof data.data.thresholdHit == "boolean") {
					info.thresholdHit = data.data.thresholdHit;
				}
				_leads.push(info);
			} else {
				_leads.push(element);
			}
		});

		props.setCurrentLeads(_leads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = _leads;
		props.setLeads(leadCollection);
	};

	const addPoint = async (index) => {
		let first = true;
		const lead = props.current.leads[index];
		const points = lead.points.filter(function (e) {
			if (first && e.type === "CUSTOM_SUBTRACT") {
				first = false;
				return;
			}
			return e;
		});
		if (first === true) {
			points.push({
				post: "/Posts/custom-" + getServerTimeStamp(),
				type: "CUSTOM_ADD",
			});
		}

		const data = await leadUpdate(
			lead.documentId,
			{
				points: points,
			},
			props.current.account
		);

		const _leads = [];
		props.current.leads.map((element, i) => {
			if (element.documentId === lead.documentId) {
				let info = {
					...element,
					points: data.data.points,
				};
				if (typeof data.data.thresholdHit == "boolean") {
					info.thresholdHit = data.data.thresholdHit;
				}
				if (typeof data.data.shouldTrack == "boolean") {
					info.shouldTrack = data.data.shouldTrack;
				}
				_leads.push(info);
			} else {
				_leads.push(element);
			}
		});

		props.setCurrentLeads(_leads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = _leads;
		props.setLeads(leadCollection);
	};

	const changeSelectedItems = (e, i) => {
		if (e.target.checked) {
			addToSelection(i);
		} else {
			removeFromSelection(i);
		}
	};

	const removeSelection = () => {
		selected.map((documentId) => {
			return leadDelete(documentId, id);
		});

		const newLeads = _leads.filter((item, i) => {
			return selected.indexOf(item.documentId) >= 0 ? false : true;
		});
		setSelected([]);
		setActiveMenu(null);
		setRemoveLead(false);
		setEdit(false);

		props.setCurrentLeads(newLeads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = newLeads;
		props.setLeads(leadCollection);
	};

	const selectAll = () => {
		const newSelection = _leads.map((item) => {
			return item.documentId;
		});
		setSelected(newSelection);
	};

	const deselectAll = () => {
		setSelected([]);
	};

	const removeFromSelection = (index) => {
		const newSelection = selected.filter((e) => {
			return _leads[index].documentId === e ? false : true;
		});
		setSelected(newSelection);
	};

	const addToSelection = (index) => {
		const newSelection = [...selected, _leads[index].documentId];
		setSelected(newSelection);
	};

	const outputEditRow = (item, index) => {
		const total = leadPointsTotal(item.points);
		console.log(selected, _leads[index].documentId);
		return (
			<div
				key={index}
				data-id={item.documentId}
				className={`lead__row lead__container${
					item.thresholdHit === true ? " hot__lead" : ""
				}`}>
				<span className="col name">
					{editing === "all" && (
						<input
							type="checkbox"
							checked={
								selected.indexOf(_leads[index].documentId) >= 0
							}
							onChange={(e) => changeSelectedItems(e, index)}
						/>
					)}
					{item.name}
				</span>
				<span className="col points">
					<button
						onClick={() => subtractPoint(index)}
						className="lead__subtract__custom">
						-
					</button>
					{total}
					<button
						onClick={() => addPoint(index)}
						className="lead__add__custom">
						+
					</button>
				</span>
				<span className="col status">
					<select
						value={item.status}
						onChange={(e) => {
							updateStatus(e, item, index);
						}}>
						<option value="open">Open</option>
						<option value="requested">Verzoek verstuurd</option>
						<option value="connected">Verbonden</option>
					</select>
				</span>
				<span className="col track">
					<select
						value={item.shouldTrack === true ? "track" : "untrack"}
						onChange={(e) => {
							updateTracking(e, item, index);
						}}>
						<option value="untrack">Niet volgen</option>
						<option value="track">Volgen</option>
					</select>
				</span>
				<button
					className={`rejection__button ${
						item.rejected === true ? "is__rejected" : ""
					}`}
					onClick={(e) => rejectLead(index)}>
					{item.rejected === true ? "Afwijzen" : "Goedkeuren"}
				</button>
				{editing !== "all" && (
					<span
						className="col options"
						onClick={(e) => {
							e.stopPropagation();
							setActiveMenu(
								activeMenu === null || index !== activeMenu
									? index
									: null
							);
						}}>
						<ul
							className={`pop__up__menu${
								activeMenu === index ? " active" : ""
							}`}>
							<li
								onClick={(e) => {
									e.stopPropagation();
									setEdit(null);
									setActiveMenu(null);
								}}>
								Klaar
							</li>
							<li
								onClick={(e) => {
									e.stopPropagation();
									setActiveMenu(null);
								}}>
								Sluiten
							</li>
						</ul>
					</span>
				)}
			</div>
		);
	};

	const outputLeads = (item, index) => {
		if (editing === index || editing == "all") {
			return outputEditRow(item, index);
		}
		return outputViewRow(item, index);
	};

	const toggleEdit = () => {
		if (editing === "all") {
			setEdit(null);
		} else {
			setEdit("all");
		}
	};

	const cancelAddLead = (e) => {
		setCreatingLead(false);
		props.callback(false);
	};

	const maybeFilterLeads = (_leads) => {
		const hits = [];
		if (searchFilter) {
			hits.push(true);
		}
		if (statusFilter) {
			hits.push(true);
		}
		if (trackingFilter) {
			hits.push(true);
		}
		if (connectedSinceFilter) {
			hits.push(true);
		}
		if (hits.length === 0) {
			return _leads;
		}

		const list = {};

		const connectedFrom = connectedSinceFilter
			? Math.floor(new Date(connectedSinceFilter).getTime() / 1000)
			: "";
		_leads.map((e, i) => {
			let allowed = true;
			if (
				searchFilter &&
				e.name.toLowerCase().indexOf(searchFilter.toLowerCase()) < 0
			) {
				allowed = false;
			}
			if (statusFilter && e.status != statusFilter) {
				allowed = false;
			}
			if (
				trackingFilter &&
				e.shouldTrack != (trackingFilter === "track" ? true : false)
			) {
				allowed = false;
			}
			if (
				connectedFrom &&
				e.connectedFrom &&
				connectedFrom > e.connectedFrom.seconds
			) {
				allowed = false;
			} else if (connectedFrom && !e.connectedFrom) {
				allowed = false;
			}

			if (allowed) {
				list[i] = e;
				// list.push(e);
			}
		});

		return list;
	};

	const _leads = maybeFilterLeads(props.current.leads);
	const doRemoveLead = () => {
		if (selected.length > 0) {
			removeSelection();
			return;
		}

		let item,
			index = removeLead;
		if (props.current.leads[index]) {
			item = props.current.leads[index];
		} else {
			return;
		}
		leadDelete(item.documentId, id);

		const newLeads = [];
		props.current.leads.map((item, i) => {
			if (item.documentId != props.current.leads[index].documentId) {
				newLeads.push(item);
			}
		});

		props.setCurrentLeads(newLeads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = newLeads;
		props.setLeads(leadCollection);

		setActiveMenu(null);
		setRemoveLead(false);
	};

	const outputRemovePopUp = (item, index) => {
		return createPopUp([
			<h2 key={0}>
				{removeLead === "all" && selected.length > 1
					? "Weet je zeker dat je deze leads wilt verwijderen?"
					: "Weet je zeker dat je deze lead wilt verwijderen?"}
			</h2>,
			<button key={1} className="remove__confirm" onClick={doRemoveLead}>
				{removeLead === "all" && selected.length > 1
					? "Ja, verwijder deze leads"
					: "Ja, verwijder deze lead"}
			</button>,
			<button
				key={2}
				className="cancel__confim"
				onClick={cancelRemoveLeast}>
				Annuleren
			</button>,
		]);
	};

	const cancelRemoveLeast = (e) => {
		setRemoveLead(false);
	};

	const createPopUp = (elements) => {
		return (
			<div className="post__pop__up__container">
				<div className="pop__up__inner">{elements}</div>
			</div>
		);
	};

	const outputCreatePopUp = () => {
		return createPopUp(
			<div key={6} className="post__create__form">
				<h2 key={0}>Lead aanmaken</h2>
				<input
					key={1}
					ref={leadIdRef}
					type="text"
					name="name"
					placeholder="Vul LinkedIn user id in..."
				/>
				<input
					key={2}
					ref={leadNameRef}
					type="text"
					name="name"
					placeholder="Vul volledige naam in..."
				/>
				<input
					key={3}
					ref={functionRef}
					type="text"
					name="name"
					placeholder="Vul functie in..."
				/>
				<input
					key={4}
					ref={companyRef}
					type="text"
					name="name"
					placeholder="Vul bedrijfsnaam in..."
				/>
				<input
					key={5}
					ref={locationRef}
					type="text"
					name="name"
					placeholder="Vul locatie in..."
				/>
				<button onClick={addLead} key={6} className="remove__confirm">
					Aanmaken
				</button>
				<button
					key={7}
					className="cancel__confim"
					onClick={cancelAddLead}>
					Annuleren
				</button>
			</div>
		);
	};

	const createPostPrompt = () => {
		setActiveMenu(null);
		setCreatingLead(true);
		props.callback(true);
	};

	const addLead = async () => {
		sessionStorage.setItem(
			"zd__lln__customer",
			props.current.account.documentId
		);
		const response = await leadInsert(
			{
				id: leadIdRef.current.value,
				name: leadNameRef.current.value,
				location: locationRef.current.value,
				company: companyRef.current.value,
				occupation: functionRef.current.value,
			},
			props.current.account.documentId
		);
		sessionStorage.removeItem("zd__lln__customer");
		if (!response) {
			return;
		}

		const newLeads = [
			{
				...response.data(),
				documentId: response.id,
			},
			...props.current.leads,
		];

		props.setCurrentLeads(newLeads);
		const leadCollection = { ...props.collections.leads };
		leadCollection[id] = newLeads;
		props.setLeads(leadCollection);

		setCreatingLead(false);
		props.callback(false);
	};

	if(!isAdmin()){
		return null;
	}
	if (!_leads) {
		return null;
	}
	return (
		<div
			className={`leads__container${
				props.parser.isRunning ? " loading__screen" : ""
			}`}>
			<ModeControls mode={props.mode} />
			<h2 key={0}>
				Lead overzicht
				<button
					onClick={() => {
						toggleEdit();
					}}
					className={`${
						editing === "all" ? "i__save" : "i__edit"
					}`}></button>
			</h2>
			<div key={1} className="lead__filters">
				<input
					onChange={(e) => {
						setSearchFilter(e.target.value);
					}}
					type="text"
					value={searchFilter}
					placeholder="Zoek persoon"
				/>
				<div className="select__container">
					<select
						value={statusFilter}
						onChange={(e) => {
							setStatusFilter(e.target.value);
						}}>
						<option value="">Wat is de vriendschapsstatus</option>
						<option value="open">Open</option>
						<option value="requested">Verzoek verstuurd</option>
						<option value="connected">Verbonden</option>
					</select>
				</div>
				<div className="select__container">
					<select
						value={trackingFilter}
						onChange={(e) => {
							setTrackingFilter(e.target.value);
						}}>
						<option value="">Wat is de volgstatus</option>
						<option value="untrack">Niet volgen</option>
						<option value="track">Volgen</option>
					</select>
				</div>
				<input
					onChange={(e) => {
						setConnectedSinceFilter(e.target.value);
					}}
					type="date"
					value={connectedSinceFilter}
				/>
			</div>
			{(editing === "all" || typeof editing == "numeric") && (
				<div key={2} className="edit__controls">
					<button
						key={0}
						onClick={
							_leads.length === selected.length
								? deselectAll
								: selectAll
						}
						className="select__all__leads">
						{_leads.length === selected.length
							? "Deselecteer alle"
							: "Selecteer alle"}
					</button>
					<button
						key={1}
						disabled={selected.length <= 0}
						onClick={() => removePrompt("all")}
						className={`remove__all__leads${
							selected.length > 0 ? " active" : ""
						}`}>
						Verwijder selectie
					</button>
				</div>
			)}
			<div key={3} className="grid three" style={{ marginBottom: "0.75rem" }}>
				<div key={0}>
					<strong>Leads</strong> ({Object.keys(_leads).length}/
					{props.current.leads.length})
				</div>
				<div key={1}>
					<strong>Uitgenodigd</strong> {props.current.leads.filter((item)=>{ return item.status != "open" && item.rejected != true; }).length}
				</div>
				<div key={2}>
					<strong>Verbonden</strong> {props.current.leads.filter((item)=>{ return item.status == "connected" && item.rejected != true; }).length}
				</div>
			</div>
			<div key={4} className="leads__inner container">
				{Object.keys(_leads).map((e, i) =>
					outputLeads(props.current.leads[e], e)
				)}
			</div>
			<button
				onClick={createPostPrompt}
				key={5}
				className="add__new__lead">
				Aanmaken
			</button>
			{creatingLead && outputCreatePopUp()}
			{removeLead !== false && outputRemovePopUp()}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			leadsAreFetched: leadsAreFetched(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		detail: {
			leads: state.accountDetailReducer.leads,
		},
		parser: {
			user: state.parserReducer.user,
		},
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLeads: (leads) => {
			dispatch(setLeads(leads));
		},
		setCurrentLeads: (leads) => {
			dispatch(setCurrentLeads(leads));
		},
		setFetchedLeads: (leads) => {
			dispatch(setFetchedLeads(leads));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsView);
