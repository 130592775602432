import {
	SET_LOGGED_IN_ACCOUNT,
} from "./userTypes";

export const setLoggedInAccount = (state) => {
	return {
		type: SET_LOGGED_IN_ACCOUNT,
		payload: {
			user: state,
		},
	};
};