import { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { contentFetch, contentUpdate } from "../../../classes/database/Content";

import SocialTextarea from "./SocialTextarea";


import AccountSettings from "./AccountSettings";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../../selectors";



import { debounce } from "../../../helpers/functions";

function ContentPlanning(props) {
	const { id, planningId } = useParams();
	const [contentPlanningHTML, setContentPlanningHTML] = useState();
	const [descriptionItems, setDescriptionItems] = useState(null);
	
	useEffect(async () => {
		const content = await contentFetch(id, planningId);
		setDescriptionItems(content);
	},[]);

	useEffect(async () => {
		if(descriptionItems == null){ return; }
		if(!props.document || typeof props.document != 'object' || props.document.length == 0){ return; }
		
		const items = props.document.querySelectorAll('calendar > contentPlanning');
		const innerHTML = [];
		Object.keys(items).map((key, index) => {
			const item = items[key];
			const planningsId = item.querySelector("id").innerHTML;

			if(planningId != planningsId){ return; }

			const deadline = item.querySelector("deadlineDate").innerHTML;
			const feedbackOne = item.querySelector("feedbackOneDate").innerHTML;
			const feedbackTwo = item.querySelector("feedbackTwoDate").innerHTML;
			const planning = item.querySelectorAll("planning > item");

			const output = outputPlanning(planning, descriptionItems);
			
            innerHTML.push((
			<article className="zd__content__planning__detail" key={id}>
                <h2 key={0}>{item.querySelector("title").innerHTML}</h2>
                <div key={1} className="zd__date__container">
						<span key={0} className={`zd__icon feedback one${isDateApproaching(feedbackOne) ? " date__approaching" : ""}${hasDatePassed(feedbackOne) ? " date__passed" : ""}`}>{feedbackOne}</span>
						<span key={1} className={`zd__icon feedback two${isDateApproaching(feedbackTwo) ? " date__approaching" : ""}${hasDatePassed(feedbackTwo) ? " date__passed" : ""}`}>{feedbackTwo}</span>
						<span key={2} className={`zd__icon deadline${isDateApproaching(deadline) ? " date__approaching" : ""}${hasDatePassed(deadline) ? " date__passed" : ""}`}>{deadline}</span>
                </div>
				<div key={2} className="zd__planning__container">
					{output}
				</div>
            </article>));
        });
		
		setContentPlanningHTML((
			<div key={1} className="planning__overview__container">
				<div className="planning__overview__inner">
					{innerHTML}
				</div>
				<div className="planning__overview__actions">
					<button className="button">Post toevoegen</button>
				</div>
			</div>
		));
	
	}, [props.document, descriptionItems]);

	const debouncedHandler = useCallback(
		debounce(contentUpdate, 500),
		[contentUpdate]
	  );

	const isDateApproaching = (date) => {
		const base = new Date();
    	base.setDate(base.getDate() + 14);

		const current = new Date(date);

		return current <= base && current > new Date();
	}

	const hasDatePassed = (date) => {
		return new Date(date) <= new Date();
	}

	const buildPlanningDetail = () => {
		return contentPlanningHTML;
	}

	const updatePostDescription = async (contentId, content) =>{
		const data = {...descriptionItems};
		data[contentId] = content;
		setDescriptionItems(data);
		debouncedHandler(planningId, data, id)
	}

	const outputPlanning = (planning, content) => {
		const body = [];
		Object.keys(planning).map((key, index) => {
			const input = planning[key].querySelector("input").innerHTML;
			const publishDate = planning[key].querySelector("pubDate").innerHTML;
			const createdBy = planning[key].querySelector("createdBy").innerHTML;
			const description = planning[key].querySelector("additionalDescription").innerHTML;
			const targetAudience = planning[key].querySelector("targetAudience").innerHTML;
			const document = planning[key].querySelector("document > src").innerHTML;
			const serie = planning[key].querySelector("serie").innerHTML;
			const status = planning[key].querySelector("status").innerHTML;
			const worker = planning[key].querySelector("worker").innerHTML;
			const version = planning[key].querySelector("description").innerHTML;
			const type = planning[key].querySelector("type").innerHTML;
			
			const socialdescription = content[`${serie}-${type}`] ? content[`${serie}-${type}`] : "";

			body.push((<article key={index} className="grid two">
				<div className="zd__column">
					<div className="zd__post__details">
						<h2>Informatie</h2>
						<span className="serie"><strong>Serie</strong>{serie}</span>
						<span className="status"><strong>Status</strong>{status}</span>
						<span className="type"><strong>Type</strong>{type}</span>
						<span className="target__audience"><strong>Doelgroep</strong>{targetAudience}</span>
						<span className="worker"><strong>Medewerker</strong>{worker}</span>
					</div>
					<div className="description"><strong>Omschrijving</strong>{description}</div>
					<div className="input" title="Klanten input voor content">{input}</div>
				</div>
				<div className="zd__column">
					<h2>{serie} - {publishDate}</h2>
					<div className="zd__post__details">
						<span className="version"><strong>Versie</strong>{version}</span>
						<span className="publish__date"><strong>Publicatie datum</strong>{publishDate}</span>
					</div>
					<div className="zd__social linkedin">
						<div className="zd__social__head grid two">
							<div className="zd__column">
								<span className="zd__social__logo"></span>
							</div>
							<div className="zd__social__details">
								<strong>Zodoende</strong>
								<span>722 followers</span>
								<span>1d</span>
							</div>
						</div>
						<div className="zd__social__description">
							<SocialTextarea text={socialdescription} id={serie+"-"+type} update={updatePostDescription}/>
						</div>
						<div className="zd__social__image">
							<img src={document}/>
						</div>
						<div className="zd__social__actions">
							<span className="zd__social__logo"></span>
							<span className="zd__social__like">Like</span>
							<span className="zd__social__comment">Comment</span>
							<span className="zd__social__repost">Repost</span>
							<span className="zd__social__send">Send</span>
						</div>
					</div>
				</div>
			</article>));
		});
		return body;
	}

    const buildPlanningOverview = () => {
		if(!props.document || props.document.length === 0){ return; }
        const title = props.document.querySelector('channel > title').innerHTML;
        const items = props.document.querySelectorAll('calendar > contentPlanning');
    
		const body = Object.keys(items).map((key, index) => {
			const item = items[key];
			const deadline = item.querySelector("deadlineDate").innerHTML;
			const feedbackOne = item.querySelector("feedbackOneDate").innerHTML;
			const feedbackTwo = item.querySelector("feedbackTwoDate").innerHTML;
			const planningsId = item.querySelector("id").innerHTML;
            return (<article className="zd__content__planning" key={id}>
                <h2 key={0}>{item.querySelector("title").innerHTML}</h2>
                <div key={1} className="zd__date__container">
						<span key={2} className={`zd__icon feedback one${isDateApproaching(feedbackOne) ? " date__approaching" : ""}${hasDatePassed(feedbackOne) ? " date__passed" : ""}`}>{feedbackOne}</span>
						<span key={0} className={`zd__icon feedback two${isDateApproaching(feedbackTwo) ? " date__approaching" : ""}${hasDatePassed(feedbackTwo) ? " date__passed" : ""}`}>{feedbackTwo}</span>
						<span key={1} className={`zd__icon deadline${isDateApproaching(deadline) ? " date__approaching" : ""}${hasDatePassed(deadline) ? " date__passed" : ""}`}>{deadline}</span>
                </div>
				<Link key={2}
							to={`/account/${id}/content-marketing/${planningsId}`}></Link>
            </article>);
        });
		
		return (
			<div className="grid two">
				<AccountSettings key={0} mode="content-marketing" data={props.current.account} />
				<div key={1}>
						<h2 key={0}>{title}</h2>
						<div key={1} className="planning__overview__container">
							{body}		
						</div>
				</div>
			</div>
		);
    };

	const buildPlanningJSON = async () => {
		const content = await contentFetch(id, planningId);
		return JSON.stringify(content);
	}

	if(planningId){
		return props.mode == 'json' ? [buildPlanningJSON()] : [buildPlanningDetail()];
	} else {
		return [buildPlanningOverview()];
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentPlanning);
