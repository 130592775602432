import { useEffect, useState, useRef } from "react";

function MessageBuilder(props) {
	const [selectedMessage, setSelectedMessage] = useState();
	const [initial, setInitialValues] = useState();
	const [formValues, setFormValues] = useState({});
	const [defaults, setDefaults] = useState(props.defaults);

	const maybeSetInitial = () => {
		if(JSON.stringify(props.initial) == JSON.stringify(initial)){
			return;
		}
		setInitialValues(props.initial);
		setSelectedMessage("");
	}
	
	useEffect(() => {
		maybeSetInitial();
	}, [props.initial]);
	
	useEffect(() => {	
		setFormValues({...setFormValues, ...initial});
	}, [initial]);

	useEffect(() => {
		if(!props.defaults){ return; }
		
		const values = {};
		for(let index in formValues){
			if(!initial.hasOwnProperty(index)){ continue; }
			values[index] = formValues[index];
		}
		
		setFormValues({...values, ...props.defaults});
		setDefaults(props.defaults);
	}, [props.defaults]);

	const determineValue = (e) => {
		if(initial && initial[e]){
			return  formValues && formValues[e] ? formValues[e] : initial[e] ? initial[e] : "";
		}

		if(props.defaults && props.defaults[e]){
			return  formValues && formValues[e] ? formValues[e] : props.defaults[e] ? props.defaults[e] : "";
		}

		return  formValues && formValues[e] ? formValues[e] : "";
	}

	const buildForm = () => {
		if (!props.messages[selectedMessage]) {
			return null;
		}
		const message = props.messages[selectedMessage].message;
		const fields = message.match(/\[[A-Z]{1,}\]/g);
		return (
			<div className="message__form">
				{fields.map((e, i) => {
					return (
						<div key={i} className="message__form__input">
							<label key={0}>{e}</label>
							<input
								placeholder="Voer veld in"
								className="input__field"
								type="text"
								key={1}
								onChange={(event) => {
									const values = { ...formValues };
									values[e] = event.target.value;
									setFormValues(values);
								}}
								value={determineValue(e)}
							/>
						</div>
					);
				})}
			</div>
		);
	};

	const buildPreview = () => {
		if (!props.messages || !props.messages[selectedMessage]) {
			return null;
		}
        const keys = formValues ? Object.keys(formValues) : [];
        let message = props.messages[selectedMessage].message;
        for(let i = 0; i < keys.length; i++){
            message = message.replace(keys[i], formValues[keys[i]]);
        }
		return <p>{message}</p>;
	};
	
	return (
		<div className="message__builder">
			<div className="input__container">
				<select
				className="input__field"
					value={selectedMessage}
					onChange={(e) => {
						setSelectedMessage(e.target.value);
					}}>
					<option value="">Selecteer een bericht template</option>
					{props.messages &&
						props.messages.map((e, i) => {
							return (
								<option key={i} value={i}>
									{e.title}
								</option>
							);
						})}
				</select>
			</div>
			{selectedMessage && ([<div key={0} className="message__builder__form">
				{buildForm()}
			</div>,
			<div key={1} className="message__builder__preview">
				{buildPreview()}
			</div>])}
		</div>
	);
}
export default MessageBuilder;
