import {
	SET_ACCOUNT, SET_POST, SET_LEAD, SET_MESSAGE
} from "./currentTypes";


export const setCurrentAccount = (item) => {
	return {
		type: SET_ACCOUNT,
		payload: {
            item: item,
        },
	};
};
export const setCurrentPosts = (item) => {
	return {
		type: SET_POST,
		payload: {
            item: item,
        },
	};
};
export const setCurrentLeads = (item) => {
	return {
		type: SET_LEAD,
		payload: {
            item: item,
        },
	};
};
export const setCurrentMessages = (item) => {
	return {
		type: SET_MESSAGE,
		payload: {
            item: item,
        },
	};
};