import {
	SET_ACCOUNT_DETAIL_MODE,
	SET_LEADS,
	SET_POSTS,
} from "./accountDetailTypes";

const initialState = {
	mode: "parser",
	leads: [],
	posts: []
};

const accountDetailReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACCOUNT_DETAIL_MODE:
			return {
				...state,
				mode: action.payload.mode,
			};
		case SET_LEADS:
			return {
				...state,
				leads: action.payload.leads,
			};
		case SET_POSTS:
			return {
				...state,
				posts: action.payload.posts,
			};
		default:
			return state;
	}
};

export default accountDetailReducer;
