import { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Login from "./Login";
import { Link, useNavigate } from "react-router-dom";

function UpdateProfile(props) {
	const emailRef = useRef();
	const passwordRef = useRef();
	const passwordConfirmRef = useRef();
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
    const [reauthenticate, setReauthenticate] = useState(false);

	const { updateEmail, updatePassword, currentUser } = useAuth();


	const navigate = useNavigate();

	const handleEvent = async (e) => {
		e.preventDefault();

		if ((passwordRef.current.value && !passwordConfirmRef.current.value) || (!passwordRef.current.value && passwordConfirmRef.current.value)) {
            return setError("Vul beide wachtwoord velden in");
				}

		if (passwordRef.current.value !== passwordConfirmRef.current.value) {
			return setError("Het wachtwoord komt niet overeen"); // return error message
		}

        
		setError("");
		setMessage("");
		setLoading(true);

        try{
            if(emailRef.current.value !== currentUser.email){
                const emailPromise = await updateEmail(emailRef.current.value);
            }
    
            if(passwordRef.current.value){
                const passwordResposne = await updatePassword(passwordRef.current.value)
            }
            		
            setMessage(
				"Wijzigingen zijn opgeslagen"
			);
        } catch(exception){
            setReauthenticate(true);
        }

        setLoading(false);
	};

	return (
        <div className="container zd__lln__user__container">
            {reauthenticate && (<Login onSuccess={()=>{setReauthenticate(false)}} message="Bevestig je login gegevens" inline={true}/>) }
			<div className="zd__lln__login__main">
				<h2>Profiel</h2>
				{error && (
					<div className="zd__lln__notice zd__lln__error">
						{error}
					</div>
				)}
				{message && (
					<div className="zd__lln__notice zd__lln__message">
						{message}
					</div>
				)}
				<form onSubmit={handleEvent}>
					<div>
						<label>E-mail</label>
						<input ref={emailRef} defaultValue={currentUser.email} type="email" />
					</div>
					<div>
						<label>Wachtwoord</label>
						<input placeholder="Laat leeg om ongewisjzigd te laten" ref={passwordRef} type="password" />
					</div>
					<div>
						<label>Wachtwoord</label>
						<input placeholder="Laat leeg om ongewisjzigd te laten" ref={passwordConfirmRef} type="password" />
					</div>
					<input
						disabled={loading}
						type="submit"
						value="Opslaan"
					/>
				</form>
			</div>
		</div>
	);
}

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		setLoggedInAccount: (user) => {
// 			dispatch(setLoggedInAccount(user));
// 		},
// 	};
// };

// const mapStateToProps = (state) => {
// 	return {
// 		user: state.userReducer.user,
// 	};
// };

export default UpdateProfile; //connect(mapStateToProps, mapDispatchToProps)();