import { sleep } from "../helpers/functions";

export async function parseCommentHTML(view, callback) {
	const children = view.querySelectorAll(
		".comments-post-meta.comments-comment-item__post-meta"
	);

	const findId = (element) => {
		const urn = element.dataset.entityHovercardId;
		const parts = urn.split(':');
		return parts[parts.length - 1];
	};

	const findName = (element) => {
		return element.innerHTML;
	};

	const buildItem = (element) => {
        const item = element.querySelector('.comments-post-meta__actor .comments-post-meta__name > .comments-post-meta__name-text');
        const id = findId(item);
        const name = findName(item);
		return {
			id: id,
			name: name,
		};
	};

	let item, isInserted;
	const data = [];
	const childLength = children.length;
	const timeout = (2000 / childLength > 1) ? 2000 / childLength  : 0;
	for (let i = 0; i < children.length; i++) {
		item = buildItem(children[i]);
		children[i].remove();

		await sleep(timeout);        
        isInserted = await callback(item);
        if(isInserted){
			data.push(item);
		}
	}

	return data;
}