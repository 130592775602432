import firebase from "./firebase";
import { getServerTimeStamp } from "./firebase";
import {
	collection,
	doc,
	setDoc,
	getDoc,
	addDoc,
	updateDoc,
	deleteDoc,
	getDocs,
	query,
	where,
	orderBy,
} from "firebase/firestore";

const defaultPointThreshold = 6;
const defaultInviteLimit = 3;

export const accountRef = collection(firebase, "Accounts");
export async function fetchAllAccounts() {
	const q = query(accountRef, orderBy("name"));

	try {
		const snapshot = await getDocs(q);

		const data = {};
		snapshot.forEach((element) => {
			data[element.id] = {
				...element.data(),
				documentId: element.id,
			};
		});
		return data;
	} catch (error) {
		console.log(error);
	}
	return [];
}

export const accountSocialSelling = async (id) => {
	if (id) {
		const data = await accountFetch(id);
		return [data];
	}

	let q = query(accountRef, orderBy("isAdmin"));
	q = query(q, orderBy("name"));
	q = query(q, where("isAdmin", "!=", true));

	try {
		const snapshot = await getDocs(q);

		const data = [];
		snapshot.forEach((element) => {
			data.push({
				...element.data(),
				documentId: element.id,
			});
		});
		return data;
	} catch (error) {
		console.log(error);
	}
	return [];
};

export const accountFetch = async (documentId) => {
	if (!documentId) {
		return false;
	}

	const accountRef = doc(firebase, "Accounts", documentId);
	const snapshot = await getDoc(accountRef);

	if (!snapshot.exists()) {
		return false;
	}

	return {
		...snapshot.data(),
		documentId: snapshot.id,
	};
};
export const accountInsert = async (item) => {
	const shouldInsert = await accountMaybeInsert(item);
	if (!shouldInsert) {
		return false;
	}

	const docRef = await setDoc(doc(firebase, "Accounts", item.uid), {
		id: item.id,
		email: item.email,
		name: item.name,
		company: item.company,
		isAdmin: false,
		created: getServerTimeStamp(),
		pointThreshold: defaultPointThreshold,
		socialSellingLimit: defaultInviteLimit,
		ZohoId: '',
		channels: [
			{active: false, name: "LinkedIn"},
			{active: false, name: "Instagram"},
			{active: false, name: "Facebook"},
			{active: false, name: "Twitter"},
			{active: false, name: "TikTok"}
		]
	});

	return await getDoc(doc(firebase, "Accounts", item.uid));
};
const accountMaybeInsert = async (item) => {
	let q = query(accountRef, where("id", "==", item.id));
	const data = [];
	const snapshot = await getDocs(q);
	snapshot.forEach((element) => {
		data.push(element.id);
	});

	if (data.length > 0) {
		return false;
	} else {
		return true;
	}
};

export const accountDelete = async (documentId) => {
	const documentRef = doc(firebase, "Accounts", documentId);
	return await deleteDoc(documentRef);
};

export const accountUpdate = async (documentId, data) => {
	var documentRef = doc(firebase, "Accounts", documentId);
	const result = await updateDoc(documentRef, data);
	return {
		data: {
			...data,
			documentId: documentId,
		},
	};
};
