import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../selectors";

import { accountFetch } from "../../classes/database/Accounts";
import { useAuth } from "../../contexts/AuthContext";

import { makeRequest } from "../../helpers/functions";

import { accountUpdate } from "../../classes/database/Accounts";
import { contentFetchAll } from "../../classes/database/Content";

import ContentPlanningOverview from "./ContentPlanningOverview";
import NewContentPlanning from "./NewContentPlanning";



function ContentPlanningView(props) {
	const accounts = props.collections.accounts
		? props.collections.accounts
		: {};
	const setAccounts = (items) => {
		props.setAccounts(items);
	};

	const { id } = useParams();

	const { isAdmin } = useAuth();
    const [contentPlans, setContentPlans] = useState([]);

    const [addContentPlanning, setAddContentPlanning] = useState(false);
    const [contentPlanningTable, setContentPlanningTable] = useState(true);


	useEffect(async () => {
		props.setCurrentPosts(null);
		props.setCurrentLeads(null);

		const user = accounts[id] ? accounts[id] : await accountFetch(id);
		props.setCurrentAccount(user);
		if (!accounts[id]) {
			const _accounts = { ...accounts };
			_accounts[id] = user;
			props.setAccounts(_accounts);
		}
		return () => {
			props.setCurrentAccount(null);
			props.setCurrentLeads([]);
			props.setCurrentPosts([]);
		};
	}, []);

    useEffect(async () => {
        const plans = await contentFetchAll(id);
        if(plans){
            setContentPlans(plans);
        }
    }, []);

    const switchToAddPlanning = () => {
        setContentPlanningTable(false);
        setAddContentPlanning(true);
    }

    const switchToViewTable = () => {
        setAddContentPlanning(false);
        setContentPlanningTable(true);
    }

	const completeNewPlanning = (documentId, data, userId) => {
		switchToViewTable();
		setContentPlans([
			...contentPlans,
			data
		]);
	}
    
	if(!isAdmin()){
		return null;
	}
    if(addContentPlanning){
        return <NewContentPlanning finish={completeNewPlanning} userId={id}/>
    } else if(contentPlanningTable){
        return <ContentPlanningOverview userId={id} setAddPlanning={switchToAddPlanning} data={contentPlans}/>
    } else {
        return null;
    }

}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentPlanningView);
