import { useEffect } from "react";
import { BrowserRouter, useNavigate, Navigate, Route } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function PrivateRoute({ children }) {
	const navigation = useNavigate();
	const { currentUser, userData, isAdmin } = useAuth();

	const maybeRedirectToUserPage = () => {
		if (
			window.location.pathname.indexOf("/leads/") !== 0 && 
			window.location.pathname.indexOf("/user/") !== 0 &&
			(window.location.pathname.indexOf("/account/") !== 0 || window.location.pathname.indexOf("/preview") == -1)&&
			isAdmin() !== true
		) {
			return navigation(`/user/${currentUser.uid}`);
		}
	};

	useEffect(() => {
		if (currentUser && userData && isAdmin() !== true) {
			maybeRedirectToUserPage();
		}
	}, [userData]);

	if (currentUser) {
		return children;
	}
	return <Navigate replace to="/login" />;
}
