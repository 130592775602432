import { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate  } from "react-router-dom";

function ForgotPassword () {

	const emailRef = useRef();
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);

	const { resetPassword, currentUser } = useAuth();

	const navigate = useNavigate();

	const handleEvent = async (e) => {
		e.preventDefault();

        if(!emailRef.current.value.length){
            setError(
				"Vul je gebruikersnaam in"
			);
		    setLoading(false);
            return;
        }

		try {
			setError("");
			setLoading(true);
			const response = await resetPassword(emailRef.current.value);
            setMessage("Bekijk je email voor instructies om je wachtwoord te herstellen");
        } catch (exception) {
			setError(
				"Er is iets fout gegaan tijdens het inloggen"
			);
		}
		setLoading(false);
	};

	return (
		<div className="container zd__lln__user__container">
			<div className="zd__lln__login__main">
				<h2>Wachtwoord vergeten</h2>
				{error && (
					<div className="zd__lln__notice zd__lln__error">
						{error}
					</div>
				)}
				{message && (
					<div className="zd__lln__notice zd__lln__message">
						{message}
					</div>
				)}
				<form onSubmit={handleEvent}>
					<div>
						<label>E-mail</label>
						<input ref={emailRef} type="email" />
					</div>
					<div>
						<Link to="/login">login</Link>
					</div>
					<input
						disabled={loading}
						type="submit"
						value="Reset wachtwoord"
					/>
				</form>
			</div>
			<div className="zd__lln__login__secondary">
				Heb je nog geen account? <Link to="/aanmelden">Aanmelden</Link>
			</div>
		</div>
	);
}

export default ForgotPassword;