import { sleep } from "../helpers/functions";

export async function parseReactionHTML(view, callback) {
	const parent = view.querySelector(".artdeco-list");
	if (!parent) {
		return [];
	}
	const children = parent.querySelectorAll("li.artdeco-list__item");

	const findId = (element) => {
		const link = element.href;
		const id = link.replace("https://www.linkedin.com/in/", "");
		const parts = id.split("?");
		return parts[0];
	};

	const findName = (element) => {
		element = element.querySelector(
			".artdeco-entity-lockup .artdeco-entity-lockup__content .artdeco-entity-lockup__title > span > span"
		);
		return element.innerHTML;
	};

	const buildItem = (element) => {
		const link = element.querySelector("a");
		const id = findId(link);
		const name = findName(element);
		return {
			id: id,
			name: name,
		};
	};

	let item, isInserted;
	const data = [];
	const childLength = children.length;
	const timeout = (2000 / childLength > 100) ? 2000 / childLength  : 50;
	
	for (let i = 0; i < children.length; i++) {
		item = buildItem(children[i]);
		children[i].remove();

		await sleep(timeout);        
		isInserted = await callback(item);
		if (isInserted) {
			data.push(item);
		}
	}

	return data;
}
