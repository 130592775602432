import { connect } from "react-redux";

function ParseResult(props) {
	const displayItems = () => {
		return props.parser.items.map((item, i) => {
			return (
				<div key={i}>
					{item.name}, {item.id} <small>{item.time}</small>
				</div>
			);
		});
	};
	return (
		<div className="parser__container">
			<h2>Verwerkte data</h2>
			<div className="parser__inner container">
				<div className="parse__results">{displayItems()}</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		appState: state.appReducer.appState,
		parser: {
			type: state.parserReducer.type,
			items: state.parserReducer.items,
		},
	};
};

export default connect(mapStateToProps)(ParseResult);
