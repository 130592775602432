import { PARSER_TYPE } from "../redux";

import {
	leadInsert,
	updatePointCount,
	leadUpdate,
	accountByName,
} from "./database/Leads";
import { getServerTimeStamp } from "./database/firebase";
import { parseLeadHTML } from "./parseLeadHTML";
import { parseReactionHTML } from "./parseReactionHTML";
import { parseCommentHTML } from "./parseCommentHTML";
import { parseConnectionHTML } from "./parseConnectionHTML";
import { parseSalesNavigatorHTML } from "./parseSalesNavigatorHTML";

export async function LinkedinParse(props, callback) {
	let data;

	if (props.view.current.innerHTML.length === 0) {
		return;
	}

	sessionStorage.setItem(
		"zd__lln__customer",
		props.current.account.documentId
	);
	sessionStorage.setItem("zd__lln__post", props.parser.post);

	if (props.parser.type === PARSER_TYPE.LEADS) {
		data = await parseLeads(props.view.current, callback);
	} else if (props.parser.type === PARSER_TYPE.LIKES) {
		sessionStorage.setItem(
			"zd__lln__point__threshold",
			props.current.account.pointThreshold
		);
		data = await parseLikes(props.view.current, callback);
	} else if (props.parser.type === PARSER_TYPE.COMMENTS) {
		sessionStorage.setItem(
			"zd__lln__point__threshold",
			props.current.account.pointThreshold
		);
		data = await parseComments(props.view.current, callback);
	} else if (props.parser.type === PARSER_TYPE.CONNECTION) {
		data = await parseConnection(props.view.current, callback);
	}

	if (data.length === 0) {
		callback([returnPlaceholder(props.parser.type)]);
	}
	props.view.current.innerHTML = "";
	sessionStorage.removeItem("zd__lln__customer");
	sessionStorage.removeItem("zd__lln__post");
	sessionStorage.removeItem("zd__lln__point__threshold");
}

async function parseLeads(view, callback) {
	// parseLeadHTML
	return await parseSalesNavigatorHTML(view, async (item) => {
		const data = await insertNewLead(item);
		callback(data);
		return data.length === 0 ? false : true;
	});
}

async function insertNewLead(item) {
	//check beforehand if items were already parsed in this sessions
	item.time = returnCurrentTime();
	const result = await leadInsert(item);
	return returnParseResult(result);
}

async function parseLikes(view, callback) {
	return await parseReactionHTML(view, async (item) => {
		const data = await insertNewLikes(item);
		callback(data);
		return data.length === 0 ? false : true;
	});
}

async function insertNewLikes(item) {
	const id = sessionStorage.getItem("zd__lln__customer");
	const threshold = sessionStorage.getItem("zd__lln__point__threshold");
	const user = { pointThreshold: threshold, documentId: id };

	const result = await updatePointCount(item, PARSER_TYPE.LIKES, user);
	return returnParseResult(result);
}

async function parseComments(view, callback) {
	return await parseCommentHTML(view, async (item) => {
		const data = await insertNewComments(item);
		callback(data);
		return data.length === 0 ? false : true;
	});
}

async function insertNewComments(item) {
	const id = sessionStorage.getItem("zd__lln__customer");
	const threshold = sessionStorage.getItem("zd__lln__point__threshold");
	const user = { pointThreshold: threshold, documentId: id };

	const result = await updatePointCount(item, PARSER_TYPE.COMMENTS, user);
	return returnParseResult(result);
}

async function parseConnection(view, callback) {
	return await parseConnectionHTML(view, async (item) => {
		const lead = await accountByName(item.name);
		
		if (!lead || lead.status === "connected") {
			return false;
		}

		const data = await updateConnection(lead);
		callback(data);
		return data.length === 0 ? false : true;
	});
}

async function updateConnection(item) {
	const id = sessionStorage.getItem("zd__lln__customer");
	const data = { status: "connected" };
	if (data.status === "connected" && !item.connectedFrom) {
		data["connectedFrom"] = getServerTimeStamp();
		data["shouldTrack"] = true;
	}
	const result = await leadUpdate(item.documentId, data, {
		documentId: id,
	});
	return returnParseResult({ data: () => { return result.data }, id: result.data.documentId }, item);
}

function returnParseResult(response, base = {}) {
	if (!response) {
		return [];
	}
	return [{ ...base, ...response.data(), documentId: response.id }];
}

function returnCurrentTime() {
	var today = new Date();
	var date =
		today.getFullYear() +
		"-" +
		(today.getMonth() + 1) +
		"-" +
		today.getDate();
	var time =
		today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
	return date + " " + time;
}

function returnPlaceholder(type) {
	if (type === PARSER_TYPE.LEADS) {
		return {
			id: null,
			name: "Er zijn geen nieuwe leads gevonden",
			time: returnCurrentTime(),
		};
	}
	if (type === PARSER_TYPE.LIKES) {
		return {
			id: null,
			name: "Er zijn geen nieuwe likes gevonden",
			time: returnCurrentTime(),
		};
	}
	if (type === PARSER_TYPE.COMMENTS) {
		return {
			id: null,
			name: "Er zijn geen nieuwe comments gevonden",
			time: returnCurrentTime(),
		};
	}
	if (type === PARSER_TYPE.CONNECTION) {
		return {
			id: null,
			name: "Er zijn geen nieuwe connecties gevonden",
			time: returnCurrentTime(),
		};
	}
}
