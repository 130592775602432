import { useRef } from "react";
import { connect } from "react-redux";

import ParseControls from "./ParseControls";
import { setParserFocus } from "../../../redux";
import { getLeads, getPosts, getAccounts, getAccountLeads, getAccountPosts, getAccount } from "../../../selectors";
import ModeControls from "./ModeControls";
import { useAuth } from "../../../contexts/AuthContext";


function ParserView(props) {
	const editorRef = useRef();
	const {isAdmin} = useAuth();
	const maybeBlur = (e) => {
		if (e.target.innerHTML.length > 0) {
			return;
		}
		props.setParserFocus(false);
	};

	if(!isAdmin()){
		return null;
	}
	return (
		<div
			className={`parser__container${
				props.parser.isRunning ? " loading__screen" : ""
			}`}>
			<ModeControls mode={props.mode} />
			<button
				onClick={() => props.setParserFocus(false)}
				className={`parser_button i__back${
					props.parser.isFocused ? "" : " hide"
				}`}>
				Terug
			</button>
			<h2>Data uitlezen</h2>
			<div className="parser__inner container">
				<ParseControls view={editorRef} />
				<div
					ref={editorRef}
					className="parse__view"
					onFocus={() => props.setParserFocus(true)}
					onBlur={maybeBlur}
					contentEditable="true"></div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		collections : {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state)
		},
		parser: {
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setParserFocus: (focussed) => {
			dispatch(setParserFocus(focussed));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ParserView);
