import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
	getMessages,
	getAccountMessages,
} from "../selectors";
import {
	filterObject,
	mapObject,
	mapObjectToArray,
} from "../helpers/functions";
import { accountUpdate, accountFetch } from "../classes/database/Accounts";

function Messages(props) {
	const accounts = props.collections.accounts
		? props.collections.accounts
		: {};

	const { id } = useParams();
	const titleRef = useRef();
	const messageRef = useRef();

	const [messageTitle, setMessageTitle] = useState("");
	const [messageBody, setMessageBody] = useState("");
	const [createMessage, setCreateMessage] = useState(false);

	const [activeMessage, setActiveMessage] = useState();
	const [editMessage, setEditMessage] = useState();
	const [messageChanged, setMessageChanged] = useState(false);

	useEffect(async () => {
		const user = accounts[id] ? accounts[id] : await accountFetch(id);
		props.setCurrentAccount(user);
		if (!accounts[id]) {
			const _accounts = { ...accounts };
			_accounts[id] = user;
			props.setAccounts(_accounts);
		}
		return () => {
			props.setCurrentAccount(null);
			props.setCurrentLeads([]);
			props.setCurrentPosts([]);
		};
	}, []);

	const toggleActive = (index) => {
		if (activeMessage === index) {
			setActiveMessage(null);
		} else {
			setActiveMessage(index);
		}
	};

	const outputMessageItem = (item, index) => {
		return (
			<div
				className={`message__item${
					activeMessage === index ? " active" : ""
				}`}
				key={index}>
				<h2
					onClick={() => {
						toggleActive(index);
					}}>
					{item.title}
				</h2>
				<div className="message__preview">
					{outputMessageBody(item, index)}
					{outputControlButton(index)}
				</div>
			</div>
		);
	};

	const saveCurrentMessage = async () => {
		if (!messageChanged) {
			setEditMessage(null);
			return;
		}
		const messages = [...props.current.account.messages];
		const response = await accountUpdate(props.current.account.documentId, {
			messages: messages,
		});
		setEditMessage(null);
	};

	const outputControlButton = (index) => {
		return index === editMessage ? (
			<button
				onClick={() => saveCurrentMessage()}
				className="i__save"></button>
		) : (
			<button
				onClick={() => {
					setMessageChanged(false);
					setEditMessage(index);
				}}
				className="i__edit"></button>
		);
	};

	const outputMessageBody = (message, index) => {
		return index !== editMessage
			? message.message
			: [
					<input
						type="text"
						className="input__field"
						placeholder="Voer titel in.."
						onChange={(e) => {
							updateMessageLocally("title", e, index);
						}}
						value={message.title}
					/>,
					<textarea
						className="input__field"
						placeholder="Voer bericht in.."
						onChange={(e) => {
							updateMessageLocally("message", e, index);
						}}
						value={message.message}></textarea>,
			  ];
	};

	const updateMessageLocally = (prop, e, index) => {
		const messages = [...props.current.account.messages];
		if (messages[index]) {
			setMessageChanged(true);
			messages[index][prop] = e.target.value;
		}
		const user = { ...props.current.account, messages: messages };
		props.setCurrentAccount(user);
	};

	const createPopUp = (elements) => {
		return (
			<div key={4} className="post__pop__up__container">
				<div className="pop__up__inner">{elements}</div>
			</div>
		);
	};

	const doCreateMessage = async () => {
		const message = {
			title: titleRef.current.value,
			message: messageRef.current.value,
		};

		const messages = !props.current.account.messages
			? [message]
			: [...props.current.account.messages, message];
		console.log(messages);
		const response = await accountUpdate(props.current.account.documentId, {
			messages: messages,
		});

		const _accounts = { ...accounts };
		_accounts[id] = {
			...props.current.account,
			messages: response.data.messages,
		};
		console.log(_accounts[id]);
		props.setAccounts(_accounts);
		props.setCurrentAccount(_accounts[id]);

		setMessageTitle("");
		setMessageBody("");
		setCreateMessage(false);
	};

	const outputCreatePopUp = (item, index) => {
		return createPopUp([
			<h2 key={0}>Bericht</h2>,
			<div key={1}>
				<input
					className="input__field"
					value={messageTitle}
					onChange={(e) => setMessageTitle(e.target.value)}
					placeholder="Vul titel in.."
					type="text"
					ref={titleRef}
				/>
				<textarea
					className="input__field"
					onChange={(e) => setMessageBody(e.target.value)}
					value={messageBody}
					placeholder="Vul bericht in.."
					ref={messageRef}></textarea>
			</div>,
			<button key={2} className="add__confirm" onClick={doCreateMessage}>
				Opslaan
			</button>,
			<button
				key={3}
				className="cancel__confim"
				onClick={() => setCreateMessage(false)}>
				Annuleren
			</button>,
		]);
	};

	return [
		<div key={0} className={`message__container`}>
			<Link key={0} className="return__link" to={`/account/${id}/leads`}>
				Naar klant
			</Link>
			<h2 key={1}>Uitnodigingen</h2>
			<div key={2} className="message__inner">
				{props.current.account &&
					props.current.account.messages &&
					props.current.account.messages.map(outputMessageItem)}
			</div>
			<button
				key={3}
				onClick={() => setCreateMessage(true)}
				className="i__add"></button>
			{createMessage !== false && outputCreatePopUp()}
		</div>
	];
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
			berichten: getMessages(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
			berichten: getAccountMessages(state),
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
