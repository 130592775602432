import { combineReducers, createStore, applyMiddleware } from "redux";
import appReducer from "./app/appReducer";
import parserReducer from "./parser/parserReducer";
import userReducer from "./user/userReducer";
import accountDetailReducer from "./accountDetail/accountDetailReducer";
import collectionReducer from "./collection/collectionReducer";
import currentReducer from "./current/currentReducer";

const store = createStore(
	combineReducers({
		appReducer,
		parserReducer,
		userReducer,
		accountDetailReducer,
		collectionReducer,
		currentReducer
	})
);

export default store;