const returnParamOrNull = (param) => {
	return param ? param : null;
};

const returnParamOrFalse = (param) => {
	return param ? param : false;
};

export const leadsAreFetched = (state) => {
	if (
		!state.collectionReducer.fetchedLeads ||
		!state.currentReducer.account ||
		!state.currentReducer.account.documentId
	) {
		return false;
	}
	return returnParamOrFalse(
		state.collectionReducer.fetchedLeads[
			state.currentReducer.account.documentId
		]
	);
};

export const postsAreFetched = (state) => {
	if (
		!state.collectionReducer.fetchedPosts ||
		!state.currentReducer.account ||
		!state.currentReducer.account.documentId
	) {
		return false;
	}
	return returnParamOrFalse(
		state.collectionReducer.fetchedPosts[
			state.currentReducer.account.documentId
		]
	);
};

export const accountIsFetched = (state) => {
	if (
		!state.collectionReducer.fetchedAccounts ||
		!state.currentReducer.account ||
		!state.currentReducer.account.documentId
	) {
		return false;
	}
	return returnParamOrFalse(
		state.collectionReducer.fetchedAccounts[
			state.currentReducer.account.documentId
		]
	);
};

export const getAccounts = (state) =>
	returnParamOrNull(state.collectionReducer.accounts);
export const getLeads = (state) =>
	returnParamOrNull(state.collectionReducer.leads);
export const getPosts = (state) =>
	returnParamOrNull(state.collectionReducer.posts);

export const getAccount = (state) => {
	if (
		!state.collectionReducer.accounts ||
		!state.currentReducer.account ||
		!state.currentReducer.account.documentId
	) {
		return null;
	}
	return returnParamOrNull(
		state.collectionReducer.accounts[
			state.currentReducer.account.documentId
		]
	);
};
export const getAccountLeads = (state) => {
	if (
		!state.collectionReducer.leads ||
		!state.currentReducer.account ||
		!state.currentReducer.account.documentId
	) {
		return null;
	}
	return returnParamOrNull(
		state.collectionReducer.leads[state.currentReducer.account.documentId]
	);
};
export const getAccountPosts = (state) => {
	if (
		!state.collectionReducer.posts ||
		!state.currentReducer.account ||
		!state.currentReducer.account.documentId
	) {
		return null;
	}
	return returnParamOrNull(
		state.collectionReducer.posts[state.currentReducer.account.documentId]
	);
};

export const getUser = (state) => {
	return state.userReducer.user ? state.userReducer.user : null;
};

export const getMessages = (state) => {
	return state.collectionReducer.messages ? state.collectionReducer.messages : null;
}

export const getAccountMessages = (state) => {
	if (
		!state.collectionReducer.messages ||
		!state.currentReducer.account ||
		!state.currentReducer.account.documentId
	) {
		return null;
	}
	return returnParamOrNull(
		state.collectionReducer.messages[state.currentReducer.account.documentId]
	);
}
