import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../../selectors";

import { accountFetch } from "../../../classes/database/Accounts";
import { useAuth } from "../../../contexts/AuthContext";

import { makeRequest } from "../../../helpers/functions";

import { accountUpdate } from "../../../classes/database/Accounts";
import { postFetchAll } from "../../../classes/database/Posts";

import ContentPlanning from "./ContentPlanning";

function ContentMarketingView(props) {
	const accounts = props.collections.accounts
		? props.collections.accounts
		: {};
	const setAccounts = (items) => {
		props.setAccounts(items);
	};

	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
    const [contentXML, setContentXML] = useState([]);

	const { isAdmin } = useAuth();

	useEffect(async () => {
		props.setCurrentPosts(null);
		props.setCurrentLeads(null);

		const user = accounts[id] ? accounts[id] : await accountFetch(id);
		props.setCurrentAccount(user);
		if (!accounts[id]) {
			const _accounts = { ...accounts };
			_accounts[id] = user;
			props.setAccounts(_accounts);
		}
		return () => {
			props.setCurrentAccount(null);
			props.setCurrentLeads([]);
			props.setCurrentPosts([]);
		};
	}, []);

	useEffect(async () => {
		if (!props.current.account) {
			return;
		}

		const posts = props.collections.posts[id]
			? props.collections.posts[id]
			: await postFetchAll(id);
		const postCollections = props.collections.posts
			? { ...props.collections.posts }
			: {};
		postCollections[id] = posts ? posts : [];

		props.setCurrentPosts(postCollections[id]);
		props.setPosts(postCollections);
	}, [props.current.account]);

    useEffect(async () => {
        if(!props.current.account || !props.current.account.zohoId){
            return;
        }
        const response = await makeRequest("GET", `https://socialrss.zodoende.nl/feed/Accounts/Contentplanningen/${props.current.account.zohoId}`);
		// const response = await makeRequest("GET", `http://localhost:3000/537031000000351008.xml`);
        const xmlDoc = await new window.DOMParser().parseFromString(response, "application/xml")
		
		setContentXML(xmlDoc);
    }, [props.current.account]);

	if (!props.current.account) {
		return null;
	}

	const updateParsedUser = (response) => {
		setIsLoading(false);
		if (!response.data) {
			return;
		}
		const account = {
			...props.current.account,
			...response.data,
		};
		props.setActiveUser(account);
	};
	
	const futureDate = new Date();
	futureDate.setDate(futureDate.getDate() + 1);
	return (
		<div
			className={`app__inner container content__overview${isLoading ? " loading__screen" : ""} ${
				props.mode ? props.mode : ""
			}`}>
			<div className="content__planning__container">
				{isAdmin() && (
					<Link key={0} className="return__link" to={`/account/${id}`}>
                        Naar profiel
                    </Link>
				)}
				<ContentPlanning mode={props.mode} key={1} document={contentXML}/>
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentMarketingView);
